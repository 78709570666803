import React from 'react'
import styled from 'styled-components'
import "../index.css"
import kitchen from "../images/kitchen.png"
import rooftop from "../images/rooftop.png"
import landscaping from "../images/landscaping.png"
import vertical from "../images/vertical.png"

export default function Services() {
    return (
        <MainContainer name="services" className="element" >
            <div>


            <Heading>Our Services</Heading>
            <Underline>
            </Underline>

            <Container>
                <Card>
                    <ImgContainer>
                        <Image src={vertical} />
                    </ImgContainer>
                    <CardBody>
                        <h3 className='card-subhead' >
                            Vertical Gardening
                        </h3>
                        <p align="justify"  >
                        Reach new heights of green elegance with our Vertical Gardening expertise, where we design and install stunning vertical green walls, turning any space into a thriving vertical oasis.                </p>         <div className='read-more'>
                            {/* <a  >Read More</a> */}
                        </div>
                    </CardBody>
                </Card>

                <Card>
                    <ImgContainer>
                        <Image src={landscaping} />
                    </ImgContainer>
                    <CardBody>
                        <h3 className='card-subhead' >
                            Landscaping
                        </h3>
                        <p align="justify"  >
                        Unleash the potential of your outdoor space with our expert landscaping services, where every detail is crafted to transform your surroundings into a captivating masterpiece.    </p>                    <div className='read-more'>
                            {/* <a  >Read More</a> */}
                        </div>
                    </CardBody>
                </Card>

                <Card>
                    <ImgContainer>
                        <Image src={rooftop} />
                    </ImgContainer>
                    <CardBody>
                        <h3 className='card-subhead' >
                            Roof Top Gardening
                        </h3>
                        <p align="justify"  >
                        Escape the urban hustle by embracing tranquility at the top – our Rooftop Gardening services transform your rooftop into a lush sanctuary, blending nature seamlessly with the city skyline.   </p>                     <div className='read-more'>
                            {/* <a  >Read More</a> */}
                        </div>
                    </CardBody>
                </Card>

                <Card>
                    <ImgContainer>
                        <Image src={kitchen} />
                    </ImgContainer>
                    <CardBody>
                        <h3 className='card-subhead' >
                            Kitchen Gardening
                        </h3>
                        <p align="justify" >
                        Experience the joy of homegrown flavors with our Kitchen Gardening solutions, cultivating a sustainable garden right at your doorstep for a fresh and delightful culinary journey                         </p>
                        
                        <div className='read-more'>
                            {/* <a  >Read More</a> */}
                        </div>
                    </CardBody>
                </Card>

        
            
            </Container>
            </div>



        </MainContainer>

    )
}

const MainContainer = styled.div`
padding: 20px;
background-color: #3B726A ;
padding: 60px;
min-height: 100vh;
margin-top: -75px;
display: flex;
justify-content: center;
align-items: center;

@media only screen and (max-width: 768px) {
   margin-top:-15px;
}
`

const Card = styled.div`
width :20%;
margin: 20px;
background-color: #FFFFFF;
width: 290px;

h3{
    margin-bottom: 23px;
}
p{
    font-family: 'Outfit';
    font-size:14px;
}
`

const CardBody = styled.div`
padding: 20px;
`

const ImgContainer = styled.div`
height: 105px;
padding: 35px;
padding-bottom: 0px;
display: flex;
justify-content: center;
`

const Image = styled.img`
height: 100%;
`


const Container = styled.div`
background-color: #3B726A;
display: flex;
flex-wrap: wrap;
padding: 5px;
justify-content: center;
align-self: center;
height: 100%;
padding-top: 80px;

@media only screen and (max-width: 768px) {
    padding-top: 15px;
}
`


const Heading = styled.h3`
background-color: #3B726A ;
color: white;
font-size: 32px;
text-align: center;
font-family: 'Outfit', sans-serif;
margin-bottom: 8px;
`

const Underline = styled.span`
height: 2px;
width: 40px;
background-color: #81B214;
padding: 2px;
display:block;
margin: auto;
text-align: center;
`

