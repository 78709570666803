import { getNativeSelectUtilityClasses } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentAdmin: null,
    currentProject: null,
    newProjects: null,
    inProgessProjects: null,
    completedProjects: null,
    openSearch :false ,
    error: false,
};


export const AdminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {

        loginSuccess: (state, action) => {
            state.currentAdmin = action.payload;
        },

        loginFailure: (state) => {
            state.currentAdmin = null;
            state.error = true;
        },

        logout: (state) => {
            state.currentAdmin = null;
            state.error = false; 
        },

        setOpenSearch : (state) =>{
            state.openSearch = !state.openSearch;
        },

        fetchCurrentProject: (state, action) => {
            state.currentProject = action.payload;
        },
        fetchnewProjects: (state, action) => {
            state.newProjects = action.payload;
        },
        fetchInProgessProjects: (state, action) => {
            state.inProgessProjects = action.payload;
        },
        fetchCompletedProjects: (state, action) => {
            state.completedProjects = action.payload;
        },
    },
});


export const { loginFailure, loginSuccess, logout , fetchCurrentProject,fetchCompletedProjects,fetchInProgessProjects,fetchnewProjects,setOpenSearch} = AdminSlice.actions;

export default AdminSlice.reducer;

