import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from "styled-components"
import { format } from "timeago.js"
import { useDispatch, useSelector } from 'react-redux'


export default function UserProjectCard({ project }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

 
    return (
        <Card>
            <span>{format(project.createdAt)}</span>
            <h6>{project.service}</h6>
            <div>
                <label style={{ marginRight: "25px" }} >Project Id &nbsp; :</label>
                <label>{project._id}</label>
            </div>
            <div>
                <label  >Project Status &nbsp; :</label>
                <label>{project.status}</label>
            </div>
            <button id={project._id} onClick={()=>navigate(`/user/project/${project._id}`)} >
                See Details
            </button>
        </Card>
    )
}

const Container = styled.div`

`

const Card = styled.div`
display: flex;
flex-direction: column;
background-color: #afefef;
width: 48%;
padding: 20px;
border-radius: 6px;

h6{
    font-size: 18px;
    margin-bottom: 10px;
}

span{
    display: block;
  text-align: end;
  font-size: 12px;
}

p{
    margin: 20px 0px;
}

div{
    display: flex;
    gap: 50px;
}

button{
    padding: 7px 14px;
    background: black;
    border: navajowhite;
    margin-top: 8px ;
    color: white;
    border-radius: 3px;
    cursor: pointer;
    align-self: flex-end;
}

label{
    margin: 10px 0px;
    font-size: 13px;
}

@media only screen and (max-width: 768px) {
    width: 100%;
    padding: 18px 16px;
    div{
    gap:7px;
}
}

`