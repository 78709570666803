import { React, useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from "axios"
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { loginFailure } from '../../redux/serviceProviderSlice'
import { projectFetchFailure ,fetchProject} from '../../redux/projectSlice'
import { format } from "timeago.js"
import HashLoader from "react-spinners/HashLoader";



export default function ServiceProviderAllProjects({ type }) {

    const { currentServiceProvider } = useSelector(state => state.serviceProvider);

    const [projects, setProjects] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();


     
  useEffect(() => {
    const verification = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_APIKEY}/service-provider/verify`, {
          withCredentials: true,
        });

        if (!res.data) {
          dispatch(loginFailure());
          navigate("/")
        }
      }
      catch (err) {
        dispatch(loginFailure())
        navigate("/")
      }
    }
    verification();
  }, []);

    

    const fetchParticularProject = async (e) => {
        try {
          dispatch(projectFetchFailure());
          const res = await axios.get(`${process.env.REACT_APP_APIKEY}/service-provider/project/${e.target.id}/${currentServiceProvider?._id}`, {
            withCredentials: true,
          });
          dispatch(fetchProject(res.data));
          navigate(`/service-provider/project`)
        } catch (err) {
          dispatch(projectFetchFailure());
        }
      }



    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_APIKEY}/service-provider/project/${type}/${currentServiceProvider?._id}`, {
                    withCredentials: true,
                });
                setProjects(res.data)
            } catch (error) {
                dispatch(loginFailure())
                dispatch(projectFetchFailure());
                navigate("/");
            }
        }
        setProjects(null);
        fetchProjects();
    }, [type]);


    return (
        <>

            {

                projects ? projects.map((project) => {
                    return <ProjectCard key={project._id}  >
                        <span style={{ textAlign: "end" }}  >{format(project.createdAt)}</span>
                        <h6>{project.service}</h6>
                        <div>
                            <p>Customer Budget</p>
                            <p>{project.budget}</p>
                        </div>
                        <div>
                            <p>Area</p>
                            <p>{project.area ? project.area : "Not Specified"}</p>
                        </div>

                       
                        <button  onClick={fetchParticularProject}  id={project._id} >
                            See Details
                        </button>

                    </ProjectCard>
                })

                    : <HashLoader style={{ position: "absolute", top: "50%", left: "50%" }} color="#010504" size={50} />

            }

           

        </>

    )
}



const ProjectCard = styled.div`
background-color: white;
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
padding: 20px;
width: 330px;
height: 225px;
display: flex;
flex-direction: column;
margin: 13px;

h6{
  font-family: 'Outfit',sans-serif;
    font-size: 17px;
    margin: 7px 0px;
}

button{
  background-color: #5b7a85;
  color: white;
  padding: 12px;
    border: aliceblue;
    border-radius: 5px;
    font-size: 16px;
    margin: 20px 0px;
    cursor: pointer;
}

div{
  display: flex;
  justify-content: space-between;
  margin: 7px 0px;
}

@media only screen and (max-width: 768px) {
   div{
    font-size: 14px;
   }
}
`
