import { getNativeSelectUtilityClasses } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentSupplier: null,
    error: false,
};


export const SupplierSlice = createSlice({
    name: 'supplier',
    initialState,
    reducers: {

        loginSuccess: (state, action) => {
            state.currentSupplier = action.payload;
        },

        loginFailure: (state) => {
            state.currentSupplier = null;
            state.error = true;
        },

        logout: (state) => {
            state.currentSupplier = null;
            state.error = false; 
        }
    }
});


export const { loginFailure, loginSuccess, logout } = SupplierSlice.actions;

export default SupplierSlice.reducer;

