import React, { useState } from 'react'
import styled from 'styled-components'
import PersonIcon from '@mui/icons-material/Person';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import GoogleIcon from '@mui/icons-material/Google';
import "../index.css"
import axios from "axios"
import { useDispatch, useSelector } from 'react-redux';
import { auth, provider } from "../firebase";
import { signInWithPopup } from 'firebase/auth';
import { Link, useNavigate  } from "react-router-dom";
import Loader from "./Loader"
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';



export default function ServiceProviderSignUp({entity}) {


    const [load, setLoad] = useState(false);
    const [phone, setPhone] = useState("");
    const [isInputDisabled, setIsInputDisabled] = useState(false);

    const [OTP, SetOTP] = useState("");
    const [expand, setExpand] = useState(false);


    const dispatch = useDispatch();
    const navigate = useNavigate();
   

    const modifiedPhone = (ph) => {
        let start = ph.slice(0, 4);
        let mid = ph.slice(4, 7);
        let end = ph.slice(7, 10);
        let phone = "+91 " + start + "-" + mid + "-" + end;
        return phone;
    }


    const requestOTP = async (e) => {
        e.preventDefault();
        if (phone === "") {
            alert("Please fill all the credentials");
            return;
        } else if (phone.length !== 16) {
            alert("Invalid Phone Number");
            return;
        }
        setIsInputDisabled(true);
        setExpand(true);
        try {
            recaptaGenerator();
            let verifier = window.recaptchaVerifier;
            signInWithPhoneNumber(auth, phone, verifier).then(confirmationResult => {
                window.confirmationResult = confirmationResult;

            }).catch(error => {
                alert("Too many requests , try after some time !")
            });

        } catch (error) {
            console.error('Error sending OTP:', error.response.data.error);
        }
    }



    const recaptaGenerator = () => {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', {
            'size': 'invisible',
            'callback': (response) => {
            }
        });
    }


    const verifyOTP = async (e) => {
        e.preventDefault();
        try {
            const result = await window.confirmationResult.confirm(OTP);
            const id = result.user.uid;
            setLoad(true);
            
            const res = await axios.post(`${process.env.REACT_APP_APIKEY}/${entity}/reset/${id}`, { phone }, { withCredentials: true, });
            if(res.data){
                navigate(`/${entity}/updatepass`) ;
            } 
            console.log(res.data)
        } catch (error) {
            alert(error.response.data.message);
            console.log(error)
            setLoad(false)
            navigate("/login")
        }
    }


    return (
        <>
            {load && <Loader />}
            <Top>

                <Link to="/login" style={{ textDecoration: "none" }}  >
                    <Admin id="commonUser" >
                        <PersonIcon style={{ marginRight: "5px" }} /> User
                    </Admin>
                </Link>

                <Link to="/login/supplier" style={{ textDecoration: "none" }} >
                    <Agent>
                        <FiberManualRecordIcon sx={{ fontSize: 10, color: "red" }} style={{ margin: "0px 10px" }} />
                        Supplier
                    </Agent>
                </Link>

                <Link to="/login/service-provider" style={{ textDecoration: "none" }}  >
                    <Agent>
                        <FiberManualRecordIcon sx={{ fontSize: 10, color: "blue" }} style={{ margin: "0px 10px" }} />
                        Service Partner
                    </Agent>
                </Link>


            </Top>
            <Container>
                <InnerContainer>
                    <form>
                        <h3>Forgot Password</h3>
                      

                        <div className='inputdata' >
                            <input  placeholder='Phone' disabled={isInputDisabled} type="number" id="phonenumber" onChange={(e) => setPhone(modifiedPhone(e.target.value))} required />
                        </div>


                        {expand === false &&
                            < button onClick={requestOTP} >Request OTP</button>
                        }
                        {expand && <div>
                            <div>
                                <input placeholder='Enter OTP' type="number" id="otp" onChange={(e) => SetOTP(e.target.value)} required />
                            </div>
                            <button onClick={verifyOTP}  >Submit</button>
                        </div>
                        }

                        <div id="recaptcha" ></div>

                        {/* <p style={{ margin: "0px" }} >or</p> */}
                        {/* <button onClick={signInWithGoogle}  > <GoogleIcon sx={{ fontSize: 15 }} style={{ marginRight: "10px" }} /> Sign up with Google</button> */}
                    </form>

                </InnerContainer>
            </Container >
        </>

    )
}


const Top = styled.div`
height: 8vh;
background-color: #3B726A;
display: flex;
padding: 10px 20px;
justify-content: flex-end;
align-items: center;

@media only screen and (max-width: 768px) {
    justify-content: space-around;
    height: 9vh;

    
    justify-content: space-between;
    svg{
        display: none;
    }      
}`

const Container = styled.div`
height: 90vh;
display: flex;
align-items: center;
justify-content: center;
font-family: 'Outfit';

@media only screen and (max-width: 768px) {
   margin-top: -25px;
}
`


const InnerContainer = styled.div`
    width: 330px;
    border-radius: 10px;
    padding: 38px 40px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 15px rgba(0.2, 0, 0, 0.3);

    form{
        height: 100%;
        
    }

    form p{
        text-align: center;
        margin: 20px 0px;
        font-size: 14px;
        margin-bottom: 35px;
    }

    form h3{
        font-size: 19px;
        text-align: center;
        margin-bottom: 30px;
    }

    form div{
        margin:20px 0px ;
    }

    input{
        width: 100%;
        padding: 10px;
        border-radius: 9px;
        border: 2px solid #b29f9f;        
    }


    button{
        width: 100%;
        padding: 13px;
        border-radius: 20px;
        border:none;
        cursor: pointer;
        background-image: linear-gradient(to right, #3370ca, #0087bd, #009297, #599679, #8c9674);
        font-weight: bold;
        color: white;
        margin: 15px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
    }

    Link{
        display: flex;
        justify-content: center;
        font-size: 14px;
    }

    @media only screen and (max-width: 768px) {
    width: 320px;
    border-radius: 10px;
    padding: 22px 28px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 15px rgba(0.2, 0, 0, 0.3);
    }


`


const Agent = styled.button`
padding: 9px 12px;
background: #56dada;
border: none;
border-radius: 20px;
margin: 0px 10px;
display: flex;
align-items: center;
cursor: pointer;
@media only screen and (max-width: 768px) {
    margin: 0px;
    justify-content: center;
    width: 110px;
    font-size: 12px;
}
`

const Admin = styled.button`
display: flex;
align-items: center;
padding: 5px 16px;
border: 1px solid white;
border-radius: 19px;
margin: 0px 15px;
color:white;
background-color: transparent;
cursor: pointer;
@media only screen and (max-width: 768px) {
    margin: 0px;
    padding: 8px 16px;
    width: 110px;
    justify-content: center;
}
`
