import { Reac, useState, useEffect } from 'react'
import styled from "styled-components"
import GradeIcon from '@mui/icons-material/Grade';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import { yellow } from '@mui/material/colors';
import { useLocation } from 'react-router-dom';
import axios from "axios"
import EditIcon from '@mui/icons-material/Edit';
import ServiceProviderPublicProfileUpdate from '../../components/ServiceProviderPublicProfileUpdate';
import { useSelector } from 'react-redux';


export default function ServiceProviderPublicProfile() {

    const { currentServiceProvider } = useSelector(state => state.serviceProvider);

    const location = useLocation();
    const [data, setData] = useState();
    const [open, setOpen] = useState(false);

    let val = location.pathname.split("/")[2];


    useEffect(() => {
        const getInfo = async () => {
            try {
                if (location.pathname.split("/")[2] == "publicprofile") {
                    const res = await axios.get(`${process.env.REACT_APP_APIKEY}/service-provider/${location.pathname.split("/")[3]}`, {
                        withCredentials: true,
                    });
                    console.log(res.data)
                    setData(res.data);
                } else {
                    const res = await axios.get(`${process.env.REACT_APP_APIKEY}/service-provider/${location.pathname.split("/")[2]}`, {
                        withCredentials: true,
                    });
                    console.log(res.data)
                    setData(res.data);
                }

            }
            catch (err) {
                // alert(err.respone.data.message);
                console.log(err)
            }
        }

        getInfo();
    }, []);


    return (
        <div style={{ backgroundImage: val !== "publicprofile" && "linear-gradient(to bottom, #2a3648, #1f3943, #213a3a, #2b3a31, #35382d)", width: "100%" }} >

            {open && <ServiceProviderPublicProfileUpdate open={open} setOpen={setOpen} />}
            {data &&
                <Container val={val} >
                    <Details val={val} >
                        <Img>
                            <img src={data?.img ? data?.img : "https://png.pngtree.com/png-clipart/20230604/ourmid/pngtree-3d-avatar-man-like-teacher-writer-png-image_7120495.png"} />
                        </Img>
                        <PersonalInfo>
                            <div id="left" >
                                <span>Name</span>
                                <span>Location</span>
                                <span>Projects Completed</span>
                                <span>Rating</span>
                            </div>

                            <div id="right" >
                                <span>{data?.name}</span>
                                <span>{data?.city}</span>
                                <span>{data?.totalProjectsCompleted}</span>
                                <span><GradeIcon  sx={{ color: yellow[500] }} />
                                    <GradeIcon sx={{ color: yellow[500] }} />
                                    <GradeIcon sx={{ color: yellow[500] }} />
                                    <StarHalfIcon sx={{ color: yellow[500] }} />
                                </span>
                            </div>
                        </PersonalInfo>
                        {location.pathname.split("/")[2] == "publicprofile" && <Edit onClick={() => setOpen(true)} ><span>Edit</span> <EditIcon sx={{ fontSize: 15 }} /> </Edit>}
                    </Details>
                    {data && data?.publicImages?.length !== 0 &&

                        <ImageContainer>
                            {
                                data && data?.publicImages?.map((item, index) => (
                                    <ProjectImg src={item} />
                                ))
                            }
                        </ImageContainer>}
                </Container>
            }
        </div>
    )

}


const Edit = styled.button`
    position: absolute;
    top: 21px;
    right: 31px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: blueviolet;
    border: none;
    color: white;
    padding: 6px 11px;
    gap: 9px;
    border-radius: 2px;
    font-family: 'Outfit';
    cursor: pointer;

    @media only screen and (max-width: 768px) {
        top:10px;
        right:10px;
        padding: 7px 7px;
        span{
            display: none;
           
        }
    }
`

const Container = styled.div`
    margin: auto;
    display: flex;
    width: ${(props) => props.val === "publicprofile" ? "100%" : "80%"};
    min-height: 100vh;
    flex-direction: column;
    font-family: 'Outfit';
    gap: 20px;
`

const Details = styled.div`
    color:white;
    background-color: #312326;
    display: flex;
    padding: 20px;
    justify-content: center;
    gap: 50px;
    margin: 20px 0px;
    margin-top:${(props) => props.val === "publicprofile" ? "0px" : "43px"};
    border-radius: 5px;
   position: relative;
   /* z-index: -1; */

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`
const Img = styled.div`
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background-color: azure;

    img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

   
`
const PersonalInfo = styled.div`
display: flex;
gap: 50px;
align-items: center;

#left , #right {
    display: flex;
    flex-direction: column;
    gap: 25px;

    span svg{
        width: 14px;
    }
}

@media only screen and (max-width: 768px) {
           justify-content: space-between;
            
           #right{
            margin-top: 7px;
           }
}
`

const ImageContainer = styled.div`
    background-color: #312326;
    display: flex;
    flex-wrap: wrap;
    padding: 40px;
    border-top-right-radius: 7px;
    border-top-left-radius:7px;
    gap:40px;
    justify-content: space-evenly;
    gap:50px;

    @media only screen and (max-width: 768px) {
            padding: 20px;
            gap:20px;
}
`
const ProjectImg = styled.img`
width: 30%;
height: 30vh;
border-radius: 6px;

@media only screen and (max-width: 768px) {
            width: 100%;
}
`