import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import "../../index.css"
import AddCardIcon from '@mui/icons-material/AddCard';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Navbar from "../../components/Navbar"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { fetchAllProjects } from "../../redux/projectSlice";
import UserProjectCard from '../../components/UserProjectCard';
import { loginFailure } from '../../redux/userSlice';
import Loader from '../../components/Loader';

import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import app from '../../firebase.js';

export default function CreateProject() {


    const { currentUser } = useSelector(state => state.user);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [active, setActive] = useState(false);
    const [userDetails, setUserDetails] = useState({ name: currentUser?.name, phone: currentUser?.phone, city: currentUser?.city, address: currentUser?.address, postalCode: currentUser?.postalCode })
    const [project, setProject] = useState({ service: "landscaping", area: "", budget: "", desc: "", photos: [] })

    const [images, setImages] = useState(null);


    const uploadFile = async (file) => {
        const storage = getStorage(app);
        const storageRef = ref(storage, `${currentUser?._id}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        await new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                (error) => {
                    console.error(error);
                    reject(error);
                },
                () => {
                    resolve();
                }
            );
        });
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        // console.log(downloadURL);
        return downloadURL;
    }


    const handleFileChange = async (e) => {
        setImages(e.target.files);
        const selectedPhotos = Array.from(e.target.files);

        const blockimages = await Promise.all(selectedPhotos.map(async (photo) => {
            return await uploadFile(photo);
        }));


        setProject((prevProject) => ({
            ...prevProject,
            photos: blockimages,
        }));
    };




    const convertImagesToBase64 = async (imageFiles) => {
        const base64Promises = imageFiles.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = () => {
                    resolve(reader.result);
                };

                reader.onerror = (error) => {
                    reject(error);
                };

                reader.readAsDataURL(file);
            });
        });
        try {
            const base64Strings = await Promise.all(base64Promises);
            return base64Strings;
        } catch (error) {
            throw error;
        }
    }



    const createProject = async (e) => {
        e.preventDefault()

        if (project.service === "" || project.budget === "" || project.desc === "") {
            alert("All fields are required")
        } else {
            try {
                setActive(true);

                const res = await axios.post(`${process.env.REACT_APP_APIKEY}/user/project/${currentUser?._id}`, project, {
                    withCredentials: true,
                    headers: {
                        "content-type": "application/json"
                    }
                });
                setActive(false);
                alert("Project Created Successfully ");
                navigate("/user/project");

            } catch (error) {
                dispatch(loginFailure());
                navigate("/")
            }

        }
    };


    useEffect(() => {
        const verification = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_APIKEY}/user/verify`, {
                    withCredentials: true,
                });

                if (!res.data) {
                    dispatch(loginFailure())
                    navigate("/")
                }

            }
            catch (err) {
                dispatch(loginFailure())
                navigate("/")
            }
        }
        verification();
    }, []);




    return (<>
        <Navbar />

        <Container>

            <LeftContainer>
                <Link to="/user" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user" ? "#e4f0f8" : "" }} >
                        <PersonIcon /> <p>  Account</p>
                    </Tab>
                </Link>
                <Link to="/user/profile" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/profile" ? "#e4f0f8" : "" }} >
                        <ManageAccountsIcon />  <p> Update Profile</p>
                    </Tab>
                </Link>
                <Link to="/user/project" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/project" ? "#e4f0f8" : "" }} >
                        <AssignmentIcon /> <p>Project Details</p>
                    </Tab>
                </Link>
                <Link to="/user/payment" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/payment" ? "#e4f0f8" : "" }} >
                        <AddCardIcon />     <p> Payment Options</p>
                    </Tab>
                </Link>
                <Link to="/user/support" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/support" ? "#e4f0f8" : "" }} >
                        <LocalPhoneIcon />   <p>Support </p>
                    </Tab>
                </Link>

            </LeftContainer>
            <RightContainer>
                <div className='activate-div' >
                    <h4>New Project</h4>
                </div>

                <CreateNewProject>

                    {active && <Loader />}
                    <Details id="pc-project" >

                        <div>
                            <label for="service">Select a Service :</label>
                            <label for="area">Area :</label>
                            <label for="budget">Your Budget :</label>
                            <label for="upload">Upload Images of your Space :</label>
                            <label for="desc">Description :</label>
                        </div>

                        <div>

                            <select id="service" name="service" onChange={(e) => setProject((prev) => ({ ...prev, service: e.target.value }))} >
                                <option hidden >Select a Service</option>
                                <option value="landscaping">Landscaping</option>
                                <option value="vertical gardening">Vertical Gardening</option>
                                <option value="kitchen gardening">Kitchen Gardening</option>
                                <option value="terrace gardening">Terrace Gardening</option>
                            </select>


                            {project.service === "landscaping" && <select id="area" name="area" onChange={(e) => setProject((prev) => ({ ...prev, area: e.target.value }))} >
                                <option hidden >Select an area</option>
                                <option value="Less than 5000 square-feet.">Less than 5000 square-feet.</option>
                                <option value="Less than 10000 square-feet.">Less than 10000 square-feet.</option>
                                <option value="Less than 20000 square-feet.">Less than 20000 square-feet.</option>
                                <option value="Less than 50000 square-feet.">Less than 50000 square-feet.</option>
                                <option value="Less than 100000 square-feet.">Less than 100000 square-feet.</option>
                                <option value="Less than 150000 square-feet.">Less than 150000 square-feet.</option>
                                <option value="It is more than 150000 square-feet.">It is more than 150000 square-feet.</option>
                            </select>}

                            {project.service === "terrace gardening" && <select id="area" name="area" onChange={(e) => setProject((prev) => ({ ...prev, area: e.target.value }))} >
                                <option hidden >Select an area</option>
                                <option value="Less than 1000 square-feet.">Less than 1000 square-feet.</option>
                                <option value="Less than 2000 square-feet.">Less than 2000 square-feet.</option>
                                <option value="Less than 5000 square-feet.">Less than 5000 square-feet.</option>
                                <option value="Less than 8000 square-feet.">Less than 8000 square-feet.</option>
                                <option value="Less than 10000 square-feet.">Less than 10000 square-feet.</option>
                                <option value="Less than 15000 square-feet.">Less than 15000 square-feet.</option>
                                <option value="It is more than 15000 square-feet.">It is more than 15000 square-feet.</option>
                            </select>}


                            {(project.service === "vertical gardening" || project.service === "kitchen gardening") && <select id="area" name="area" onChange={(e) => setProject((prev) => ({ ...prev, area: e.target.value }))} >
                                <option hidden >Select an area</option>
                                <option value="Less than 100 square-feet.">Less than 100 square-feet.</option>
                                <option value="Between 100 to 500 square-feet">Between 100 to 500 square-feet</option>
                                <option value="Between 500 to 1000 square-feet">Between 500 to 1000 square-feet</option>
                                <option value="It is more than 1000 square-feet.">It is more than 1000 square-feet.</option>
                            </select>}


                            <select id="budget" name="budget" onChange={(e) => setProject((prev) => ({ ...prev, budget: e.target.value }))} >
                                <option hidden >Select your budget</option>
                                <option value="Rs 1 Lakh to 1.5 Lakh.">Rs 1 Lakh to 1.5 Lakh.</option>
                                <option value="Rs 1.5 Lakh to 2 Lakh.">Rs 1.5 Lakh to 2 Lakh.</option>
                                <option value="Rs 2 Lakh to 3 Lakh.">Rs 2 Lakh to 3 Lakh.</option>
                                <option value="Rs 3 Lakh to 5 Lakh.">Rs 3 Lakh to 5 Lakh.</option>
                                <option value="Rs 5 Lakh to 10 Lakh.">Rs 5 Lakh to 10 Lakh.</option>
                            </select>


                            <input type="file" id="upload" name="image" accept="image/*" multiple onChange={handleFileChange} />


                            <textarea placeholder='Describe your requirements and please provide the dimensions of your space' id="desc" onChange={(e) => setProject((prev) => ({ ...prev, desc: e.target.value }))} >
                            </textarea>

                        </div>

                    </Details>


                    <Details id="mob-project" >
                        <div>
                            <label for="service">Select a Service :</label>
                            <select id="service" name="service" onChange={(e) => setProject((prev) => ({ ...prev, service: e.target.value }))} >
                                <option hidden >Select a Service</option>
                                <option value="landscaping">Landscaping</option>
                                <option value="vertical-gardening">Vertical Gardening</option>
                                <option value="kitchen-gardening">Kitchen Gardening</option>
                                <option value="terrace-gardening">Terrace Gardening</option>
                            </select>

                            {(project.service === "landscaping" || project.service === "terrace-gardening") && <label for="area">Area :</label>}
                            {project.service === "landscaping" && <select id="area" name="area" onChange={(e) => setProject((prev) => ({ ...prev, area: e.target.value }))} >
                                <option hidden >Select an area</option>
                                <option value="Less than 5000 square-feet.">Less than 5000 square-feet.</option>
                                <option value="Less than 10000 square-feet.">Less than 10000 square-feet.</option>
                                <option value="Less than 20000 square-feet.">Less than 20000 square-feet.</option>
                                <option value="Less than 50000 square-feet.">Less than 50000 square-feet.</option>
                                <option value="Less than 100000 square-feet.">Less than 100000 square-feet.</option>
                                <option value="Less than 150000 square-feet.">Less than 150000 square-feet.</option>
                                <option value="It is more than 150000 square-feet.">It is more than 150000 square-feet.</option>
                            </select>}
                            {project.service === "terrace-gardening" && <select id="area" name="area" onChange={(e) => setProject((prev) => ({ ...prev, area: e.target.value }))} >
                                <option hidden >Select an area</option>
                                <option value="Less than 1000 square-feet.">Less than 1000 square-feet.</option>
                                <option value="Less than 2000 square-feet.">Less than 2000 square-feet.</option>
                                <option value="Less than 5000 square-feet.">Less than 5000 square-feet.</option>
                                <option value="Less than 8000 square-feet.">Less than 8000 square-feet.</option>
                                <option value="Less than 10000 square-feet.">Less than 10000 square-feet.</option>
                                <option value="Less than 15000 square-feet.">Less than 15000 square-feet.</option>
                                <option value="It is more than 15000 square-feet.">It is more than 15000 square-feet.</option>
                            </select>}


                            <label for="budget">Your Budget :</label>
                            <select id="budget" name="budget" onChange={(e) => setProject((prev) => ({ ...prev, budget: e.target.value }))} >
                                <option hidden >Select your budget</option>
                                <option value="Rs 1 Lakh to 1.5 Lakh.">Rs 1 Lakh to 1.5 Lakh.</option>
                                <option value="Rs 1.5 Lakh to 2 Lakh.">Rs 1.5 Lakh to 2 Lakh.</option>
                                <option value="Rs 2 Lakh to 3 Lakh.">Rs 2 Lakh to 3 Lakh.</option>
                                <option value="Rs 3 Lakh to 5 Lakh.">Rs 3 Lakh to 5 Lakh.</option>
                                <option value="Rs 5 Lakh to 10 Lakh.">Rs 5 Lakh to 10 Lakh.</option>
                            </select>

                            <label for="upload">Upload Images of your Space :</label>
                            <input type="file" id="upload" name="image" accept="image/*" multiple onChange={handleFileChange} />


                            <label for="desc">Description :</label>
                            <textarea placeholder='Explain in short what bring you here ?' id="desc" onChange={(e) => setProject((prev) => ({ ...prev, desc: e.target.value }))} > </textarea>

                        </div>

                    </Details>


                    <button onClick={createProject} >Submit</button>

                </CreateNewProject>


            </RightContainer>

        </Container >
    </>
    )
}

const Container = styled.div`
display: flex;
height: 91vh;
font-family: 'Outfit';
@media only screen and (max-width: 768px) {
height: fit-content;
}
`
const LeftContainer = styled.div`
width: 20%;
display: flex;
flex-direction: column;
padding: 30px 40px;
background-color: #d4d1cd;
@media only screen and (max-width: 768px) {
display: none;
}
`
const RightContainer = styled.div`
width: 80%;
display: flex;
flex-direction: column;
padding: 55px 120px;

h4{
    font-size: 26px;
    margin-bottom: 35px;
}

 /* p{
    margin-bottom: 20px;
    font-family: cursive;
} */

.activate{
   
    padding: 7px 10px;
    background-color: blueviolet;
    border: none;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
} 

.activate-div{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

@media only screen and (max-width: 768px) {
    width: 100%;
    padding: 25px;

    h4{
        margin-bottom: 5px;
    }

}
`


const ImageContainer = styled.div`
height: 200px;
width: 200px;
overflow: hidden;
border-radius: 50%;

image{
    width: 100%;
    height: 100%;

}
`


const Top = styled.div`
width: 100%;
display: flex;
`


const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    row-gap: 10px;
    color: mediumvioletred;
`


const Details = styled.div`
display: flex;
gap: 50px;
padding: 25px 0px;

select,input{
    padding: 10px;
    width: 400px;
    border-radius: 8px;
    border: 1px solid lightblue;
}

label{
    padding: 10px;
}

div{
    display: flex;
    flex-direction: column;
    gap:30px
}

textarea{
    width: 405px;
    height: 95px;
    resize: none;
    padding: 10px;
}

@media only screen and (max-width: 768px) {
   flex-direction: column;
   div{
    gap:10px;
   }
   input,select{
    width:100%;
   }
   label{
    padding-left: 0px;
   }
   textarea{
    width: 100%;
    height: 155px;
   }
  
}
`


const Tab = styled.div`
padding: 13px;
border: 2px solid grey;
margin: 20px 0px;
display: flex;
align-items: center;
gap:20px;
cursor: pointer;
border-radius: 5px;
`


const Projects = styled.div`
    display: flex;
    gap: 35px;
`


const CreateNewProject = styled.div`
   display: flex;
    flex-direction: column;
    height: 100%;
    border:2px solid;
    padding: 5px 60px;
    border-radius: 5px;

button{
    width: 135px;
    padding: 10px;
    background-color: blueviolet;
    border: none;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

#mob-project{
    display: none;
}

@media only screen and (max-width: 768px) {

   

    padding: 10px;
    border: none;
    #mob-project{
    display: flex;
    padding: 12px 0px;
        }
    #pc-project{
        display: none;
    }
    button{
        width: 100%;
        padding: 15px;
        margin: 10px 0px  ;
    }

}


`
