import "../../index.css"
import React, { useState ,useEffect } from 'react';
import styled from "styled-components";
import Navbar from "../../components/Navbar"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { loginSuccess, loginFailure } from '../../redux/userSlice';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import MailIcon from '@mui/icons-material/Mail';
import AddCardIcon from '@mui/icons-material/AddCard';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';


export default function UserSupport() {


    const { currentUser } = useSelector(state => state.user)

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [userDetails, setUserDetails] = useState({ name: currentUser?.name, phone: currentUser?.phone, city: currentUser?.city, address: currentUser?.address, postalCode: currentUser?.postalCode })

    const updateUser = async (e) => {
        e.preventDefault();
        const updatationDetails = userDetails;
        try {
            const res = await axios.put(`${process.env.REACT_APP_APIKEY}/user/${currentUser._id}`, { updatationDetails }, { withCredentials: true, });
            dispatch(loginSuccess(res.data));
            navigate(`/user`);
        } catch (error) {
            console.log(error);
            dispatch(loginFailure());
        }
    }

    
    useEffect(() => {
        const verification = async () => {
          try {
            const res = await axios.get(`${process.env.REACT_APP_APIKEY}/user/verify`, {
              withCredentials: true,
            });
    
            if (!res.data) {
              dispatch(loginFailure())
              navigate("/")
            }
    
          }
          catch (err) {
            dispatch(loginFailure())
            navigate("/")
          }
        }
        verification();
      }, []);

    return (<>
        <Navbar />

        <Container>

            <LeftContainer>
                <Link to="/user" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user" ? "#e4f0f8" : "" }} >
                        <PersonIcon /> <p>  Account</p>
                    </Tab>
                </Link>
                <Link to="/user/profile" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/profile" ? "#e4f0f8" : "" }} >
                        <ManageAccountsIcon />  <p> Update Profile</p>
                    </Tab>
                </Link>
                <Link to="/user/project" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/project" ? "#e4f0f8" : "" }} >
                        <AssignmentIcon /> <p>Project Details</p>
                    </Tab>
                </Link>
                <Link to="/user/payment" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/payment" ? "#e4f0f8" : "" }} >
                        <AddCardIcon />     <p> Payment Options</p>
                    </Tab>
                </Link>
                <Link to="/user/support" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/support" ? "#e4f0f8" : "" }} >
                        <LocalPhoneIcon />   <p>Support </p>
                    </Tab>
                </Link>

            </LeftContainer>
            <RightContainer>
                <h4>Contact Us</h4>

                <Details>
                    <div>
                        <label>Message &nbsp; :</label>
                        <textarea id="desc"> </textarea>
                    </div>

                </Details>
                <button onClick={updateUser} >Send Message</button>
                <p> Connect us on Socials !</p>
                <Socials>
                    <a href="https://www.instagram.com/ecoventure.in" target="_blank" >
                        <InstagramIcon />
                    </a>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fecoventuredecor" target="_blank" >
                        <TwitterIcon />
                    </a>

                    <a href="https://www.facebook.com/ecoventure.in" target="_blank" >
                        <FacebookOutlinedIcon />
                    </a>

                    <a href="mailto:support@ecoventure.in">
                        <MailIcon />
                    </a>

                    <a href="tel:+918709291650" class="Blondie">
                        <LocalPhoneIcon />
                    </a>
                </Socials>
            </RightContainer>

        </Container>
    </>
    )
}

const Container = styled.div`
display: flex;
height: 91vh;
font-family: 'Outfit';
@media only screen and (max-width: 768px) {
height: fit-content;
}
`
const LeftContainer = styled.div`
width: 20%;
display: flex;
flex-direction: column;
padding: 30px 40px;
background-color: #d4d1cd;

@media only screen and (max-width: 768px) {
display: none;
}
`
const RightContainer = styled.div`
width: 80%;
display: flex;
flex-direction: column;
padding: 55px 120px;

h4{
    font-size: 26px;
    margin-bottom: 35px;
}

button{
    width: 146px;
    padding: 10px;
    background-color: blueviolet;
    border: none;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
}

p{
    margin-top: 45px;
}

@media only screen and (max-width: 768px) {
    width: 100%;
    padding: 25px;

    h4{
        margin-bottom: 8px;
    }
}
`
const ImageContainer = styled.div`
height: 200px;
width: 200px;
overflow: hidden;
border-radius: 50%;

image{
    width: 100%;
    height: 100%;

}
`
const Top = styled.div`
width: 100%;
display: flex;
`
const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    row-gap: 10px;
    color: mediumvioletred;
`

const Details = styled.div`
display: flex;
gap: 50px;
padding: 25px 0px;

input{
    padding: 10px;
    width: 400px;
    border-radius: 8px;
    border: 1px solid lightblue;
}

div{
    display: flex;
    flex-direction: column;
    gap:10px
}

textarea{
    width: 585px;
    height: 182px;
    resize: none;
    padding: 10px;
}

@media only screen and (max-width: 768px) {
div{
    width: 100%;
}
textarea{
    width: 100%;
}
}
`

const Tab = styled.div`
padding: 13px;
border: 2px solid grey;
margin: 20px 0px;
display: flex;
align-items: center;
gap:20px;
cursor: pointer;
border-radius: 5px;
`

const Socials = styled.div`
display: flex;
    gap: 30px;
    padding: 20px 0px;
    margin: 5px 0px;

svg{
    background: #493d3d;
    border-radius: 50%;
    padding: 10px;
    font-size: 40px;
    color:white;

}

@media only screen and (max-width: 768px) {
    justify-content: space-between;
    gap: 1px;
}
`