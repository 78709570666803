import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import "../../index.css"
import AddCardIcon from '@mui/icons-material/AddCard';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Navbar from "../../components/Navbar"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { loginFailure } from '../../redux/userSlice';
import Loader from '../../components/Loader';
import { projectFetchFailure } from '../../redux/projectSlice';

export default function UserProjectInfo() {


    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [project, setProject] = useState(null);
    const [designNum, setDesignNum] = useState(null);
    const [quotation, setQuotation] = useState(null);



    const { currentUser } = useSelector(state => state.user);

    useEffect(() => {
        const verification = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_APIKEY}/user/verify`, {
                    withCredentials: true,
                });

                if (!res.data) {
                    dispatch(loginFailure())
                    navigate("/")
                }

            }
            catch (err) {
                dispatch(loginFailure())
                navigate("/")
            }
        }
        verification();
    }, []);




    useEffect(() => {
        const fetchParticularProject = async (e) => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_APIKEY}/user/project/${location.pathname.split("/")[3]}/${currentUser._id}`, {
                    withCredentials: true,
                });
                setProject(res.data);
            } catch (err) {
                dispatch(loginFailure());
                navigate("/")
            }
        }

        fetchParticularProject();
    }, []);


    useEffect(() => {
        const retriveServicePartners = async () => {
            try {
                const quotations = project?.allocatedQuotations;
                console.log(quotations)
                const res = await axios.post(`${process.env.REACT_APP_APIKEY}/user/project/servicepartners/${currentUser?._id}`, quotations, {
                    withCredentials: true,
                });
                setQuotation(res.data);
                console.log(res.data, "......................")
            } catch (err) {
                // dispatch(loginFailure());
                // dispatch(projectFetchFailure());
                // navigate("/");
                console.log(err);
            }
        }
        if (project !== null) {
            retriveServicePartners();
        }

    }, [project])



    const submitDesign = async (e) => {
        e.preventDefault();

        try {

            if (designNum.length > 1) {
                alert("Enter the valid design number");
                return;
            }

            const res = await axios.put(`${process.env.REACT_APP_APIKEY}/user/project/${project?._id}/${currentUser._id}`, { num: designNum }, {
                withCredentials: true,
                headers: {
                    "content-type": "application/json"
                }
            });
            setProject(res.data);
            console.log(res.data);
            alert("Your selected design has been successfully submited ")

        } catch (error) {
            dispatch(loginFailure());
            navigate("/")
        }
    };

    const submitSelectedQuotation = async (e) => {
        // e.preventDefault();

        try {

            if (designNum.length > 1) {
                alert("Enter the valid quotation number");
                return;
            }

            const res = await axios.put(`${process.env.REACT_APP_APIKEY}/user/project/quotation/${project?._id}/${currentUser._id}`, { num: designNum }, {
                withCredentials: true,
                headers: {
                    "content-type": "application/json"
                }
            });
            setProject(res.data);
            console.log(res.data);
            alert("Your selected quotation has been successfully submited ")

        } catch (error) {
            dispatch(loginFailure());
            console.log(error)
            navigate("/")
        }

    }


    return (<>
        <Navbar />

        {!project && <Loader />}

        <Container>

            <LeftContainer>
                <Link to="/user" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user" ? "#e4f0f8" : "" }} >
                        <PersonIcon /> <p>  Account</p>
                    </Tab>
                </Link>
                <Link to="/user/profile" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/profile" ? "#e4f0f8" : "" }} >
                        <ManageAccountsIcon />  <p> Update Profile</p>
                    </Tab>
                </Link>
                <Link to="/user/project" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/project" ? "#e4f0f8" : "" }} >
                        <AssignmentIcon /> <p>Project Details</p>
                    </Tab>
                </Link>
                <Link to="/user/payment" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/payment" ? "#e4f0f8" : "" }} >
                        <AddCardIcon />     <p> Payment Options</p>
                    </Tab>
                </Link>
                <Link to="/user/support" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/support" ? "#e4f0f8" : "" }} >
                        <LocalPhoneIcon />   <p>Support </p>
                    </Tab>
                </Link>

            </LeftContainer>
            <RightContainer>

                <h4>Project Details</h4>

                <Details>
                    <div>
                        <div>
                            <p>Project Id   :   </p>
                            <p>Service Required   :</p>
                            <p>Budget     :</p>
                            <p>Area     :</p>
                        </div>
                        <div>
                            <p>{project?._id}</p>
                            <p>{project?.service}</p>
                            <p>{project?.budget}</p>
                            <p>{project?.area ? project.area : "Not Defined"}</p>
                        </div>
                    </div>

                </Details>


                {project?.designSent?.length === 0 ? (<Notifier>
                    <p> We'll be right back to you with designs in the next 2 days .</p>
                </Notifier>) :

                    <Designs>
                        <p>Your designs are as follows -</p>
                        <div id="image-set" >
                            {project?.designSent?.map((link, index) => {
                                return (
                                    <ImageContainer>
                                        <span>{index + 1}</span>
                                        <img key={link} src={link} />
                                    </ImageContainer>


                                )
                            })}
                        </div>
                    </Designs>
                }

                {(project?.designSent?.length !== 0 && !project?.designSelected) &&
                    <>

                        <Notifier id="pc-select-design" >
                            <p>Enter the design number you want to move forward with </p>
                            <input maxLength="1" onChange={(e) => setDesignNum(e.target.value)} style={{ textAlign: "center" }} type='number' />
                            <button onClick={submitDesign} >Sumbit</button>
                        </Notifier>

                        <Notifier id="mob-select-design" >
                            <p>Enter the design number you want to move forward with </p>
                            <div>
                                <input maxLength="1" onChange={(e) => setDesignNum(e.target.value)} style={{ textAlign: "center" }} type='number' />
                                <button onClick={submitDesign} >Sumbit</button>
                            </div>

                        </Notifier>
                    </>
                }

                {(project?.designSelected && !project.visibleToclient) && <Notifier>
                    <p>We have received your selected designs .
                        We'll be back soon with quotation .
                    </p>
                </Notifier>}



                {(quotation && project.visibleToclient) &&

                    <Designs>
                        <p>Quotations</p>

                        {quotation.map((quote, index) => {
                            return <QuotationDiv>

                                <a target='_blank' href={`https://ecoventure.in/service-provider/${quote?.phone}`}>
                                    <button onClick={() => window.location.href = ""} >See Profile</button>
                                </a>

                                <div>
                                    <div className="innerdiv" >
                                        <span>Quotation Number &nbsp; :</span>
                                        <span>Service Partner &nbsp; :</span>
                                        <span>Quotation Amount &nbsp; : </span>
                                    </div>

                                    <div className="innerdiv" >
                                        <span>{index + 1}</span>
                                        <span>{quote.name}</span>
                                        <span>{quote.costing.finalCosting}</span>
                                    </div>
                                </div>

                                <table>
                                    <tr>
                                        <th>Description of Services</th>
                                    </tr>
                                    <tbody>
                                        {quote.info.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                <td>{row.serviceDesc}</td>
                                            </tr>
                                        ))}
                                    </tbody>


                                </table>

                            </QuotationDiv>
                        })}
                    </Designs>
                }

                {(project?.visibleToclient && !project?.selectedQuotation) &&
                    <>

                        <Notifier id="pc-select-quotation" >
                            <p>Enter the Quotation Number you want to move forward with  </p>
                            <input maxLength="1" onChange={(e) => setDesignNum(Number(e.target.value))} style={{ textAlign: "center" }} type='number' />
                            <button onClick={submitSelectedQuotation} >Sumbit</button>
                        </Notifier>

                        <Notifier id="mob-select-quotation" >
                            <p>Enter the Quotation Number you want to move forward with  </p>
                            <div>
                                <input maxLength="1" onChange={(e) => setDesignNum(Number(e.target.value))} style={{ textAlign: "center" }} type='number' />
                                <button onClick={submitSelectedQuotation} >Sumbit</button>
                            </div>
                        </Notifier>
                    </>
                }



                {(project?.selectedQuotation) && <Notifier>
                    <p>We have received your selected quotation .
                        Our team will contact you soon .
                    </p>
                </Notifier>}





            </RightContainer>

        </Container>
    </>
    )
}

const Container = styled.div`
display: flex;
height: 91vh;
font-family: 'Outfit';
`
const LeftContainer = styled.div`
width: 20%;
display: flex;
flex-direction: column;
padding: 30px 40px;
background-color: #d4d1cd;

@media only screen and (max-width: 768px) {
    display: none;
}
`
const QuotationDiv = styled.div`
background-color: #e8e9ec;
padding: 28px;
margin: 40px 0px;
font-size: 15px;
border-radius: 2px;
position: relative;

>div{
    display: flex;
    gap: 40px;
}

.innerdiv{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

td{
    font-size: 14px;
    font-family: sans-serif;
}

button{
    position: absolute;
    top: 30px;
    color: white;
    right: 30px;
    padding: 6px 7px;
    background: #414845;
    border: none;
    font-family: 'Outfit';
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    background-color: #e8e9ec;
    padding: 15px;
    margin: 31px 0px;
    font-size: 14px;
    border-radius: 3px;
}

`

const Notifier = styled.div`
min-width:  465ps;
margin: 35px 0px;
background: #c9bed7;
display: flex;
padding: 3px 21px;
border-radius: 4px;

p{
    padding: 15px;
}
input{
    margin: 10px 50px;
    text-align: center;
    width: 50px;
}

button{
    align-self: flex-end;
    width: 129px;
    padding: 8px;
    background-color: blueviolet;
    border: none;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    margin: 10px;
}

@media only screen and (max-width: 768px) {
    margin: 10px 0px;
    padding: 0px;
    flex-direction: column;

    input{
    text-align: center;
    width: 120px;
    margin: 10px 12px;
    height: 30px;
    }
}
`



const RightContainer = styled.div`
width: 80%;
display: flex;
flex-direction: column;
padding: 55px 120px;
overflow-y: scroll;

h4{
    font-size: 26px;
    margin-bottom: 10px;
}

table {
      border-collapse: collapse;
      width: 100%;
      margin: auto;
      margin-top: 20px;
    }

    th, td {
      border: 1px solid #474654;
      padding: 12px;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
    }

    #mob-select-design,#mob-select-quotation{
        display: none;
    }

@media only screen and (max-width: 768px) {
    width: 100%;
    height: fit-content;
    padding: 10% 8%;

    #mob-select-design,#mob-select-quotation{
        display: flex;
    }

    #pc-select-design,#pc-select-quotation{
        display: none;
    }
}
`

const ImageContainer = styled.div`
position: relative;
span{
    display: flex;
    position: absolute;
    top: -8px;
    left: -8px;
    background: black;
    color: white;
    padding: 6px;
    height: 23px;
    width: 24px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}
image{
    width: 100%;
    height: 100%;

}
`

const Top = styled.div`
width: 100%;
display: flex;
`


const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    row-gap: 10px;
    color: mediumvioletred;
`

const Details = styled.div`
padding :20px 0px ;
h4{
    margin: 15px 0px;
    font-family: 'Outfit', sans-serif;
    font-size: 18px;
}
div{
    display: flex;
    gap:30px;
}
div div{
    display: flex;
    flex-direction: column;
    gap:25px;
    margin: 13px 0px;
}
div div:nth-child(2){
    font-family: none;
}

@media only screen and (max-width: 768px) {
    font-size: 14px;
    div{
        gap:10px;
        justify-content: space-between;
    }
}
`


const Tab = styled.div`
padding: 13px;
border: 2px solid grey;
margin: 20px 0px;
display: flex;
align-items: center;
gap:20px;
cursor: pointer;
border-radius: 5px;
`



const Designs = styled.div`
font-family: 'Outfit';
padding: 20px 0px;

>p{font-size: 18px;
    margin-bottom: 25px;
}
p:nth-child(2){
    font-size: 15px;
    font-family: 'Inter';
}
#image-set{
    display: flex;
    gap:20px;

    img{
    width: 280px;
    height: 180px;
    }
}

@media only screen and (max-width: 768px) {
    #image-set{
        flex-direction: column;
        align-items: center;
       img{
        width: 300px;
       }
    }
}
`