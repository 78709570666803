import React, { useState } from 'react';
import styled from 'styled-components';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Carousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images?.length);
    };

    const prevImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images?.length) % images?.length);
    };

    return (<>

        <Container>

            <button onClick={prevImage}><NavigateBeforeIcon /></button>
            <ImageContainer>
                <img src={images && images[currentIndex]} alt={`Image ${currentIndex + 1}`} />
            </ImageContainer>

            <button onClick={nextImage}><NavigateNextIcon /></button>
        </Container>
    </>
    );
};

export default Carousel;

const Container = styled.div`
display: flex;
align-items: center;
padding: 2px 20px;
gap: 30px;
margin: 15px 0px;

img{
    height: 100%;
    width: 100%;
    border-radius: 8px;
}

button{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
   padding: 0px;
   gap: 6px;
   margin: 0px;
   width: 100%;
}

`
const ImageContainer = styled.div`
height: 260px;
width: 425px;


@media only screen and (max-width: 768px) {
   width: 100%;
   height: 200px;
}
`