import { React, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import AdminQuotation from './AdminQuotation';
import { useLocation, useNavigate } from 'react-router-dom'
import OfflineShareIcon from '@mui/icons-material/OfflineShare';
import axios from "axios";
import { useSelector } from 'react-redux';
import { fetchProject, projectFetchFailure, fetchAllocatedQuotation } from '../../redux/projectSlice'
import { loginFailure } from '../../redux/adminSlice';
import { useDispatch } from 'react-redux';
import Loader from '../../components/Loader';


import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import app from '../../firebase.js';


export default function AdminProjectInfo() {

    const { currentProject } = useSelector(state => state.project);
    const { currentAdmin } = useSelector(state => state.admin);

    const [client, setClient] = useState();
    const [active, setActive] = useState(false);
    const [servicePartners, setServicePartners] = useState(null);

    console.log(currentProject)

    const fileInputRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleIconClick = () => {
        // Trigger file input click
        fileInputRef.current.click();
    };


    const convertImagesToBase64 = async (imageFiles) => {
        const base64Promises = imageFiles.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = () => {
                    resolve(reader.result);
                };

                reader.onerror = (error) => {
                    reject(error);
                };

                // Read the image file as a data URL
                reader.readAsDataURL(file);
            });
        });
        try {
            const base64Strings = await Promise.all(base64Promises);
            return base64Strings;
        } catch (error) {
            throw error;
        }
    }
    

    const uploadFile = async (file) => {
        const storage = getStorage(app);
        const storageRef = ref(storage, `${currentProject?.client}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        await new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                (error) => {
                    console.error(error);
                    reject(error);
                },
                () => {
                    resolve();
                }
            );
        });
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        // console.log(downloadURL);
        return downloadURL;
    }



    const sendImages = async (e) => {
        e.preventDefault();
        setActive(true);
        const selectedPhotos = Array.from(e.target.files);

        const images = await Promise.all(selectedPhotos.map(async (photo) => {
            return await uploadFile(photo);
        }));

        try {
            const res = await axios.put(`${process.env.REACT_APP_APIKEY}/admin/project/${currentProject?._id}/${currentAdmin._id}`, images, {
                withCredentials: true,
                headers: {
                    "content-type": "application/json"
                }
            });
            dispatch(fetchProject(res.data));
            setActive(false);
            alert("Images sent successfully !")
        } catch (error) {
            dispatch(projectFetchFailure());
        }
    };

   

    useEffect(() => {
        const verification = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_APIKEY}/admin/verify`, {
                    withCredentials: true,
                });

                if (!res.data) {
                    dispatch(loginFailure())
                    navigate("/")
                }

            }
            catch (err) {
                dispatch(loginFailure())
                navigate("/")
            }
        }
        verification();
    }, []);


    useEffect(()=>{
        
        

          const fetchClient = async (e) => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_APIKEY}/admin/client/${currentProject.client}/${currentAdmin?._id}`, {
                    withCredentials: true,
                });
                setClient(res.data);
            } catch (err) {
                dispatch(loginFailure());
                dispatch(projectFetchFailure());
                navigate("/");
            }
        }

        const retriveServicePartners = async () => {
            try {
                const quotations = currentProject?.allocatedQuotations;
                const res = await axios.post(`${process.env.REACT_APP_APIKEY}/admin/project/servicepartners/${currentAdmin?._id}`, quotations, {
                    withCredentials: true,
                });
                dispatch(fetchAllocatedQuotation(res.data));
                setServicePartners(res.data);
            } catch (err) {
                dispatch(loginFailure());
                dispatch(projectFetchFailure());
                navigate("/");
            }
        }
          fetchClient();
          retriveServicePartners();
    },[])


    return (
        <Container>

            <EssentialDetails>
                <Details>
                    <h4>Customer Details</h4>
                    <div>
                        <div>
                            <p>Customer Id   :   </p>
                            <p>Customer Name   :   </p>
                            <p>Mobile Number   :</p>
                            <p>Emali Id    :</p>
                        </div>
                        <div>
                            <p>{client?._id} </p>
                            <p>{client?.name}</p>
                            <p>{client?.phone}</p>
                            <p>{client?.email}</p>
                        </div>
                    </div>

                </Details>
                <Details>
                    <h4>Project Details</h4>
                    <div>
                        <div>
                            <p>Project Id   :   </p>
                            <p>Service Required   :</p>
                            <p>Budget     :</p>
                            <p>Area     :</p>
                        </div>
                        <div>
                            <p>{currentProject?._id}</p>
                            <p>{currentProject?.service}</p>
                            <p>{currentProject?.budget}</p>
                            <p>{currentProject?.area ? currentProject?.area : "Not Defined"}</p>
                        </div>
                    </div>

                </Details>
            </EssentialDetails>



            <Designs>
                <p>Customer Requirements</p>
                <p>{currentProject?.desc}</p>
                <div id="image-set" >
                    {currentProject?.photos.map((link) => {
                        return (
                            <img key={link} src={link} />
                        )
                    })}
                </div>

            </Designs>

            {currentProject && (currentProject?.designSent.length === 0 &&
                <>

                    <SendDesign onClick={handleIconClick}  >
                        <OfflineShareIcon />
                        Send Designs
                        <input
                            type="file"
                            id="upload"
                            name="image"
                            accept="image/*"
                            multiple
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={sendImages}
                        />
                    </SendDesign>

                </>
            )
            }

            {active && <Loader />}



            <Designs>
                {currentProject && (currentProject?.designSent.length !== 0 && <p>Designs Sent</p>)}
                <div id="image-set" >
                    {currentProject?.designSent?.map((link) => {
                        return (
                            <img key={link} src={link} />
                        )
                    })}

                </div>

            </Designs>

            <Designs>
                {currentProject?.designSelected && <p>Selected Designs</p>}
                <div id="image-set" >

                    {currentProject?.designSelected && <img src={currentProject?.designSent[currentProject?.designSelected - 1]} />}

                </div>

            </Designs>


            {(servicePartners?.length !==0   )&&

                <Designs>

                    <p>Allocated Service Partners</p>
                    <SupplierTable>

                        <thead>
                            <tr>
                                <th>Service Partner Id</th>
                                <th>Name</th>
                                <th>Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(servicePartners?.length !==0  ) && servicePartners?.map((quote,index) => {

                                return <tr key={quote?._id} >
                                    <td>{index+1}</td>
                                    <td>{quote?.name}</td>
                                    <td>{quote?.phone}</td>
                                </tr>
                            })}

                        </tbody>
                    </SupplierTable>
                </Designs>
            }

            {currentProject?.selectedQuotation && <Designs>
                <p>Selected Quotation</p>
                <SupplierTable>

                    <thead>
                        <tr>
                            <th>Service Partner</th>
                            <th>Name</th>
                            <th>Phone</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{currentProject?.selectedQuotation}</td>
                            <td>{ servicePartners && servicePartners[currentProject?.selectedQuotation-1]?.name}</td>
                            <td>{ servicePartners && servicePartners[currentProject?.selectedQuotation-1]?.phone}</td>
                        </tr>
                    </tbody>
                </SupplierTable>
            </Designs>}

        </Container>
    )
}


const EssentialDetails = styled.span`
display: flex;
gap:20% ;
`

const SendDesign = styled.button`
background: #23e3e3;
border-radius: 6px;
display: flex;
gap: 10px;
align-items: center;
border: none;
width: fit-content;
cursor: pointer;
font-family: 'Outfit';
margin: 40px 20px;
padding: 8px 11px;
`

const Designs = styled.div`
font-family: 'Outfit';

padding: 20px;

>p{font-size: 18px;
    margin-bottom: 25px;
}
p:nth-child(2){
    font-size: 15px;
    font-family: 'Inter';
}

#image-set{
    display: flex;
    gap:20px;

    img{
    width: 280px;
    height: 180px;
    }
}

`

const SupplierTable = styled.table`

            border-collapse: collapse;
            width: 100%;
            margin-top: 20px;
            font-size:15px;

        th, td {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
        }

        th {
            background-color: #f2f2f2;
        }

        tbody{
            font-size: 14px;
        }
`

const Menu = styled.div`
background-color: #d4d1cd;
padding: 13px 40px;
display: flex;
gap: 45px;
align-items: center;
border-radius: 5px;
font-weight: bold;

div{
    padding: 7px 14px;
    border: 2px solid grey;
    border-radius: 2px;
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: black;
    cursor: pointer;
}
`


const Container = styled.div`
margin: 20px;
display: flex;
font-family: 'Inter', sans-serif;
flex-direction: column;
padding-top: 0px;
`
const LeftContainer = styled.div`
width: 30%;
height: 100%;
`

const RightContainer = styled.div`
display: flex;
flex-direction: column;
width: 70%;
height: 100%;
background-color: white;
font-family: 'Outfit';
border-radius: 20px;
padding: 20px;

 /* >div{
    padding: 21px;
    font-size: 19px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
button{
    padding: 15px;
    font-size: 16px;
    margin: 20px;
    background-color: forestgreen;
    border: none;
    color: white;
    border-radius: 7px;
} */
`

const ScrollList = styled.section`
overflow-y: scroll;
border-radius: none;
height: 100%;
`

const Details = styled.div`
padding :20px ;
h4{
    margin: 15px 0px;
    font-family: 'Outfit', sans-serif;
    font-size: 18px;
}

div{
    display: flex;
    gap:30px;
}

div div{
    display: flex;
    flex-direction: column;
    gap:25px;
    margin: 13px 0px;

}
`
const Supplier = styled.div`
margin: 16px;
justify-content: space-between;
display: flex;
background-color: aqua;
padding: 20px;
input{
    height: 20px;
    width: 20px;
}
`

const ActionTab = styled.div`
    height: 65px;
    background-color: rebeccapurple;
    width: 98%;
    border-radius: 10px;
`


