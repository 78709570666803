import { React, useEffect } from 'react';
import styled from "styled-components";
import "../../index.css"

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { loginFailure } from '../../redux/supplierSlice';

export default function SupplierInfo() {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { currentSupplier } = useSelector(state => state.supplier);


    useEffect(() => {
        const verification = async () => {
          try {
            const res = await axios.get(`${process.env.REACT_APP_APIKEY}/supplier/verify`, {
              withCredentials: true,
            });
    
            if (!res.data) {
              dispatch(loginFailure());
              navigate("/")
            }
          }
          catch (err) {
            dispatch(loginFailure())
            navigate("/")
          }
        }
        verification();
      }, []);

    return (

        <>



            <RightContainer>
                <h4>Account Details</h4>
                <Top>
                    <ImageContainer>
                        <img style={{ height: "100%", width: "100%" }} src={currentSupplier?.img} />
                    </ImageContainer>
                    <NameContainer>
                        <p className='username' >{currentSupplier?.name}</p>
                        <p>{currentSupplier?.email}</p>
                    </NameContainer>
                </Top>

                <Details id="pc-details" >
                    <div>
                        <label>Phone Number</label>
                        <label>City</label>
                        <label>Address</label>
                        <label>Postal Code</label>
                    </div>
                    <div>
                        <input disabled value={currentSupplier?.phone} type='text' />
                        <input disabled value={currentSupplier?.city} type='text' />
                        <input disabled value={currentSupplier?.address} type='text' />
                        <input disabled value={currentSupplier?.postalCode} type='text' />
                    </div>
                </Details>

                <Details id="mob-details" >
                    <div>
                        <label>Phone Number</label>
                        <input disabled value={currentSupplier?.phone} type='text' />
                        <label>City</label>
                        <input disabled value={currentSupplier?.city} type='text' />
                        <label>Address</label>
                        <input disabled value={currentSupplier?.address} type='text' />
                        <label>Postal Code</label>
                        <input disabled value={currentSupplier?.postalCode} type='text' />
                    </div>
                </Details>
            </RightContainer>
        </>
    )
}


const Container = styled.div`
display: flex;
height: 91vh;
font-family: 'Outfit';
@media only screen and (max-width: 768px) {
height: fit-content;
}
`
const LeftContainer = styled.div`
width: 20%;
display: flex;
flex-direction: column;
padding: 30px 40px;
background-color: #d4d1cd;
@media only screen and (max-width: 768px) {
display: none;
}
`
const RightContainer = styled.div`
display: flex;
flex-direction: column;
padding: 0px 50px;
font-family: 'Outfit';

h4{
    font-size: 26px;
    margin-bottom: 35px;
}

#mob-details{
    display: none;
}

@media only screen and (max-width: 768px) {
    width: 100%;
    padding: 15px 12px;

    #pc-details{
        display: none;
    }

    #mob-details{
        display: flex;
        padding: 25px 0px;
        >div{
            width: 100%;
            gap:12px;
        }
        input{
            width: 100%;
        }
        label{
            padding-left: 0px;
        }
    }

}

`
const ImageContainer = styled.div`
height: 125px;
width: 125px;
overflow: hidden;
border-radius: 50%;
@media only screen and (max-width: 768px) {
    height: 100px;
    width: 100px;
}
`
const Top = styled.div`
width: 100%;
display: flex;
margin: 20px 0px;
`
const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 55px;
    row-gap: 10px;
    color: mediumvioletred;
    margin-top: -10px;

    @media only screen and (max-width: 768px) {
        padding: 0px;
        margin-left: 20px;
        .username{
            font-size: 25px;
        }
    }
`

const Details = styled.div`
display: flex;
gap: 50px;
padding: 25px 0px;

input{
    padding: 10px;
    width: 480px;
    border-radius: 8px;
    border: 1px solid lightblue;
}

label{
    padding: 10px;
}

div{
    display: flex;
    flex-direction: column;
    gap:35px
}

#services-div{
    padding: 10px 0px;
    gap: 10px;
    display: flex;
    /* flex-direction: row;
    flex-wrap: wrap; */
}

`

const Tab = styled.div`
padding: 13px;
border: 2px solid grey;
margin: 20px 0px;
display: flex;
align-items: center;
gap:20px;
cursor: pointer;
border-radius: 5px;
`