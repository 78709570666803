import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import "../../index.css"
import AddCardIcon from '@mui/icons-material/AddCard';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Navbar from "../../components/Navbar"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { loginSuccess,loginFailure } from '../../redux/serviceProviderSlice';

export default function UpdateSP() {


    const { currentServiceProvider } = useSelector(state => state.serviceProvider)

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [userDetails, setUserDetails] = useState({ name: currentServiceProvider?.name, phone: currentServiceProvider?.phone, city: currentServiceProvider?.city, address: currentServiceProvider?.address, postalCode: currentServiceProvider?.postalCode, servicesOffered: currentServiceProvider?.servicesOffered })


    useEffect(() => {
        const verification = async () => {
          try {
            const res = await axios.get(`${process.env.REACT_APP_APIKEY}/service-provider/verify`, {
              withCredentials: true,
            });
    
            if (!res.data) {
              dispatch(loginFailure());
              navigate("/")
            }
          }
          catch (err) {
            dispatch(loginFailure())
            navigate("/")
          }
        }
        verification();
      }, []);

    
    const handleCheckbox = (e) => {
        const { value, checked } = e.target;

        setUserDetails((prevDetails) => {
            const updatedServices = new Set(prevDetails.servicesOffered);

            if (checked) {
                updatedServices.add(value);
            } else {
                updatedServices.delete(value);
            }

            return {
                ...prevDetails,
                servicesOffered: Array.from(updatedServices)
            };
        });
    };



    const updateUser = async (e) => {
        e.preventDefault();

        const updatationDetails = userDetails ;

        if (updatationDetails.name === "" || updatationDetails.phone === "" || updatationDetails.city === "" || updatationDetails.address === "" || updatationDetails.postalCode === "") {
            alert("All fields are required")
        } else {
            try {
                const res = await axios.put(`${process.env.REACT_APP_APIKEY}/service-provider/${currentServiceProvider._id}`, { updatationDetails }, { withCredentials: true, });
                dispatch(loginSuccess(res.data));
                alert("Profile Updated Successfully")
                navigate(`/service-provider`);
            } catch (error) {
                dispatch(loginFailure());
                navigate("/service-provider/account")
            }
        }
    }



    return (<>

        <RightContainer>
            <div>
                <h4>Update Profile </h4>
                <button id="pc-update-btn"  onClick={updateUser} >Save Changes</button>
            </div>


            <Details id="pc-update" >
                <div>
                    <label>Username</label>
                    <label>Phone Number</label>
                    <label>City</label>
                    <label>Address</label>
                    <label>Postal Code</label>
                    <label>Services</label>
                </div>

                <div>
                    <input value={userDetails.name} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, name: e.target.value }))} type='text' />
                    <input value={userDetails.phone} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, phone: e.target.value }))} type='text' />
                    <input value={userDetails.city} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, city: e.target.value }))} type='text' />
                    <input value={userDetails.address} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, address: e.target.value }))} type='text' />
                    <input value={userDetails.postalCode} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, postalCode: e.target.value }))} type='text' />
                    
                    <div id="services-container">
                        <span>
                            <input
                                type="checkbox"
                                id="landscaping-only-plants"
                                name="services"
                                value="Landscaping (Only plant related services)"
                                onClick={handleCheckbox}
                                checked={userDetails.servicesOffered.includes("Landscaping (Only plant related services)")}
                            />
                            <label htmlFor="landscaping-only-plants">Landscaping (Only plant related services)</label>
                        </span>

                        <span>
                            <input
                                type="checkbox"
                                id="landscaping-civil-services"
                                name="services"
                                value="Landscaping (Plant and civil services including swimming pool, fountain, etc.)"
                                onClick={handleCheckbox}
                                checked={userDetails.servicesOffered.includes("Landscaping (Plant and civil services including swimming pool, fountain, etc.)")}
                            />
                            <label htmlFor="landscaping-civil-services">Landscaping (Plant and civil services including swimming pool, fountain, etc.)</label>
                        </span>

                        <span>
                            <input
                                type="checkbox"
                                id="vertical-gardening"
                                name="services"
                                value="Vertical Gardening"
                                onClick={handleCheckbox}
                                checked={userDetails.servicesOffered.includes("Vertical Gardening")}

                            />
                            <label htmlFor="vertical-gardening">Vertical Gardening</label>
                        </span>

                        <span>
                            <input
                                type="checkbox"
                                id="roof-top-gardening"
                                name="services"
                                value="Roof Top Gardening"
                                onClick={handleCheckbox}
                                checked={userDetails.servicesOffered.includes("Roof Top Gardening")}
                            />
                            <label htmlFor="roof-top-gardening">Roof Top Gardening</label>
                        </span>

                        <span>
                            <input
                                type="checkbox"
                                id="kitchen-gardening"
                                name="services"
                                value="Kitchen Gardening"
                                onClick={handleCheckbox}
                                checked={userDetails.servicesOffered.includes("Kitchen Gardening")}
                            />
                            <label htmlFor="kitchen-gardening">Kitchen Gardening</label>
                        </span>
                    </div>



                </div>

            </Details>

            <Details id="mob-update" >
                <div>
                    <label>Username</label>
                    <input value={userDetails.name} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, name: e.target.value }))} type='text' />
                    <label>Phone Number</label>
                    <input value={userDetails.phone} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, phone: e.target.value }))} type='text' />
                    <label>City</label>
                    <input value={userDetails.city} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, city: e.target.value }))} type='text' />
                    <label>Address</label>
                    <input value={userDetails.address} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, address: e.target.value }))} type='text' />
                    <label>Postal Code</label>
                    <input value={userDetails.postalCode} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, postalCode: e.target.value }))} type='text' />
                    <label>Services</label>

                    <div id="services-container">
                        <span>
                            <input
                                type="checkbox"
                                id="landscaping-only-plants"
                                name="services"
                                value="Landscaping (Only plant related services)"
                                onClick={handleCheckbox}
                                checked={userDetails.servicesOffered.includes("Landscaping (Only plant related services)")}
                            />
                            <label htmlFor="landscaping-only-plants">Landscaping (Only plant related services)</label>
                        </span>

                        <span>
                            <input
                                type="checkbox"
                                id="landscaping-civil-services"
                                name="services"
                                value="Landscaping (Plant and civil services including swimming pool, fountain, etc.)"
                                onClick={handleCheckbox}
                                checked={userDetails.servicesOffered.includes("Landscaping (Plant and civil services including swimming pool, fountain, etc.)")}
                            />
                            <label htmlFor="landscaping-civil-services">Landscaping (Plant and civil services including swimming pool, fountain, etc.)</label>
                        </span>

                        <span>
                            <input
                                type="checkbox"
                                id="vertical-gardening"
                                name="services"
                                value="Vertical Gardening"
                                onClick={handleCheckbox}
                                checked={userDetails.servicesOffered.includes("Vertical Gardening")}

                            />
                            <label htmlFor="vertical-gardening">Vertical Gardening</label>
                        </span>

                        <span>
                            <input
                                type="checkbox"
                                id="roof-top-gardening"
                                name="services"
                                value="Roof Top Gardening"
                                onClick={handleCheckbox}
                                checked={userDetails.servicesOffered.includes("Roof Top Gardening")}
                            />
                            <label htmlFor="roof-top-gardening">Roof Top Gardening</label>
                        </span>

                        <span>
                            <input
                                type="checkbox"
                                id="kitchen-gardening"
                                name="services"
                                value="Kitchen Gardening"
                                onClick={handleCheckbox}
                                checked={userDetails.servicesOffered.includes("Kitchen Gardening")}
                            />
                            <label htmlFor="kitchen-gardening">Kitchen Gardening</label>
                        </span>
                    </div>

                    <button onClick={updateUser} >Save Changes</button>

                </div>
            </Details>

        </RightContainer>

    </>
    )
}

const Container = styled.div`
display: flex;
height: 91vh;
font-family: 'Outfit';
@media only screen and (max-width: 768px) {
height: fit-content;
}
`
const LeftContainer = styled.div`
width: 20%;
display: flex;
flex-direction: column;
padding: 30px 40px;
background-color: #d4d1cd;
@media only screen and (max-width: 768px) {
display: none;
}
`
const RightContainer = styled.div`
display: flex;
flex-direction: column;
padding: 0px 50px;
font-family: 'Outfit';
width: 100%;
overflow-y: hidden;
padding-right: 0px;



>div:nth-child(1){
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

#mob-update{
    display: none;
}

h4{
    font-size: 26px;
}

button{
    width: 120px;
    padding: 10px;
    background-color: blueviolet;
    border: none;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    margin: 10px;
}

@media only screen and (max-width: 768px) {
    width: 100%;
    padding: 10px 15px;

    #pc-update{
        display: none;
    }

    h4{
        margin-bottom:0px;
    }

    button{
        width: 100%;
        padding: 15px;
        margin: 10px 0px  ;
    }

    #mob-update{
        display: flex;
        >div{
            width: 100%;
            gap:12px;
        }
        input{
            width: 100%;
        }
        label{
            padding-left: 0px;
        }
    }

    #pc-update-btn{
        display: none;
    }

}
`



const ImageContainer = styled.div`
height: 200px;
width: 200px;
overflow: hidden;
border-radius: 50%;

image{
    width: 100%;
    height: 100%;

}
`
const Top = styled.div`
width: 100%;
display: flex;
`
const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    row-gap: 10px;
    color: mediumvioletred;
`

const Details = styled.div`
display: flex;
gap: 50px;
padding:  0px;

input{
    padding: 10px;
    width: 600px;
    border-radius: 8px;
    border: 1px solid lightblue;
}

label{
    padding: 10px;
}

div{
    display: flex;
    flex-direction: column;
    gap:30px
}

#services-container{
    gap:0px;
    margin: 15px 0px;
    input{
        width: 15px;
        height: 15px;
    }

    span{
        display: flex;
        align-items: center;
        gap:20px;
    }
}

@media only screen and (max-width: 768px) {
    #services-container{
        margin: 0px;
        margin-top: -10px;

        input{
        height: 15px;
        width: unset;
    }
    }
}


`

const Tab = styled.div`
padding: 13px;
border: 2px solid grey;
margin: 20px 0px;
display: flex;
align-items: center;
gap:20px;
cursor: pointer;
border-radius: 5px;
`