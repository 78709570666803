import React, { useState } from 'react'
import styled from 'styled-components'
import PersonIcon from '@mui/icons-material/Person';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { auth } from "../firebase"
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import axios from "axios"

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { loginFailure, loginSuccess } from '../redux/supplierSlice';


export default function SupplierPhoneLogin() {

    const [load, setLoad] = useState(false);
    const [phone, setPhone] = useState("");
    const [isInputDisabled, setIsInputDisabled] = useState(false);

    const [OTP, SetOTP] = useState("");
    const [expand, setExpand] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const modifiedPhone = (ph) => {
        let start = ph.slice(0, 4);
        let mid = ph.slice(4, 7);
        let end = ph.slice(7, 10);
        let phone = "+91 " + start + "-" + mid + "-" + end;
        return phone;
    }


    const requestOTP = async (e) => {
        e.preventDefault();

        if (phone.length !== 10) {
            alert("Invalid phone number !");
            return
        }
        const newwPhone = modifiedPhone(phone)
        setPhone(newwPhone);
        console.log(newwPhone)
        setIsInputDisabled(true);
        setExpand(true);
        try {
            recaptaGenerator();
            let verifier = window.recaptchaVerifier;
            signInWithPhoneNumber(auth, newwPhone, verifier).then(confirmationResult => {
                window.confirmationResult = confirmationResult;

            }).catch(error => {
                console.log(error)
                alert("Too many requests , try after some time !")
            });

        } catch (error) {
            console.error('Error sending OTP:', error.response.data.error);
        }
    }


    const recaptaGenerator = () => {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', {
            'size': 'invisible',
            'callback': (response) => {
            }
        });
    }



    const verifyOTP = async (e) => {
        e.preventDefault();
        try {
            const result = await window.confirmationResult.confirm(OTP);
            console.log(result)
            const id = result.user.uid;
            console.log(id);
            setLoad(true)
            const res = await axios.post(`${process.env.REACT_APP_APIKEY}/supplier/phone/${id}`, { phone : phone }, { withCredentials: true, });
            dispatch(loginSuccess(res.data));
            if (res.data.profileCompleted) {
                navigate("/supplier");
            } else {
                navigate("/supplier/update");
            }
        } catch (error) {
            alert(error.response.data.message);
            console.log(error)
            setLoad(false)
            dispatch(loginFailure());
        }
    }


    return (
        <>
            <Top>
                <Link to="/login" style={{ textDecoration: "none" }}  >
                    <Admin>
                        <PersonIcon style={{ marginRight: "5px" }} /> User
                    </Admin>
                </Link>

                <Link to="/login/service-provider" style={{ textDecoration: "none" }}  >
                    <Agent>
                        <FiberManualRecordIcon sx={{ fontSize: 10, color: "blue" }} style={{ margin: "0px 10px" }} />
                        Service Partner
                    </Agent>
                </Link>
            </Top>

            <Container>
                <InnerContainer>
                    <form>

                        <h3>Supplier Sign in</h3>
                        <p>to cointinue to Ecoventure</p>


                        {!expand &&
                            < div className='inputdata'>
                                <input placeholder='Phone' disabled={isInputDisabled} type="number" id="phonenumber" onChange={(e) => setPhone(e.target.value)} required />
                            </div>
                        }

                        <div id="recaptcha" ></div>

                        {!expand &&
                            <>

                                < button onClick={requestOTP} >Request OTP</button>
                            </>
                        }

                        {expand &&

                            <>
                                <div className='inputdata' >
                                    <input placeholder='Enter OTP' type="number" id="phonenumber" onChange={(e) => SetOTP(e.target.value)} required />
                                </div>
                                < button onClick={verifyOTP} >Verify OTP</button>
                            </>

                        }


                    </form>

                </InnerContainer>
            </Container >
        </>

    )
}


const Top = styled.div`
height: 8vh;
background-color: #3B726A;
display: flex;
padding: 10px 20px;
justify-content: flex-end;
align-items: center;
`

const Container = styled.div`
height: 90vh;
display: flex;
align-items: center;
justify-content: center;
font-family: 'Outfit';
`


const InnerContainer = styled.div`
    width: 330px;
    border-radius: 10px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 15px rgba(0.2, 0, 0, 0.3);

    form{
        height: 100%;
        
    }

    form p{
        text-align: center;
        margin: 20px 0px;
        font-size: 14px;
        margin-bottom: 55px;
    }

    form h3{
        font-size: 22px;
        text-align: center;
    }

    form div{
        margin:35px 0px ;
    }

    input{
        width: 100%;
        padding: 12px;
        border-radius: 9px;
        border: 2px solid #b29f9f;        
    }


    button{
        width: 100%;
        padding: 15px;
        border-radius: 20px;
        border:none;
        cursor: pointer;
        background-image: linear-gradient(to right, #3370ca, #0087bd, #009297, #599679, #8c9674);
        font-weight: bold;
        color: white;
    }

    Link{
        display: flex;
        justify-content: center;
        font-size: 14px;
    }


`


const Agent = styled.button`
padding: 9px 12px;
background: #56dada;
border: none;
border-radius: 20px;
margin: 0px 10px;
display: flex;
align-items: center;
cursor: pointer;
`

const Admin = styled.button`
display: flex;
align-items: center;
padding: 5px 16px;
border: 1px solid white;
border-radius: 19px;
margin: 0px 15px;
color:white;
background-color: transparent;
cursor: pointer;
`
const OTPContainer = styled.div`
display: flex;
gap: 10px;
input{
    text-align: center;
}
`