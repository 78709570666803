import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Link as ScrollLink, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import axios from "axios"
import { logout } from '../redux/userSlice';

export default function SPHamburger() {

    const [showMenu, setShowMenu] = useState(false);

    const { currentServiceProvider } = useSelector(state => state.serviceProvider);

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const closeMenu = () => {
        document.getElementById('menu__toggle').checked = false;
    }

    const logOut = async () => {
        const res = await axios.post(`${process.env.REACT_APP_APIKEY}/auth/logout`, {}, { withCredentials: true });
        dispatch(logout());
        navigate("/");
    }



    return (
        <Container>
            <div class="hamburger-menu">
                <input id="menu__toggle" type="checkbox" />
                <label id="openMenu" class="menu__btn" for="menu__toggle">
                    <span></span>
                </label>

                <ul id="menu" className="menu__box">


                    <>
                        <Link to="/service-provider"  >
                            <Tab class="menu__item"  onClick={closeMenu} style={{ border: location.pathname === "/service-provider" ? "2px solid" : "", background: location.pathname === "/service-provider" ? "white" : "" }} >
                                New Projects
                            </Tab>
                        </Link>

                        <Link to="/service-provider/inprogress" >
                            <Tab class="menu__item" onClick={closeMenu} style={{ border: location.pathname === "/service-provider/inprogress" ? "2px solid" : "", background: location.pathname === "/service-provider/inprogress" ? "white" : "" }} >
                                In Progess
                            </Tab>
                        </Link>

                        <Link to="/service-provider/completed" >
                            <Tab class="menu__item" onClick={closeMenu}  style={{ border: location.pathname === "/service-provider/completed" ? "2px solid" : "", background: location.pathname === "/service-provider/completed" ? "white" : "" }} >
                                Completed Projects
                            </Tab>
                        </Link>

                        <Link to="/service-provider/account" >
                            <Tab class="menu__item" onClick={closeMenu}  style={{ border: location.pathname === "/service-provider/account" ? "2px solid" : "", background: location.pathname === "/service-provider/account" ? "white" : "" }} >
                                Account
                            </Tab>
                        </Link>

                        <Link to="/service-provider/updateprofile" >
                            <Tab class="menu__item" onClick={closeMenu}  style={{ border: location.pathname === "/service-provider/updateprofile" ? "2px solid" : "", background: location.pathname === "/service-provider/updateprofile" ? "white" : "" }} >
                                Update Profile
                            </Tab>
                        </Link>

                        <Link to={`/service-provider/publicprofile/${currentServiceProvider?._id}`} >
                            <Tab class="menu__item" onClick={closeMenu}  style={{ border: location.pathname === "/service-provider/updateprofile" ? "2px solid" : "", background: location.pathname === "/service-provider/updateprofile" ? "white" : "" }} >
                                Public Profile
                            </Tab>
                        </Link>

                        <button  onClick={logOut} id="logoutbtn" >Logout</button>

                        
                    </>



                    {/* <UserInfo>
                        {
                            (location.pathname.startsWith("/user") && currentServiceProvider) ?
                                <User onClick={logOut} >
                                    Logout
                                </User>
                                :
                                (currentServiceProvider && <Link to="/user" style={{ textDecoration: "none" }} >
                                    <Admin>
                                        <PersonIcon style={{ marginRight: "3px" }} />
                                        <p>Account</p>
                                    </Admin>
                                </Link>)


                        }

                        {!currentServiceProvider && <Link to="/login" >
                            <User>
                                Sign In
                            </User>
                        </Link>}

                    </UserInfo> */}


                </ul>




            </div>
        </Container>
    )
}



const Tab = styled.button`
    font-family: 'Outfit';
    padding: 15px;
    width: 200px;
    background-color: #e7a9b8;
    border: none;
    margin: 18px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
`



const Container = styled.div`
display: none;
z-index: 10;

#menu__toggle {
  opacity: 0;
}

#menu__toggle:checked + .menu__btn > span {
  transform: rotate(45deg);
}

#menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
  background-color: black;
}

#menu__toggle:checked + .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
  background-color: black;
}

#menu__toggle:checked ~ .menu__box {
  right: 0; /* Adjusted from left: -100% to right: 0 */
}


.menu__btn {
    position: fixed;
    top: 42px;
    right: 5cqh;
    width: 26px;
    height: 30px;
    cursor: pointer;
    z-index: 1;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: white;
  transition-duration: .25s;
  
}

.menu__btn > span::before {
  content: '';
  top: -8px;
}

.menu__btn > span::after {
  content: '';
  top: 8px;
  
}

.menu__box {
  display: block;
  position: fixed;
  top: 0;
  right: -100%; /* Adjusted from left: -100% to right: -100% */
  width: 300px;
  height: 100%;
  margin: 0;
  padding: 80px 0;
  list-style: none;
  background-color: #d6d2da;
  box-shadow: -2px 2px 6px rgba(0, 0, 0, .4); /* Adjusted from 2px 2px 6px to -2px 2px 6px */
  transition-duration: .25s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu__item {
    text-align: center;
  display: block;
  padding: 12px 24px;
  color: #333;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition-duration: .25s;
  margin: 20px;
}

.menu__item:hover {
  background-color: #CFD8DC;
}

#logoutbtn{
    background: blueviolet;
    color: white;
    padding: 13px;
    border: none;
    width: 200px;
    margin: 20px 0px;
    border-radius: 3px;
}

@media only screen and (max-width: 768px) {
   display: block;
}
`

const LinkContainer = styled.ul`
display: flex;
flex-direction: row;
padding-right: 240px;

li{
    list-style: none;
    margin: 22px 44px;
    color: black;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 768px) {
   display: flex;
}
`



const UserInfo = styled.div`
display: flex;
justify-content: center;
`

const User = styled.button`
    padding: 8px 10px;
    width: 125px;
    border-radius: 5px;
    border: none;
    background: aliceblue;
    margin: 0px 10px;
    font-size: 14px;
    font-family: 'Inter',sans-serif;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    font-size: 17px;
    color: white;
    background: #324344;
`
const Admin = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 11px;
    border: 1px solid white;
    border-radius: 19px;
    margin: 0px 15px;
    color: white;
    cursor: pointer;
`