import React from 'react'
import "../index.css"
import styled from 'styled-components'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';

export default function Contact() {

    const navigate = useNavigate();
    
    return (
        <Container className='contact-container element' name="contact"  >
            <InnerContainer>
                <h5 className='conatct-left-headline' >Let's build <br />
                    something cool <br />
                    together
                </h5>
                <div className='contact-detail-container' >
                    <ContactEle>
                        <MailIcon />
                        <p>support@ecoventure.in</p>
                    </ContactEle>
                    <ContactEle className='phone' >
                        <LocalPhoneIcon />
                        <p>+91 8709291650
                        </p>
                    </ContactEle>
                    <ContactEle>
                        <LocationOnIcon />
                        <p style={{ fontSize: "13px" }} >Office Address: Second Floor, Sadaqat Ashram, near kurji
                        </p>
                    </ContactEle>
                </div>
                <SocialContainer>
                    <a href="https://www.facebook.com/ecoventure.in" target='_blank' >
                        <FacebookOutlinedIcon sx={{ fontSize: 40 }} className='social-icons' />
                    </a>
                    <a href='https://www.instagram.com/ecoventure.in/' target='_blank' >
                        <InstagramIcon sx={{ fontSize: 40 }} className='social-icons' />
                    </a>



                    <a href="https://twitter.com/ecoventuredecor" target='_blank' >
                        <TwitterIcon sx={{ fontSize: 40 }} className='social-icons' />
                    </a>


                </SocialContainer>

            </InnerContainer>
            <InnerContainer className='right-contact'  >
                {/* <p className='contact-interest'  > I'm  interested in ...</p>
                <div className='service-container' >
                    <Service>
                        Garden Care
                    </Service>
                    <Service>
                        Garden Care
                    </Service>
                    <Service>
                        Garden Care
                    </Service>
                </div> */}


                <form style={{ margin: "20px 0px" }} action="" method="post">


                    <h5 >Book Your<br />
                        Appointment Today <br />
                        and Transform Your Garden !</h5>

                    <Button onClick={()=>navigate("/signup")}>
                        <TelegramIcon style={{ marginRight: "14px" }} sx={{ fontSize: 20 }} />
                        <p>Get Started </p>
                    </Button>


                </form>

                {/* <div class="form-group">
                        <Input placeholder=' Your Name' type="text" id="username" name="username" required />
                    </div>

                    <div class="form-group">
                        <Input placeholder=' Your Email' type="email" id="email" name="email" required />
                    </div>

                    <div class="form-group">
                        <Input type="tel" id="phone" name="phone" placeholder="Your Mobile Number" required />
                    </div>


                    <div class="form-group">
                        <textarea className='desc' placeholder='Description' name="desc" required ></textarea>
                    </div>

                    <div className='file-upload'>
                        <p>Upload images of your space</p>
                        <input type="file" id="image" name="image" accept="image/*" multiple />
                    </div>


                    <Button type="submit">
                        <TelegramIcon style={{ marginRight: "14px" }} sx={{ fontSize: 20 }} />
                        <p>Send Message</p>
                    </Button> */}

            </InnerContainer>

        </Container>
    )
}

const Container = styled.div`
@media only screen and (max-width: 768px) {
padding: 0px;
flex-direction: column;
height: fit-content;
padding-bottom: 40px;

.conatct-left-headline{
    margin-left: 20px;
    font-size: 36px;
}
.right-contact{
    width: 80%;
    padding: 35px;
}

.contact-detail-container{
    margin: 0px;
}
}
`

const InnerContainer = styled.div`
width: 50%;
margin: 60px;
padding: 50px;
border-radius: 25px;

form{
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h5{
        text-align: center;
        font-size: 30px;
        font-family: 'Outfit';
        margin-bottom: 50px;
        color: black;
    line-height: 45px;
    }
}

@media only screen and (max-width: 768px) {
width: 100%;
margin: 0px;
padding: 35px;

form{
    h5{
    text-align: center;
    font-size: 24px;
    font-family: 'Outfit';
    margin-bottom: 12px;
    color: black;
    line-height: 35px;
        }
    }
}



`
const Service = styled.div`
padding: 12px;
border-radius: 10px;
margin: 16px;
margin-left:0px ;
border: 2px solid grey;
`
const Input = styled.input`
padding: 11px;
    border: none;
    margin: 17px 0px;
    width: 100%;
    outline: none;
    border-bottom: 2px solid  #3B726A ;
`


const Button = styled.button`
padding: 15px;
background: #5f3b41;
border: none;
border-radius: 8px;
color: white;
margin: 20px 0px;
font-family: 'Outfit', sans-serif;
display: flex;
align-items: center;
cursor: pointer;
width: 146px;
`

const ContactEle = styled.div`
color: white;
padding: 20px;
font-family: 'Outfit', sans-serif;
margin: 10px 0px;
display: flex;
gap: 20px;
align-items: center;
`
const SocialContainer = styled.div`
align-self: flex-end;
@media only screen and (max-width: 768px) {
    display: none;
}
`