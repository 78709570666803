import {React,useEffect} from 'react'
import "../../index.css"
import styled from 'styled-components'
import PersonIcon from '@mui/icons-material/Person';
import MessageIcon from '@mui/icons-material/Message';
import { Link, useNavigate, useLocation,Outlet } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios"
import { loginFailure,logout } from '../../redux/serviceProviderSlice';
import SPHamburger from '../../components/SPHamburger';

export default function ServiceProviderPanel() {

    const { currentServiceProvider } = useSelector(state => state.serviceProvider);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logOut = async () => {
        const res = await axios.post(`${process.env.REACT_APP_APIKEY}/auth/logout`, {}, { withCredentials: true });
        dispatch(logout());
        navigate("/");
    }

    useEffect(() => {
        const verification = async () => {
          try {
            const res = await axios.get(`${process.env.REACT_APP_APIKEY}/service-provider/verify`, {
              withCredentials: true,
            });
    
            if (!res.data) {
              dispatch(loginFailure());
              navigate("/")
            }
          }
          catch (err) {
            dispatch(loginFailure())
            navigate("/")
          }
        }
        verification();
      }, []);
    
        

    return (
        <Container>
            <Menu>
                <Link  to="/service-provider" style={{textDecoration:"none",color:"white"}} >
                <h4>Ecoventure</h4>
                </Link>
                
                <div id="right-div" >
                    <div id="messageDiv" >
                        <MessageIcon />
                        <span>3</span>
                    </div>

                    <User>
                        <PersonIcon style={{ marginRight: "4px" }} />
                        <p>{currentServiceProvider?.name?.split(" ")[0]}</p>
                    </User>
                </div>

                <SPHamburger/>

            </Menu>
            <InnerContainer>
                <LeftContainer>

                    <ProjectMenu>

                       
                        <Link to="/service-provider" >
                            <Tab style={{ border: location.pathname === "/service-provider" ? "2px solid" : "", background: location.pathname === "/service-provider" ? "white" : "" }} >
                                New Projects
                            </Tab>
                        </Link>

                        <Link to="/service-provider/inprogress" >
                            <Tab style={{ border: location.pathname === "/service-provider/inprogress" ? "2px solid" : "", background: location.pathname === "/service-provider/inprogress" ? "white" : "" }} >
                                In Progess
                            </Tab>
                        </Link>

                        <Link to="/service-provider/completed" >
                            <Tab style={{ border: location.pathname === "/service-provider/completed" ? "2px solid" : "", background: location.pathname === "/service-provider/completed" ? "white" : "" }} >
                                Completed Projects
                            </Tab>
                        </Link>

                        <Link to="/service-provider/account" >
                            <Tab style={{ border: location.pathname === "/service-provider/account" ? "2px solid" : "", background: location.pathname === "/service-provider/account" ? "white" : "" }} >
                                Account
                            </Tab>
                        </Link>

                        <Link to="/service-provider/updateprofile" >
                            <Tab style={{ border: location.pathname === "/service-provider/updateprofile" ? "2px solid" : "", background: location.pathname === "/service-provider/updateprofile" ? "white" : "" }} >
                                Update Profile
                            </Tab>
                        </Link>

                        <Link to={`/service-provider/publicprofile/${currentServiceProvider?._id}`} >
                            <Tab style={{ border:  location.pathname === `/service-provider/publicprofile/${currentServiceProvider?._id}` ? "2px solid" : "", background: location.pathname === `/service-provider/publicprofile/${currentServiceProvider?._id}` ? "white" : "" }} >
                                Public Profile
                            </Tab>
                        </Link>

                        <button  onClick={logOut} id="logoutbtn" >Logout</button>



                    </ProjectMenu>

                </LeftContainer>
                <RightContainer>
                    <Outlet />
                </RightContainer>
            </InnerContainer>

        </Container>
    )
}


const Container = styled.div`
height: 100vh;
`
const Menu = styled.div`
font-family: 'Outfit';
background-color: black;
color: white;
display: flex;
justify-content: space-between;
align-items: center;
padding: 12px 75px;

#right-div{
    display: flex;
    align-items: center;
    gap: 36px;
}


#messageDiv{
   position: relative;
   display: flex;
   align-items: center;
}

h4{
    font-size: 25px;
}

span{
    position: absolute;
    top: -10px;
    right: -11px;
    background: green;
    height: 19px;
    width: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 13px;
}

@media only screen and (max-width: 768px) {
    padding: 18px;

    #messageDiv{
        display: none;
    }
}

`

const User = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 14px;
    border: 2px solid blue;
    border-radius: 13px;

    @media only screen and (max-width: 768px) {
    display: none;
}
`
const ProjectMenu = styled.div`
    height: 100%;
    display: flex;
    background-color: #4e6464;
    padding: 20px;
    flex-direction: column;
    gap: 5px;

    #logoutbtn{
    padding: 12px;
    background: #263548;
    color: white;
    border: none;
    font-family: 'Outfit';
    margin: 35px;
    border-radius: 3px;
    cursor: pointer;
}
`

const Tab = styled.button`
    font-family: 'Outfit';
    padding: 20px;
    width: 200px;
    background-color: #ffc8c8;
    border: none;
    margin: 13px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
`
const InnerContainer = styled.div`
height: calc(100vh - 67px);
display: flex;
`

const LeftContainer = styled.div`
@media only screen and (max-width: 768px) {
   display: none;
}
`

const RightContainer = styled.div`
padding: 30px 60px;
display: flex;
flex-wrap: wrap;
overflow-y: scroll;
align-content: flex-start;
width: 100%;
column-gap: 24px;
justify-content: flex-start;
row-gap: 20px;

@media only screen and (max-width: 768px) {
   padding: 20px 15px;
}
`

