import { React, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { Outlet, Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import SendIcon from '@mui/icons-material/Send';
import { fetchProject, projectFetchFailure } from '../../redux/projectSlice'
import axios from "axios"
import { loginFailure } from '../../redux/serviceProviderSlice'
import TaskAltIcon from '@mui/icons-material/TaskAlt';


export default function ServiceProviderProject() {


    const { currentAdmin } = useSelector(state => state.admin);
    const { allocatedQuotation, currentProject } = useSelector(state => state.project);

    useEffect(() => {
        const verification = async () => {
          try {
            const res = await axios.get(`${process.env.REACT_APP_APIKEY}/service-provider/verify`, {
              withCredentials: true,
            });
    
            if (!res.data) {
              dispatch(loginFailure());
              navigate("/")
            }
          }
          catch (err) {
            dispatch(loginFailure())
            navigate("/")
          }
        }
        verification();
      }, []);
    

    function allObjectsFinalized(allocatedQuotation) {
        if (allocatedQuotation.length === 0) return false;
        for (const obj of allocatedQuotation) {
            if (!obj.finalized) {
                return false;
            }
        }
        return true;
    }

    const sendToClient = async (e) => {
        try {
            const res = await axios.put(`${process.env.REACT_APP_APIKEY}/admin/project/client/${currentProject._id}/${currentAdmin._id}`, {}, { withCredentials: true, });
            console.log(res.data)
            alert("Quotations sent to client Successfully")
            navigate(`/admin/project`);
        } catch (error) {
            dispatch(loginFailure());
            navigate("/")
            console.log(error)
        }
    }

    const updateStatus = async (e) => {
        try {
            const res = await axios.put(`${process.env.REACT_APP_APIKEY}/admin/project/completed/${currentProject._id}/${currentAdmin._id}`, {}, { withCredentials: true, });
            console.log(res.data)
            alert("your project is completed")
            navigate(`/admin`);
        } catch (error) {
            dispatch(loginFailure());
            navigate("/")
            console.log(error)
        }
    }



    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    

    return (
        <Container>
            <Menu>
                <Link to="/service-provider/project" style={{ textDecoration: "none" }} >
                    <div style={{ backgroundColor: location.pathname === `/service-provider/project` ? "#eddeff" : "" }} >Project Info</div>
                </Link>

                <Link to="/service-provider/project/quotation" style={{ textDecoration: "none" }}  >
                    <div style={{ backgroundColor: location.pathname === "/service-provider/project/quotation" ? "#eddeff" : "" }} >Quotation</div>
                </Link>

                <Link to="/service-provider/project/payments" style={{ textDecoration: "none" }}  >
                    <div style={{ backgroundColor: location.pathname === "/service-provider/project/payments" ? "#eddeff" : "" }} >Payments</div>
                </Link>

            </Menu>
            <InnerContainer>
                <Outlet />
            </InnerContainer>

        </Container>
    )
}


const Menu = styled.div`
background-color: #d4d1cd;
padding: 13px 40px;
display: flex;
gap: 45px;
align-items: center;
border-radius: 5px;
font-weight: bold;
margin: 8px 0px;


div{
    padding: 7px 14px;
    border: 2px solid grey;
    border-radius: 2px;
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: black;
    cursor: pointer;
}

button{
    display: flex;
    padding: 7px 15px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background: blueviolet;
    color: white;
    border: none;
    border-radius: 2px;
    margin-left: 30px;
    cursor: pointer;
}
#sendIcon{
    font-size: 18px;
}

#markAsComplete{
    background-color: green;
    margin: 0px;
    font-size: 12px;
    padding: 7px 6px;
    gap:7px;
    border-radius: 4px;
}


@media only screen and (max-width: 768px) {
    gap: 4px;
    padding: 12px 16px;
    justify-content: space-around;
}
`

const InnerContainer = styled.div`
`

const Container = styled.div`
padding: 20px;
display: flex;
font-family: 'Inter', sans-serif;
flex-direction: column;
padding-top: 0px;
width: 100%;

@media only screen and (max-width: 768px) {
    padding: 0px;
}
`
