import { React, useEffect, useState } from 'react';
import styled from "styled-components";
import "../../index.css"
import AddIcon from '@mui/icons-material/Add';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { loginFailure, loginSuccess } from '../../redux/supplierSlice';

export default function MaterialCosting() {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { currentSupplier } = useSelector(state => state.supplier);


    const [rows, setRows] = useState([
        {
            materialName: ``,
            materialPrice: ``
        },
    ]);

    const addRow = () => {
        setRows((prevRows) => [
            ...prevRows,
            {
                materialName: ``,
                materialPrice: ``
            },
        ]);
    };

    const handleInputChange = (index, field, value) => {
        setRows((prevRows) => {
            const newRows = [...prevRows];
            newRows[index][field] = value;
            return newRows;
        });
    };

    useEffect(() => {
        const verification = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_APIKEY}/supplier/verify`, {
                    withCredentials: true,
                });

                if (!res.data) {
                    dispatch(loginFailure());
                    navigate("/")
                }
            }
            catch (err) {
                dispatch(loginFailure())
                navigate("/")
            }
        }
        verification();
    }, []);


    function isDetailsComplete(data) {
        if (data.length === 0) return false;
        for (const row of data) {
            if (row.materialName === '' || row.materialPrice === '') {
                return false;
            }
        }
        return true;
    }




    const createProductList = async (e) => {
        e.preventDefault();

        const updatationDetails = { materialInfo: rows };

        if (!isDetailsComplete(rows)) {
            alert("Please fill all the fields !")
            return
        }
        try {
            const res = await axios.put(`${process.env.REACT_APP_APIKEY}/supplier/${currentSupplier._id}`, { updatationDetails }, { withCredentials: true, });
            dispatch(loginSuccess(res.data));
            alert("Updated Successfully")
            navigate(`/supplier/account`);
        } catch (error) {
            dispatch(loginFailure());
            navigate("/")
        }
    }


    return (

        <>



            <RightContainer>
                <h4>Material Costing</h4>

                <table>
                    <thead>
                        <tr>
                            <th>Product Name</th>
                            <th> Unit Price of Product   </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentSupplier?.materialInfo.length === 0 ? (

                                rows.map((row, index) => (
                                    <tr key={index}>

                                        <td>
                                            <input className='service-input' type='text' value={row.materialName}
                                                onChange={(e) => handleInputChange(index, 'materialName', e.target.value)}
                                            />
                                        </td>

                                        <td>
                                            <input className='service-input' type='number' value={row.materialPrice}
                                                onChange={(e) => handleInputChange(index, 'materialPrice', e.target.value)}
                                            />
                                        </td>

                                    </tr>
                                ))

                            ) : (

                                currentSupplier?.materialInfo.map((row, index) => (
                                    <tr key={index}>

                                        <td>
                                            <input disabled className='service-input' type='text' value={row.materialName}
                                                onChange={(e) => handleInputChange(index, 'materialName', e.target.value)}
                                            />
                                        </td>

                                        <td>
                                            <input disabled className='service-input' type='number' value={row.materialPrice}
                                                onChange={(e) => handleInputChange(index, 'materialPrice', e.target.value)}
                                            />
                                        </td>

                                    </tr>
                                ))

                            )

                        }

                    </tbody>
                </table>

                {(currentSupplier?.materialInfo.length === 0) && <ButtonContainer>
                    <button id="add" onClick={addRow} ><AddIcon /></button>

                    <CreateQuotation onClick={createProductList} id="create" >Submit</CreateQuotation>

                </ButtonContainer>}

            </RightContainer>
        </>
    )
}


const Container = styled.div`
display: flex;
height: 91vh;
font-family: 'Outfit';



@media only screen and (max-width: 768px) {
height: fit-content;
}
`
const LeftContainer = styled.div`
width: 20%;
display: flex;
flex-direction: column;
padding: 30px 40px;
background-color: #d4d1cd;
@media only screen and (max-width: 768px) {
display: none;
}
`
const RightContainer = styled.div`
display: flex;
flex-direction: column;
padding: 0px 50px;
font-family: 'Outfit';

h4{
    font-size: 26px;
    margin-bottom: 35px;
}

#mob-details{
    display: none;
}


table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
    }

    tr td:nth-child(1){
        min-width: 500px;
    }

    th, td {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 15px;
    }

    th {
      background-color: #f2f2f2;
    }

    thead > tr>th:nth-child(1) {
        width: 40%;
    }
    thead{
      font-size: 15px;
    }
    thead tr th{
      padding: 14px;
    }

    #add{
        border-radius: 50%;
        margin: 12px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #5a3b3b;
        color: white;
        border: none;
        cursor: pointer;
    }


@media only screen and (max-width: 768px) {
    width: 100%;
    padding: 15px 12px;

    #pc-details{
        display: none;
    }

    #mob-details{
        display: flex;
        padding: 25px 0px;
        >div{
            width: 100%;
            gap:12px;
        }
        input{
            width: 100%;
        }
        label{
            padding-left: 0px;
        }
    }

    table{
        margin-top: 0px;
    }
    tr td:nth-child(1){
        min-width: unset;
    }
    thead > tr>th:nth-child(1) {
        width: unset;
    }
    th, td {
      padding: 5px;
    }

}

`
const ImageContainer = styled.div`
height: 125px;
width: 125px;
overflow: hidden;
border-radius: 50%;
@media only screen and (max-width: 768px) {
    height: 100px;
    width: 100px;
}
`
const Top = styled.div`
width: 100%;
display: flex;
margin: 20px 0px;
`
const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 55px;
    row-gap: 10px;
    color: mediumvioletred;
    margin-top: -10px;

    @media only screen and (max-width: 768px) {
        padding: 0px;
        margin-left: 20px;
        .username{
            font-size: 25px;
        }
    }
`

const Details = styled.div`
display: flex;
gap: 50px;
padding: 25px 0px;

input{
    padding: 10px;
    width: 480px;
    border-radius: 8px;
    border: 1px solid lightblue;
}

label{
    padding: 10px;
}

div{
    display: flex;
    flex-direction: column;
    gap:35px
}

#services-div{
    padding: 10px 0px;
    gap: 10px;
    display: flex;
    /* flex-direction: row;
    flex-wrap: wrap; */
}

`

const Tab = styled.div`
padding: 13px;
border: 2px solid grey;
margin: 20px 0px;
display: flex;
align-items: center;
gap:20px;
cursor: pointer;
border-radius: 5px;
`

const ButtonContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin: 20px;
`

const CreateQuotation = styled.button`
    padding: 8px 25px;
    background: blueviolet;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
        padding: 11px 35px;
    }
 `