import { configureStore ,combineReducers} from '@reduxjs/toolkit';
import userReducer from './userSlice';
import projectReducer from "./projectSlice"
import storage from 'redux-persist/lib/storage';
import adminReducer from "./adminSlice"
import serviceProviderReducer from "./serviceProviderSlice"
import supplierReducer from "./supplierSlice"

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import supplierSlice from './supplierSlice';

const rootReducer = combineReducers({user:userReducer,project:projectReducer,admin:adminReducer,serviceProvider:serviceProviderReducer,supplier:supplierSlice});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const  persistor = persistStore(store);

