import React, { useState,useEffect } from 'react';
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { useSelector ,useDispatch } from 'react-redux';
import axios from 'axios';
import { loginFailure, loginSuccess } from '../../redux/serviceProviderSlice';


export default function UpdateServiceProvider() {

    const navigate = useNavigate();
    const { currentServiceProvider } = useSelector(state => state.serviceProvider);
    const dispatch = useDispatch();

    const [updatationDetails, setUpdatationDetails] = useState({ name: currentServiceProvider?.name, phone: currentServiceProvider?.phone, city: currentServiceProvider?.city, address: currentServiceProvider?.address, postalCode: currentServiceProvider?.postalCode, servicesOffered: [] })

    useEffect(() => {
        const verification = async () => {
          try {
            const res = await axios.get(`${process.env.REACT_APP_APIKEY}/service-provider/verify`, {
              withCredentials: true,
            });
    
            if (!res.data) {
              dispatch(loginFailure());
              navigate("/")
            }
          }
          catch (err) {
            dispatch(loginFailure())
            navigate("/")
          }
        }
        verification();
      }, []);
    
        

    const updateUser = async (e) => {
        e.preventDefault();

        if (updatationDetails.name === "" || updatationDetails.phone === "" || updatationDetails.city === "" || updatationDetails.address === "" || updatationDetails.postalCode === "") {
            alert("All fields are required")
        } else {
            try {
                const res = await axios.put(`${process.env.REACT_APP_APIKEY}/service-provider/${currentServiceProvider._id}`, { updatationDetails }, { withCredentials: true, });
                dispatch(loginSuccess(res.data));
                console.log(res.data)
                alert("Profile Updated Successfully")
                navigate(`/service-provider`);
            } catch (error) {
                dispatch(loginFailure());
                navigate("/")
            }
        }
    }


    const handleCheckbox = (e) => {
        console.log(e.target)

        setUpdatationDetails(prevDetails => {
            if (e.target.checked) {
                return {
                    ...prevDetails,
                    servicesOffered: [...prevDetails.servicesOffered, e.target.value]
                };
            } else {
                return {
                    ...prevDetails,
                    servicesOffered: prevDetails.servicesOffered.filter(item => item !== e.target.value)
                };
            }
        });
    };



    return (
        <Container>
            <Form>
                <p>Fill the  details to continue</p>
                <input value={updatationDetails.name}  onChange={(e) => setUpdatationDetails(prevDetails => ({ ...prevDetails, name: e.target.value }))} type='text' placeholder='Username' required></input>
                <input value={updatationDetails.phone} onChange={(e) => setUpdatationDetails(prevDetails => ({ ...prevDetails, phone: e.target.value }))} type='number' maxLength="10" placeholder='Phone' required></input>
                <input value={updatationDetails.city} onChange={(e) => setUpdatationDetails(prevDetails => ({ ...prevDetails, city: e.target.value }))} type='text' placeholder='City' required></input>
                <input value={updatationDetails.address}  onChange={(e) => setUpdatationDetails(prevDetails => ({ ...prevDetails, address: e.target.value }))} type='text' placeholder='Address' required></input>
                <input value={updatationDetails.postalCode} onChange={(e) => setUpdatationDetails(prevDetails => ({ ...prevDetails, postalCode: e.target.value }))} type='number' placeholder='Postal code' required></input>

                <label style={{ margin: "15px 0px" }} >Select a services you offer</label>

                <div id="services-container">
                    <span>
                        <input
                            type="checkbox"
                            id="landscaping-only-plants"
                            name="services"
                            value="Landscaping (Only plant related services)"
                            onClick={handleCheckbox}
                        />
                        <label htmlFor="landscaping-only-plants">Landscaping (Only plant related services)</label>
                    </span>

                    <span>
                        <input
                            type="checkbox"
                            id="landscaping-civil-services"
                            name="services"
                            value="Landscaping (Plant and civil services including swimming pool, fountain, etc.)"
                            onClick={handleCheckbox}
                        />
                        <label htmlFor="landscaping-civil-services">Landscaping (Plant and civil services including swimming pool, fountain, etc.)</label>
                    </span>

                    <span>
                        <input
                            type="checkbox"
                            id="vertical-gardening"
                            name="services"
                            value="Vertical Gardening"
                            onClick={handleCheckbox}
                        />
                        <label htmlFor="vertical-gardening">Vertical Gardening</label>
                    </span>

                    <span>
                        <input
                            type="checkbox"
                            id="roof-top-gardening"
                            name="services"
                            value="Roof Top Gardening"
                            onClick={handleCheckbox}
                        />
                        <label htmlFor="roof-top-gardening">Roof Top Gardening</label>
                    </span>

                    <span>
                        <input
                            type="checkbox"
                            id="kitchen-gardening"
                            name="services"
                            value="Kitchen Gardening"
                            onClick={handleCheckbox}
                        />
                        <label htmlFor="kitchen-gardening">Kitchen Gardening</label>
                    </span>
                </div>

                <input value="Continue" type='button' onClick={updateUser} />
            </Form>
        </Container>
    )
}

const Container = styled.div`
position: absolute;
top: 0;
left: 0;
height: 100vh;
width: 100%;
background-color: antiquewhite;
display: flex;
justify-content: center;
align-items: center;
font-family: 'Outfit';
`
const Form = styled.form`
    display: flex;
    flex-direction: column;
    /* width: 420px; */
    border: 2px solid ;
    padding: 40px 30px;
    border-radius: 10px;
p{
    text-align: center;
    margin: 10px 0px;
    font-size: 18px;
    margin-bottom: 30px;
}

input{
    padding: 9px 15px;
    border-radius: 5px;
    border: none;
    margin: 8px 0px;
}

input[type="button"]{
    border: none;
    background-color: blueviolet;
    padding: 12px;
    color: white;
    border-radius: 8px;
    margin: 20px 0px;
    margin-top: 20px;
    cursor: pointer;
}

#services-container{
    font-size: 14px;
    display: flex;
    flex-direction: column;
    span{
    border-radius: 5px;
    display: flex;
    padding: 0px 4px;
    gap: 20px;
    align-items: center;
    }
}
`
