import { React, useState, useEffect } from 'react'
import styled from 'styled-components'
import "../../index.css"
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios"
import { useNavigate } from 'react-router-dom';
import { loginFailure } from '../../redux/adminSlice';
import { projectFetchFailure } from '../../redux/projectSlice';


export default function AdminQuotation() {

  const { currentAdmin } = useSelector(state => state.admin);
  const { currentProject } = useSelector(state => state.project);
  const [quotation, setQuotation] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [rows, setRows] = useState([
    {
      serviceDesc: ``,
      quantity: ``,
      lowerRange: ``,
      upperRange: ``,
    },
  ]);

  const addRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      {
        serviceDesc: '',
        quantity: "",
        lowerRange: "",
        upperRange: "",
      },
    ]);
  };

  const handleInputChange = (index, field, value) => {
    setRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[index][field] = value;
      return newRows;
    });
  };


  function convertTableToJson() {
    const tableBody = document.getElementById("table-body");
    const rows = tableBody.getElementsByTagName("tr");

    const jsonData = [];

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const columns = row.getElementsByTagName("td");

      const rowData = {
        serviceDesc: columns[0].querySelector('textarea').value,
        quantity: columns[1].querySelector('input').value,
        lowerRange: columns[2].querySelector('.price-range-input-container input:nth-child(1)').value,
        upperRange: columns[2].querySelector('.price-range-input-container input:nth-child(3)').value,
        serviceProviderCosting: columns[3].querySelector('input').value,
        amount: columns[4].querySelector('input').value,
      };
      jsonData.push(rowData);
    }
    return jsonData;
  }

  
  const createQuoatation = async (e) => {

    e.preventDefault()

    // let input = prompt("Type Confirm to create project");

    let isExecuted = window.confirm("Are you sure to create the quotation ?");

    const newQuoatation = { info: rows, projectId: currentProject._id };

    if (isExecuted) {
      try {
        const res = await axios.post(`${process.env.REACT_APP_APIKEY}/admin/project/quotation/${currentAdmin?._id}`, newQuoatation, {
          withCredentials: true,
          // headers: {
          //   "content-type": "application/json"
          // }
        });
        alert("Quotation Created Successfully ");
        navigate("/admin/project")
      } catch (error) {
        dispatch(loginFailure());
        dispatch(projectFetchFailure());
        navigate("/");
      }
    }
  }



  useEffect(() => {
    const verification = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_APIKEY}/admin/verify`, {
          withCredentials: true,
        });

        if (!res.data) {
          dispatch(loginFailure())
          navigate("/")
        }

      }
      catch (err) {
        dispatch(loginFailure())
        navigate("/")
      }
    }
    verification();
  }, []);



  useEffect(() => {

    const fetchQuotation = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_APIKEY}/admin/project/quotation/${currentProject?._id}/${currentAdmin?._id}`, {
          withCredentials: true,
        });

        setQuotation(res.data)
      } catch (err) {
        dispatch(loginFailure())
        dispatch(projectFetchFailure());
        navigate("/");
      }
    }

    fetchQuotation();
  }, []);



  return (
    <Container>
      {quotation.length === 0 ?
        (
          <>
            <table>
              <thead>
                <tr>
                  <th>Description of Service</th>
                  <th>Quantity</th>
                  <th>Price Range</th>
                  <th>S.P Costing</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <textarea
                        className='service-desc'
                        value={row.serviceDesc}
                        onChange={(e) =>
                          handleInputChange(index, 'serviceDesc', e.target.value)
                        }
                      ></textarea>
                    </td>
                    <td>
                      <input
                        className='service-input'
                        type='number'
                        value={row.quantity}
                        onChange={(e) =>
                          handleInputChange(index, 'quantity', e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <div className='price-range-input-container'>
                        <input
                          className='service-input'
                          type='number'
                          value={row.priceRangeStart}
                          onChange={(e) =>
                            handleInputChange(index, 'lowerRange', e.target.value)
                          }
                        />
                        <p>-</p>
                        <input
                          className='service-input'
                          type='number'
                          value={row.priceRangeEnd}
                          onChange={(e) =>
                            handleInputChange(index, 'upperRange', e.target.value)
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <input
                        className='service-input'
                        disabled
                        type='number'

                      />
                    </td>
                    <td>
                      <input
                        className='service-input'
                        disabled
                        type='number'

                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ButtonContainer>
              <button id="add" onClick={addRow} ><AddIcon /></button>

              <CreateQuotation onClick={createQuoatation} id="create" >Create Quotation</CreateQuotation>

            </ButtonContainer>
          </>
        )


        : (
          <table>
            <thead>
              <tr>
                <th>Description of Service</th>
                <th>Quantity</th>
                <th>Price Range</th>
                <th>S.P Costing</th>
                <th>Amount</th>

              </tr>
            </thead>
            <tbody id="table-body">

              {quotation && quotation[0].info.map((row) => {

                return <tr key={row._id} >
                  <td  ><textarea disabled className='service-desc' >{row.serviceDesc}</textarea></td>
                  <td><input disabled className='service-input' type="number" value={row.quantity} /></td>
                  <td><div className='price-range-input-container'><input disabled className='service-input' type="number" value={row.lowerRange} /> <p>-</p> <input disabled className='service-input' type="number" value={row.upperRange} /></div></td>
                  <td><input className='service-input' disabled type="number" /></td>
                  <td><input className='service-input' disabled type="number" /></td>
                </tr>
              })
              }


            </tbody>
          </table>
        )
      }




    </Container>
  )
}

const Container = styled.div`
width: 100%;

table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
    }

    th, td {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    th {
      background-color: #f2f2f2;
    }

    thead > tr>th:nth-child(1) {
        width: 40%;
    }
    thead{
      font-size: 15px;
    }
    thead tr th{
      padding: 14px;
    }

 #add{

    border-radius: 50%;
    margin: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #5a3b3b;
    color: white;
    border: none;
    padding: 1px;
    cursor: pointer;
}

`
const CreateQuotation = styled.button`
    padding: 9px;
    background: blueviolet;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
 `

const ButtonContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin: 20px;
`



