import './App.css';
import Home from './components/Home';
import About from "./components/About"
import Navbar from './components/Navbar';
import Services from './components/Services';
import Gallary from './components/Gallary';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Admin from './pages/admin/Admin';
import SeriveProviderPhoneLogin from "./components/SeriveProviderPhoneLogin";
import SupplierPhoneLogin from './components/SupplierPhoneLogin';
import ForgotPassword from './components/ForgotPassword';
import UpdatePassword from './components/UpdatePassword';

import { Link as ScrollLink, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

import {
  BrowserRouter,
  Link,
  Route,
  Routes,
} from "react-router-dom";
import { useEffect } from 'react';

import SignIn from './pages/user/SignIn';
import SignUp from './pages/user/SignUp';
import UserInfo from './pages/user/UserInfo';
import UpdateUser from './pages/user/UpdateUser';
import UserPopup from "./pages/user/UserPopup"
import UserProject from "./pages/user/UserProject"
import UserPayment from './pages/user/UserPayment';
import UserSupport from './pages/user/UserSupport';
import CreateProject from './pages/user/CreateProject';
import UserProjectInfo from './pages/user/UserProjectInfo';



import AdminLogin from './pages/admin/AdminLogin';
import AdminProject from './pages/admin/AdminProject';
import AllProjects from './pages/admin/AdminAllProjects';
import AdminQuotation from './pages/admin/AdminQuotation';
import AdminProjectInfo from './pages/admin/AdminProjectInfo';
import AdminShareQuotation from './pages/admin/AdminShareQuotation';
import AdminQuotationInfo from './pages/admin/AdminQuotationInfo';



import ServiceProviderPanel from './pages/serviceProvider/ServiceProviderPanel';
import ServiceProviderProject from './pages/serviceProvider/ServiceProviderProject';
import ServiceProviderAllProjects from './pages/serviceProvider/ServiceProviderAllProjects';
import ServiceProviderQuotation from './pages/serviceProvider/ServiceProviderQuotation';
import ServiceProviderSignUp from './pages/serviceProvider/ServiceProviderSignUp';
import ServiceProviderSignIn from './pages/serviceProvider/ServiceProviderSignIn';
import UpdateServiceProvider from './pages/serviceProvider/UpdateServiceProvider';
import ServiceProviderInfo from './pages/serviceProvider/ServiceProviderInfo';
import ServiceProviderProjectInfo from './pages/serviceProvider/ServiceProviderProjectInfo';
import ServiceProviderPayments from './pages/serviceProvider/ServiceProviderPayments';
import ServiceproviderInAppQuotation from './pages/serviceProvider/ServiceproviderInAppQuotation';
import UpdateSP from './pages/serviceProvider/UpdateSP';
import ServiceProviderPublicProfile from './pages/serviceProvider/ServiceProviderPublicProfile';

import SupplierSignIn from './pages/supplier/SupplierSignin';
import SupplierSignUp from './pages/supplier/SupplierSignUp';
import SupplierPanel from './pages/supplier/SupplierPanel';
import UpdateSupplier from './pages/supplier/UpdateSupplier';
import SupplierInfo from './pages/supplier/SupplierInfo';
import MaterialCosting from './pages/supplier/MaterialCosting';
import UpdateCosting from './pages/supplier/UpdateCosting';



function App() {

  useEffect(() => {
    localStorage.clear();
    const timeoutId = setTimeout(() => {
      localStorage.clear();
    }, 1 * 60 * 60 * 1000); // 24 hours in milliseconds

    return () => clearTimeout(timeoutId);
  }, []);


  useEffect(() => {

    Events.scrollEvent.register('begin', (to, element) => {
      console.log('begin', to, element);
    });

    Events.scrollEvent.register('end', (to, element) => {
      console.log('end', to, element);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);



  return (
    <>

      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={
            <>
              <Navbar />
              <Home />
              <About />
              <Services />
              <Gallary />
              <Contact />
              <Footer />
            </>} />

       
          <Route path="signup" element={<SignUp />} />
          <Route path="login" element={<SignIn />} />
          <Route path="user/resetpass" element={<ForgotPassword entity={"user"} />} />
          <Route path="user/updatepass" element={<UpdatePassword entity={"user"} />} />

          <Route path="user" element={<UserInfo />} />
          <Route path="user/profile" element={<UpdateUser />} />
          <Route path="user/details" element={<UserPopup />} />
          <Route path="user/project/new" element={<CreateProject />} />
          <Route path="user/project" element={<UserProject />} />
          <Route path="user/payment" element={<UserPayment />} />
          <Route path="user/support" element={<UserSupport />} />
          <Route path="user/project/:id" element={<UserProjectInfo />} />



          <Route path="login/admin" element={<AdminLogin />} />

          <Route path="admin" exact element={<Admin />} >
            <Route path="" element={<AllProjects type={"new"} />} />
            <Route path="inprogress" element={<AllProjects type={"inprogress"} />} />
            <Route path="completed" element={<AllProjects type={"completed"} />} />
            <Route path="project" element={<AdminProject />} >
              <Route path="" element={<AdminProjectInfo />} />
              <Route path="quotation" element={<AdminQuotation />} />
              <Route path="share" element={<AdminShareQuotation />} />
              <Route path="quotation/first" element={<AdminQuotationInfo index={0} />} />
              <Route path="quotation/second" element={<AdminQuotationInfo index={1} />} />
              <Route path="quotation/third" element={<AdminQuotationInfo index={2} />} />
            </Route>
          </Route>



          <Route path="login/service-provider" element={<ServiceProviderSignIn />} />
          <Route path="signup/service-provider" element={<ServiceProviderSignUp />} />
          <Route path="login/service-provider/phone" element={<SeriveProviderPhoneLogin />} />
          <Route path="service-provider/resetpass" element={<ForgotPassword entity={"service-provider"} />} />
          <Route path="service-provider/updatepass" element={<UpdatePassword entity={"service-provider"} />} />


          <Route path="service-provider" exact element={<ServiceProviderPanel />} >
            <Route path="" element={<ServiceProviderAllProjects type={"new"} />} />
            <Route path="inprogress" element={<ServiceProviderAllProjects type={"inprogress"} />} />
            <Route path="completed" element={<ServiceProviderAllProjects type={"completed"} />} />
            <Route path="details" element={<UpdateServiceProvider />} />
            <Route path="publicprofile/:id" element={<ServiceProviderPublicProfile />} />
            <Route path="project" element={<ServiceProviderProject />} >
              <Route path="" element={<ServiceProviderProjectInfo />} />
              <Route path="payments" element={<ServiceProviderPayments />} />
              <Route path="quotation" element={<ServiceproviderInAppQuotation />} />
            </Route>
            <Route path="account" element={<ServiceProviderInfo />} />
            <Route path="update" element={<UpdateServiceProvider />} />
            <Route path="updateprofile" element={<UpdateSP />} />
          </Route>

          <Route path="service-provider/quotation/:id" exact element={<ServiceProviderQuotation />} />
          <Route path="service-provider/:id" element={<ServiceProviderPublicProfile/>} />


          <Route path="login/supplier" element={<SupplierSignIn />} />
          <Route path="signup/supplier" element={<SupplierSignUp />} />
          <Route path="login/supplier/phone" element={<SupplierPhoneLogin />} />
          <Route path="supplier/resetpass" element={<ForgotPassword entity={"supplier"} />} />
          <Route path="supplier/updatepass" element={<UpdatePassword entity={"supplier"} />} />



          <Route path="supplier" exact element={<SupplierPanel />} >
            <Route path="account" element={<SupplierInfo />} />
            <Route path="update" element={<UpdateSupplier />} />
            <Route path="costing" element={<MaterialCosting />} />
            <Route path="updatecosting" element={<UpdateCosting />} />
          </Route>

        </Routes>
      </BrowserRouter >
    </>
  );
}

export default App;


