import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import "../../index.css"
import AddCardIcon from '@mui/icons-material/AddCard';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Navbar from "../../components/Navbar"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { fetchAllProjects } from "../../redux/projectSlice";
import UserProjectCard from '../../components/UserProjectCard';
import AddIcon from '@mui/icons-material/Add';
import { loginFailure } from '../../redux/userSlice';


export default function UserProject() {


    const { currentUser } = useSelector(state => state.user);
    const { allProjects } = useSelector(state => state.project);

    console.log(allProjects)

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [userDetails, setUserDetails] = useState({ name: currentUser?.name, phone: currentUser?.phone, city: currentUser?.city, address: currentUser?.address, postalCode: currentUser?.postalCode })

    const [project, setProject] = useState({ service: "landscaping", area: "", budget: "", desc: "", photos: [] })


    const handleFileChange = async (event) => {
        const selectedPhotos = Array.from(event.target.files);
        const images = await convertImagesToBase64(selectedPhotos);

        setProject((prevProject) => ({
            ...prevProject,
            photos: [...prevProject.photos, ...images],
        }));
    };


    const convertImagesToBase64 = async (imageFiles) => {
        const base64Promises = imageFiles.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = () => {
                    resolve(reader.result);
                };

                reader.onerror = (error) => {
                    reject(error);
                };

                // Read the image file as a data URL
                reader.readAsDataURL(file);
            });
        });
        try {
            const base64Strings = await Promise.all(base64Promises);
            return base64Strings;
        } catch (error) {
            throw error;
        }
    }


    const createProject = async (e) => {
        e.preventDefault()

        try {
            const res = await axios.post(`${process.env.REACT_APP_APIKEY}/user/project/${currentUser?._id}`, project, {
                withCredentials: true,
                headers: {
                    "content-type": "application/json"
                }
            });
            console.log(res.data);

        } catch (error) {
            console.log(error.data);
        }
    };


    useEffect(() => {
        const verification = async () => {
          try {
            const res = await axios.get(`${process.env.REACT_APP_APIKEY}/user/verify`, {
              withCredentials: true,
            });
    
            if (!res.data) {
              dispatch(loginFailure())
              navigate("/")
            }
          }
          catch (err) {
            dispatch(loginFailure());
            navigate("/");
          }
        }
        verification();
      }, []);



    useEffect(() => {
        const fetchProjects = async () => {
            const res = await axios.get(`${process.env.REACT_APP_APIKEY}/user/allprojects/${currentUser?._id}`, project, {
                withCredentials: true,
                headers: {
                    "content-type": "application/json"
                }
            });
            dispatch(fetchAllProjects(res.data))
        }

        fetchProjects();

    }, []);


    return (<>
        <Navbar />

        <Container>

            <LeftContainer id="left-container" >
                <Link to="/user" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user" ? "#e4f0f8" : "" }} >
                        <PersonIcon /> <p>  Account</p>
                    </Tab>
                </Link>
                <Link to="/user/profile" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/profile" ? "#e4f0f8" : "" }} >
                        <ManageAccountsIcon />  <p> Update Profile</p>
                    </Tab>
                </Link>
                <Link to="/user/project" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/project" ? "#e4f0f8" : "" }} >
                        <AssignmentIcon /> <p>Project Details</p>
                    </Tab>
                </Link>
                <Link to="/user/payment" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/payment" ? "#e4f0f8" : "" }} >
                        <AddCardIcon />     <p> Payment Options</p>
                    </Tab>
                </Link>
                <Link to="/user/support" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/support" ? "#e4f0f8" : "" }} >
                        <LocalPhoneIcon />   <p>Support </p>
                    </Tab>
                </Link>

            </LeftContainer>
            <RightContainer>
                <div className='activate-div' >
                    <h4>Your Projects</h4>
                    <Link style={{textDecoration:"none"}} to="/user/project/new">
                    <button  className='activate' > New Project<AddIcon style={{fontSize:"20px"}} /></button>

                    </Link>
                </div>



                <Projects>
                    {allProjects && allProjects.map((project) => {
                        return <UserProjectCard key={project._id} project={project} />
                    })}
                </Projects>

              

            </RightContainer>

        </Container >
    </>
    )
}



const Container = styled.div`
display: flex;
height: 91vh;
font-family: 'Outfit';
@media only screen and (max-width: 768px) {
height: fit-content;
#left-container{
    display: none;
}
  
}
`
const LeftContainer = styled.div`
width: 20%;
display: flex;
flex-direction: column;
padding: 30px 40px;
background-color: #d4d1cd;

@media only screen and (max-width: 768px) {
    display: none;
}
`
const RightContainer = styled.div`
width: 80%;
display: flex;
flex-direction: column;
padding: 55px 120px;
overflow-y: scroll;

h4{
    font-size: 26px;
    margin-bottom: 35px;
}


.activate{
   
    padding: 7px 10px;
    background-color: blueviolet;
    border: none;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
} 

.activate-div{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

@media only screen and (max-width: 768px) {
    width: 100%;
    padding: 25px;

    .activate-div{
        align-items: center;
    }
    h4{
        margin-bottom:0px;
    }
 
}

`
const ImageContainer = styled.div`
height: 200px;
width: 200px;
overflow: hidden;
border-radius: 50%;

image{
    width: 100%;
    height: 100%;

}
`
const Top = styled.div`
width: 100%;
display: flex;
`
const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    row-gap: 10px;
    color: mediumvioletred;
`

const Details = styled.div`
display: flex;
gap: 50px;
padding: 25px 0px;

select,input{
    padding: 10px;
    width: 400px;
    border-radius: 8px;
    border: 1px solid lightblue;
}

label{
    padding: 10px;
}

div{
    display: flex;
    flex-direction: column;
    gap:30px
}

textarea{
    width: 405px;
    height: 95px;
    resize: none;
    padding: 10px;
}

`

const Tab = styled.div`
padding: 13px;
border: 2px solid grey;
margin: 20px 0px;
display: flex;
align-items: center;
gap:20px;
cursor: pointer;
border-radius: 5px;
`

const Projects = styled.div`
    display: flex;
    gap: 35px;
    flex-wrap: wrap;

    @media only screen and (max-width: 768px) {
        padding: 20px 0px;
        gap:25px;
    }
`
const CreateProject = styled.div`
   display: flex;
    /* flex-direction: column; */
    height: 100%;
    /* border:2px solid; */
    padding: 5px 60px;
    border-radius: 5px;
`
