import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import "../../index.css"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { loginFailure } from '../../redux/serviceProviderSlice';
import Loader from '../../components/Loader';
import { projectFetchFailure } from '../../redux/projectSlice';


export default function ServiceproviderInAppQuotation() {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { currentServiceProvider } = useSelector(state => state.serviceProvider);
    const { currentProject } = useSelector(state => state.project);


    const [initialQuotation, setInitialQuotation] = useState(null)
    const [quotation, setQuotation] = useState(null);
    const [active, setActive] = useState(false);
    const [msg, setMsg] = useState(null);


    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        info: [], // This will hold information for each row in the table
    });



    useEffect(() => {
        const verification = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_APIKEY}/service-provider/verify`, {
                    withCredentials: true,
                });

                if (!res.data) {
                    dispatch(loginFailure());
                    navigate("/")
                }
            }
            catch (err) {
                dispatch(loginFailure())
                navigate("/")
            }
        }
        verification();
    }, []);




    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleTableInputChange = (field, value, index, row) => {
        setFormData((prevData) => {
            const updatedInfo = [...prevData.info];
            const currentRow = updatedInfo[index] || {};
            updatedInfo[index] = {
                ...currentRow,
                [field]: value,
                // Store values from the row object as well
                quantity: row.quantity,
                lowerRange: row.lowerRange,
                upperRange: row.upperRange,
                serviceDesc: row.serviceDesc,
                amount: Number(value) * row.quantity,
            };
            return {
                ...prevData,
                info: updatedInfo,
            };
        });
    };

    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    function isValueInRange(lowerRange, upperRange, value) {
        return Number(value) >= Number(lowerRange) && Number(value) <= Number(upperRange);
    }

    function isQuotationFieldsComplete(formData) {
        if (formData.info.length === 0) return false;
        for (const row of formData.info) {
            if (row.spCosting === null || row.spCosting === undefined || row.spCosting === '') {
                return false;
            }
        }
        return true;
    }


    const handleSubmit = async () => {

        if (formData.phone === "" || formData.name === "") {
            alert("Please fill the required fields !")
            return
        }
        else if (formData.phone.length !== 10) {
            alert("Invalid Phone number")
            return
        } else if (formData.email && !isValidEmail(formData.email)) {
            alert("Invalid Email Address")
            return
        }


        const ans = formData.info.every((item) => {
            return isValueInRange(item.lowerRange, item.upperRange, item.spCosting);
        });


        if (!isQuotationFieldsComplete(formData)) {
            alert("Please fill all the fields !")
            return
        }

        if (ans === false) {
            alert("The entered values are not in range !")
            return
        }

        try {
            setActive(true);
            const res = await axios.post(`${process.env.REACT_APP_APIKEY}/service-provider/quotation/${currentProject._id}`, formData, {
                withCredentials: true,
                headers: {
                    "content-type": "application/json"
                }
            });
            setMsg(res.data);
            setActive(false)
            setTimeout(() => {
                navigate("/service-provider")
            }, 3000)
        } catch (error) {
            alert(error.response.data.message);
            navigate("/service-provider")
        }
    }



    useEffect(() => {

        const verify = async () => {
            const res = await axios.get(`${process.env.REACT_APP_APIKEY}/service-provider/quotation/verify/${currentProject._id}`);
            setMsg(res.data);
        }
        if (!currentProject.selectedServicePartner) {
            verify();
        }

    }, []);


    useEffect(() => {

        const fetchQuotation = async () => {
            const res = await axios.get(`${process.env.REACT_APP_APIKEY}/service-provider/quotation/${currentProject._id}`);
            setInitialQuotation(res.data);
        }
        if (!currentProject.selectedServicePartner) {
            fetchQuotation();
        }

    }, []);



    useEffect(() => {
        const retrieveQuotation = async () => {
            try {

                const res = await axios.get(`${process.env.REACT_APP_APIKEY}/service-provider/project/quotation/${currentProject._id}/${currentServiceProvider?._id}`, {
                    withCredentials: true,
                });
                setQuotation(res.data);
                console.log(res.data, "......................")
            } catch (err) {
                // dispatch(loginFailure());
                // dispatch(projectFetchFailure());
                // navigate("/");
                console.log(err);
            }
        }

        if (currentProject.selectedServicePartner) {
            retrieveQuotation();
        }

    }, [])



    return (<>


        {active && <Loader />}
        {(msg && msg !== "Time to apply for quotation is expired") && <Popup> <div><p>{msg}</p></div></Popup>}
        {(msg && msg !== "Time to apply for quotation is expired") && <Loader />}



        <Container>

            {(quotation && currentProject?.selectedServicePartner) &&

                <RightContainer>

                    

                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: "35%" }} >Description of Service</th>
                                    <th>Quantity</th>
                                    <th>Price Range</th>
                                    <th>S.P Costing</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>


                            <tbody id="table-body">

                                {quotation && quotation?.details.map((row, rowIndex) => {

                                    return <tr key={row._id} >
                                        <td  ><textarea disabled className='service-desc' >{row.serviceDesc}</textarea></td>
                                        <td><input disabled className='service-input' type="number" value={row.quantity} /></td>
                                        <td><div className='price-range-input-container'><input disabled className='service-input' type="number" value={row.lowerRange} /> <p>-</p> <input disabled className='service-input' type="number" value={row.upperRange} /></div></td>
                                        <td><input className='service-input' value={row.spCosting} disabled type="number" /></td>
                                        <td><input className='service-input' value={row.amount} disabled type="number" /></td>
                                    </tr>
                                })
                                }
                            </tbody>

                        </table>

                        <div id="total" >
                            <span>Total</span>
                            <span id="finalPrice" >{quotation?.total}</span>

                        </div>

                
                </RightContainer>
            }



            {(initialQuotation && !currentProject.allocatedServicePartners.includes(currentServiceProvider._id)) &&


                <>

                    <div id="main" >


                        <SPDetails>
                            <div>
                                <label htmlFor="name">Name:</label>
                                <input type="text" id="name" name="name" value={formData.name} onChange={handleInputChange} required />
                                {!formData.name && <span class="required">*</span>}

                                <label htmlFor="phone">Phone:</label>
                                <input type='number' id="phone " name="phone" value={formData.phone} onChange={handleInputChange} requiredpattern={"[0-9]{10}"} maxLength="10" />
                                {!formData.phone && <span class="required">*</span>}

                                <label htmlFor="email">Email:</label>
                                <input type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} required />

                            </div>
                        </SPDetails>


                        <div id="inital-quotation" >
                            <div>
                                <table id="info-table" >
                                    <thead>
                                        <tr>
                                            <th>Description of Service</th>
                                            <th>Quantity</th>
                                            <th>Price Range</th>
                                            <th>S.P Costing</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody id="table-body">
                                        {initialQuotation &&
                                            initialQuotation[0]?.info.map((row, index) => (
                                                <tr key={row._id}>
                                                    <td>
                                                        <textarea disabled className='service-desc' >{row.serviceDesc}</textarea>
                                                    </td>
                                                    <td>
                                                        <input disabled className='service-input' type='number' value={row.quantity} />
                                                    </td>
                                                    <td>
                                                        <div className='price-range-input-container'>
                                                            <input disabled className='service-input' type='number' value={row.lowerRange} />
                                                            <p>-</p>
                                                            <input
                                                                disabled className='service-input' type='number' value={row.upperRange} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input
                                                            className='service-input'
                                                            type='number'
                                                            value={formData.info[index]?.spCosting || ''}
                                                            onChange={(e) =>
                                                                handleTableInputChange('spCosting', e.target.value, index, row)
                                                            }

                                                            style={{ border: formData.info[index]?.spCosting && !isValueInRange(row.lowerRange, row.upperRange, formData.info[index]?.spCosting) ? "2px solid red" : "" }}
                                                        />
                                                        {/* { !isValueInRange(row.lowerRange, row.upperRange, formData.info[index]?.spCosting) && ( <span className="required">*</span> )} */}
                                                    </td>
                                                    <td>
                                                        <input
                                                            className='service-input'
                                                            type='number'
                                                            value={formData.info[index]?.amount || ''}
                                                            onChange={(e) =>
                                                                handleTableInputChange('amount', e.target.value, index, row)
                                                            }
                                                            disabled
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <BtnContainer>
                            <button onClick={handleSubmit}>Submit</button>
                        </BtnContainer>
                    </div>
                </>

            }


            {(currentProject.allocatedServicePartners.includes(currentServiceProvider._id) && currentProject.selectedServicePartner !== currentServiceProvider._id) &&

                <Details>
                    <p>You have already applied for the project</p>
                </Details>
            }

        </Container >

    </>
    )
}






const Container = styled.div`
display: flex;
height: 91vh;
font-family: 'Outfit';

    #main{
    margin: 45px 10px;
    }
    #inital-quotation{
    display: flex;
    flex-direction: column;

    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
    }

    th, td {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    th {
      background-color: #f2f2f2;
    }

    thead > tr>th:nth-child(1) {
        width: 40%;
    }
    thead{
      font-size: 15px;
    }
    thead tr th{
      padding: 14px;
    }
}

@media only screen and (max-width: 768px) {
    #main{
        margin: 0px;
        width: 100%;
        padding: 8px;
    }
    #inital-quotation{
        width: 100%;
        overflow-x: scroll;

    table {
        width: 700px;
    }

    thead > tr>th:nth-child(1) {
        width: 200px;
    }

    }
    
}
`
const QuotationDiv = styled.div`
background-color: #e8e9ec;
padding: 28px;
margin: 40px 0px;
font-size: 15px;
border-radius: 2px;

>div{
    display: flex;
    gap: 40px;
}

.innerdiv{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

td{
    font-size: 14px;
    font-family: sans-serif;
}


`

const Notifier = styled.div`
min-width:  465ps;
margin: 35px 0px;
background: #c9bed7;
display: flex;
padding: 3px 21px;
border-radius: 4px;

p{
    padding: 15px;
}
input{
    margin: 10px 50px;
    text-align: center;
    width: 50px;
}

button{
    align-self: flex-end;
    width: 129px;
    padding: 8px;
    background-color: blueviolet;
    border: none;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    margin: 10px;
}
`



const RightContainer = styled.div`
width: 100%;
display: flex;
padding: 0px 25px;
margin: 30px 0px;
flex-direction: column;

h4{
    font-size: 26px;
    margin-bottom: 10px;
}

table {
      border-collapse: collapse;
      width: 100%;
      margin-top: 20px;
    }

    th, td {
      border: 1px solid #474654;
      padding: 12px;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
    }

    #total{
   
    background: #f2f2f2;
    font-family: 'Outfit';
    font-size: 15px;
    padding: 11px 36px;
    display: flex;
    justify-content: space-between;
    border:1px solid black;
    border-top: none;
}


#finalPrice{
    display: flex;
    align-items: center;
    gap: 20px;
}

@media only screen and (max-width: 768px) {
    padding: 0px ;
    margin: 0px;
        width: 100%;
        overflow-x: scroll;

        #total{
            width: 800px;
        }

    table {
        width: 800px;
    }

    thead > tr>th:nth-child(1) {
        width: 200px;
    }
}
`


const SPDetails = styled.form`
display: flex;
flex-direction: column;

span{
    margin-left: -45px;
    margin-bottom: 24px;
    color: red;
    font-size: 21px;
}

>div{
    display: flex;
    gap: 30px;
    align-items: center;
}
   
label {
display: block;
margin-bottom: 8px;
}

input {
    width: 100%;
    padding: 8px;
  
    margin-bottom: 16px;
    box-sizing: border-box;
}
input[type="submit"] {
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
}


@media only screen and (max-width: 768px) {
    >div{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    }

    span{
    align-self: flex-end;
    justify-content: flex-end;
    display: flex;
    margin-top: -54px;
    margin-right: 9px;
    }
}


`



const BtnContainer = styled.div`
display: flex;
justify-content: flex-end;
padding: 0px 20px;

button{
    padding: 11px;
    width: 164px;
    background: blueviolet;
    border: none;
    color: white;
    border-radius: 3px;
    margin: 30px 0px;
    cursor: pointer;
}

button:hover{
    transform: scale(0.95);
}

@media only screen and (max-width: 768px) {
    button{
        width: 100%;
    }
}

`

const Popup = styled.div`
position: absolute;
top: 0;
left: 0;
display: flex;
align-items: center;
justify-content: center;
z-index: 10;
height: 100vh;
width: 100vw;
p{
padding: 24px;
}
div{
background-color:#5e5858;
color: white;
font-family: 'Outfit';
border-radius:4px;
}

`

const Details = styled.div`
padding :25px 45px ;
padding-bottom: 0px;
margin: 30px 0px;
h4{
    margin: 15px 0px;
    font-family: 'Outfit', sans-serif;
    font-size: 18px;
}
div{
    display: flex;
    gap:30px;
}
div div{
    display: flex;
    flex-direction: column;
    gap:45px;
    margin: 13px 0px;
}
div div:nth-child(2){
    font-family: none;
}
`