import { React, useEffect, useState } from 'react'
import "../../index.css"
import styled from 'styled-components'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import axios from "axios"
import { format } from "timeago.js"
import HashLoader from "react-spinners/HashLoader";
import { fetchProject, projectFetchFailure } from '../../redux/projectSlice'
import { loginFailure } from '../../redux/adminSlice'


export default function AllProjects({ type }) {

  const { currentAdmin, newProjects, completedProjects } = useSelector(state => state.admin);

  const [projects, setProjects] = useState(null);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    const verification = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_APIKEY}/admin/verify`, {
          withCredentials: true,
        });

        if (!res.data) {
          dispatch(loginFailure())
          navigate("/")
        }

      }
      catch (err) {
        dispatch(loginFailure())
        navigate("/")
      }
    }
    verification();
  }, []);


  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_APIKEY}/admin/project/${type}/${currentAdmin?._id}`, {
          withCredentials: true,
        });
        setProjects(res.data)
      } catch (error) {
        dispatch(loginFailure())
        dispatch(projectFetchFailure());
        navigate("/");
      }
    }
    setProjects(null);
    fetchProjects();

  }, [type]);


  

  const fetchParticularProject = async (e) => {
    try {
      dispatch(projectFetchFailure());
      const res = await axios.get(`${process.env.REACT_APP_APIKEY}/admin/project/${e.target.id}/${currentAdmin?._id}`, {
        withCredentials: true,
      });
      dispatch(fetchProject(res.data));
      navigate(`/admin/project`)
    } catch (err) {
      dispatch(projectFetchFailure());
    }
  }


  return (
    <>
      <ProjectContainer>
        <Header>
          <Link style={{ textDecoration: "none" }} to="/admin" >
            <h6 style={{ borderBottom: location.pathname === "/admin" ? "2px solid blueviolet" : "" }} >New Projects</h6>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/admin/inprogress" >
            <h6 style={{ borderBottom: location.pathname === "/admin/inprogress" ? "2px solid blueviolet" : "" }} >In Progress</h6>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/admin/completed" >
            <h6 style={{ borderBottom: location.pathname === "/admin/completed" ? "2px solid blueviolet" : "" }} >Completed</h6>
          </Link>


        </Header>

        <div className='pj-container' >

          {

            projects ? projects.map((project) => {
              return <ProjectCard key={project._id}  >
                <span style={{ textAlign: "end" }}  >{format(project.createdAt)}</span>
                <h6>{project.service}</h6>
                <div>
                  <p>Customer Budget</p>
                  <p>{project.budget}</p>
                </div>
                <div>
                  <p>Area</p>
                  <p>{project.area ? project.area : "Not Specified"}</p>
                </div>

                
                  <button onClick={fetchParticularProject} id={project._id} >
                    {type==="completed" ? "See details": "Get it done"}
                  </button>

              </ProjectCard>
            })

              : <HashLoader style={{ position: "absolute", top: "50%", left: "50%" }} color="#010504" size={50} />

          }



        </div>


      </ProjectContainer>
    </>
  )
}


const ProjectContainer = styled.div`
height: 100%;
padding: 20px 40px;
display:  flex ;
flex-direction: column;
`

const Header = styled.div`
display: flex;
align-items: center;
height: 50px;
width: 100%;

h6{
  font-size: 18px;
  margin: 0px 40px;
    font-family: 'Outfit', sans-serif;
    padding-bottom: 8px;
}
`

const ProjectCard = styled.div`
background-color: #f1f3fa;
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
padding: 20px;
width: 310px;
height: 225px;
display: flex;
flex-direction: column;
margin: 15px;

span{
  text-align: end;
    font-size: 13px;
    font-family: 'Inter';
}


h6{
  font-family: 'Outfit',sans-serif;
    font-size: 17px;
    margin: 7px 0px;
}

button{
  width:100%;
  background-color: #009FD9;
  color: white;
  padding: 12px;
    border: aliceblue;
    border-radius: 5px;
    font-size: 16px;
    margin: 20px 0px;
    cursor: pointer;
}

div{
  display: flex;
  justify-content: space-between;
  margin: 7px 0px;
  font-size: 15px;
}
`
