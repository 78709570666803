import { React, useState, useEffect } from 'react'
import styled from 'styled-components'
import "../../index.css"
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios"
import { useNavigate } from 'react-router-dom';
import { loginFailure } from '../../redux/adminSlice';
import { projectFetchFailure, fetchAllocatedQuotation, fetchProject } from '../../redux/projectSlice';
import RefreshIcon from '@mui/icons-material/Refresh';



export default function AdminQuotationInfo({ index }) {

    const { currentAdmin } = useSelector(state => state.admin);
    const { currentProject } = useSelector(state => state.project);


    const [allocatedQuotation, setAllocatedQuotation] = useState([])

    const navigate = useNavigate();
    const dispatch = useDispatch();


    console.log(allocatedQuotation);


    const [info, setInfo] = useState([]);
    const [costing, setCosting] = useState({ totalAmount: 0, totalSupplierCosting: 0, finalCosting: 0 });

    console.log(info);

    const calculateTotal = () => {
        let totalAmount = 0;
        let totalSupplierCosting = 0;

        info.forEach((x) => {
            totalAmount += Number(x.amount) || 0;
            totalSupplierCosting += Number(x.supplierCosting) || 0;
        });

        const updatedCosting = {
            totalAmount: totalAmount,
            totalSupplierCosting: totalSupplierCosting,
            finalCosting: totalAmount + totalSupplierCosting,
        };
        setCosting(updatedCosting);
    };




    const retriveServicePartners = async () => {
        try {
            const quotations = currentProject?.allocatedQuotations;
            const res = await axios.post(`${process.env.REACT_APP_APIKEY}/admin/project/servicepartners/${currentAdmin?._id}`, quotations, {
                withCredentials: true,
            });

            setAllocatedQuotation(res.data);
            console.log(res.data);
        } catch (err) {
            // dispatch(loginFailure());
            // dispatch(projectFetchFailure());
            // navigate("/");
            console.log(err);
        }
    }

    


    const handleTableInputChange = (field, value, index, row) => {
        setInfo((prevInfo) => {
            const updatedInfo = [...prevInfo];
            const currentRow = updatedInfo[index] || {};
            updatedInfo[index] = {
                ...currentRow,
                [field]: value, // Ensure that value is converted to a number
                quantity: row.quantity,
                lowerRange: row.lowerRange,
                upperRange: row.upperRange,
                serviceDesc: row.serviceDesc,
                spCosting: row.spCosting, // Update the supplier cost value as a number
                amount: row.amount,
            };
            return updatedInfo;
        });
    };



    const updateQuotation = async (e) => {
        e.preventDefault();
        const updatationDetails = { info, costing };
        console.log(updatationDetails);

        if (info.length === 0 || costing.finalCosting === '') {
            alert("All fields are required")
        } else {
            try {
                const res = await axios.put(`${process.env.REACT_APP_APIKEY}/admin/project/quotation/${allocatedQuotation[index]._id}/${currentAdmin._id}`, updatationDetails, { withCredentials: true, });
                retriveServicePartners();
                alert("Quotation Updated Successfully")
                navigate(`/admin/project`);
            } catch (error) {
                dispatch(loginFailure());
                navigate("/")
            }
        }
    }


    useEffect(() => {
        const verification = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_APIKEY}/admin/verify`, {
                    withCredentials: true,
                });

                if (!res.data) {
                    dispatch(loginFailure())
                    navigate("/")
                }

            }
            catch (err) {
                dispatch(loginFailure())
                navigate("/")
            }
        }
        verification();
    }, []);


    useEffect(() => {
        const reset = () => {
            setCosting({ totalAmount: 0, totalSupplierCosting: 0, finalCosting: 0 });
            setInfo([]);
        }
    }, [])


    useEffect(() => {
        retriveServicePartners();
    }, [index])


    return (
        <Container>
            <SPDetails>
                <div>
                    <label htmlFor="name">Name:</label>
                    <input disabled type="text" id="name" name="name" value={allocatedQuotation[index]?.name} />

                    <label htmlFor="phone">Phone:</label>
                    <input disabled type='number' id="phone" name="phone" value={allocatedQuotation[index]?.phone} />

                    <label htmlFor="email">Email:</label>
                    <input disabled type="email" id="email" name="email" value={allocatedQuotation[index]?.email} />
                </div>
            </SPDetails>

            <table>
                <thead>
                    <tr>
                        <th>Description of Service</th>
                        <th>Quantity</th>
                        <th>Price Range</th>
                        <th>S.P Costing</th>
                        <th>Amount</th>
                        <th>Supplier Costing</th>
                        <th>Supplier</th>
                    </tr>
                </thead>


                <tbody id="table-body">

                    {allocatedQuotation && allocatedQuotation[index]?.info.map((row, rowIndex) => {

                        return <tr key={row._id} >
                            <td  ><textarea disabled className='service-desc' >{row.serviceDesc}</textarea></td>
                            <td><input disabled className='service-input' type="number" value={row.quantity} /></td>
                            <td><div className='price-range-input-container'><input disabled className='service-input' type="number" value={row.lowerRange} /> <p>-</p> <input disabled className='service-input' type="number" value={row.upperRange} /></div></td>
                            <td><input className='service-input' value={row.spCosting} disabled type="number" /></td>
                            <td><input className='service-input' value={row.amount} disabled type="number" /></td>

                            {
                                allocatedQuotation[index].finalized &&
                                <>
                                    <td><input className='service-input' disabled value={row.supplierCosting} type="number" /></td>
                                    <td><input className='service-input' disabled type="text" value={row.supplierDetails} /></td>
                                </>
                            }

                            {
                                !allocatedQuotation[index].finalized &&
                                <>
                                    <td><input className='service-input' onChange={(e) => { handleTableInputChange('supplierCosting', e.target.value, rowIndex, row) }} type="number" /></td>
                                    <td><input className='service-input' onChange={(e) => { handleTableInputChange('supplierDetails', e.target.value, rowIndex, row) }} type="text"  /></td>
                                </>
                            }

                        </tr>
                    })
                    }
                </tbody>

            </table>

            <div id="total" >
                <span>Total</span>

                {allocatedQuotation[index]?.finalized && <span id="finalPrice" >{allocatedQuotation[index]?.costing.finalCosting}</span>}

                {!allocatedQuotation[index]?.finalized &&
                    <span id="finalPrice" ><RefreshIcon onClick={calculateTotal} />{costing.finalCosting}</span>}

            </div>

            {!allocatedQuotation[index]?.finalized &&
                <ButtonContainer>
                    <button onClick={updateQuotation} >Save Quotation</button>
                </ButtonContainer>
            }

        </Container>
    )
}

const Container = styled.div`
width: 100%;

table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
    }

    th, td {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    th {
      background-color: #f2f2f2;
    }

    thead > tr>th:nth-child(1) {
        width: 25%;
    }
    thead > tr>th:last-child {
        width: 15%;
    }
    thead{
      font-size: 15px;
    }
    thead tr th{
      padding: 14px;
      font-size: 12px;
    }

 #add{

    border-radius: 50%;
    margin: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #5a3b3b;
    color: white;
    border: none;
    padding: 1px;
    cursor: pointer;
}

#total{
    background: #f2f2f2;
    font-family: 'Outfit';
    font-size: 15px;
    padding: 11px 36px;
    display: flex;
    justify-content: space-between;
    border:1px solid #dddddd;
}


#finalPrice{
    display: flex;
    align-items: center;
    gap: 20px;
}
`

const CreateQuotation = styled.button`
    padding: 9px;
    background: blueviolet;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
 `

const ButtonContainer = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
margin: 40px 0px;

button{
    background: green;
    padding: 10px;
    color: white;
    border: none;
    font-family: 'Outfit';
    cursor: pointer;
    border-radius: 4px;
}
`


const SPDetails = styled.form`
display: flex;
flex-direction: column;
margin: 10px;
margin: 40px;
font-family: 'Outfit';

>div{
    display: flex;
    gap: 30px;
    align-items: center;
}
   
label {
display: block;
}

input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}
input[type="submit"] {
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
}
`
