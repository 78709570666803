import React from 'react'
import styled from 'styled-components'
import "../index.css"
import { Link as ScrollLink, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';


export default function Footer() {
  return (
    <Container>
      <Tab>
        <div>
          <h4 style={{ fontSize: "15px" }} >Treeoz EcoVenture Private Limited</h4>
          <p className='footer-para' align="justify" >
            EcoVenture is the highest-rated Agritech company, with a team of highly competent landscaping professionals who pay close attention to the smallest of details.        </p>
        </div>
      </Tab>

      <Tab>

        <h5 style={{ fontSize: "15px" }} >Services</h5>
        <div>
          <p className='footer-para' >Vertical Gardening</p>
          <p className='footer-para' >Roof Top Gardening</p>
          <p className='footer-para' >Kitchen Gardening</p>
          <p className='footer-para' >Landscaping</p>

        </div>

      </Tab>

      <Tab>
        <h5 style={{ fontSize: "15px" }} >Quick Links</h5>

        <div id="link-hover" >
          <ScrollLink style={{ textDecoration: "none", cursor: "pointer" }} activeClass="active" to="test1" spy={true} smooth={true} offset={-70} duration={500} >
            <p className='footer-para' >Home</p>
          </ScrollLink>


          <ScrollLink style={{ textDecoration: "none", cursor: "pointer" }} activeClass="active" to="about" spy={true} smooth={true} offset={-90} duration={500} >
            <p className='footer-para' >About</p>
          </ScrollLink>


          <ScrollLink style={{ textDecoration: "none", cursor: "pointer" }} activeClass="active" to="services" spy={true} smooth={true} offset={-30} duration={500} >
            <p className='footer-para' >Services</p>
          </ScrollLink>

          <ScrollLink style={{ textDecoration: "none", cursor: "pointer" }} activeClass="active" to="gallary" spy={true} smooth={true} offset={-30} duration={500} >
            <p className='footer-para'>Gallary</p>
          </ScrollLink>

          <ScrollLink style={{ textDecoration: "none", cursor: "pointer" }} activeClass="active" to="contact" spy={true} smooth={true} offset={-30} duration={500} >
            <p className='footer-para'>Contact</p>
          </ScrollLink>
        </div>
      </Tab>

      <Tab>
        <h5 style={{ fontSize: "15px" }} >Contact Us</h5>
        <p className='footer-para' > Second Floor, Sadaqat Ashram, near kurji</p>
        <p className='footer-para' >+91 8709291650</p>
        <p className='footer-para' >support@ecoventure.in</p>


      </Tab>


    </Container>
  )
}


const Container = styled.footer`
height:40vh;
margin: auto;
background:#0d221f;
display: flex;
color: white;
font-family: 'Outfit', sans-serif;
justify-content: center;
align-items: center;

@media only screen and (max-width: 768px) {
  justify-content: space-evenly;
  flex-direction: column;
  height: fit-content;
  padding: 20px;
  >div:nth-child(1){
      display: none;
    }
  
    >div:nth-child(3) div{
      justify-content: space-around;
    }
}
`

const Tab = styled.div`
width: 16%;
height: 100%;
margin: 0px 20px;
padding: 20px;
padding-top:50px;

p{
  font-size: 14px;
  width: fit-content;
}
#link-hover p:hover{
  border-bottom: 2px solid red;
}

@media only screen and (max-width: 768px) {
    padding: 0px;
    width: 100%;
    

    h5{
      font-size: 20px;
    }

    div{
      display: flex;
      gap:10px
    }
   
}
`