import React from 'react'
import styled from "styled-components"
import rating from "../images/ratings.png"
import img1 from "../images/1.png"
import img2 from "../images/2.png"
import img3 from "../images/3.png"
import img4 from "../images/4.png"
import img5 from "../images/5.png"
import img6 from "../images/6.png"

export default function About() {
    return (
        <Container name="about" className="element">
            <h4>Ecoventure</h4>
            <p id="first-para" align="justify" >
                Plants are the ideal option for both interior and outdoor decoration. They not only look wonderful, but they also benefit the environment by removing toxins from the air. Gardening plants in your home has a number of advantages. Nature has always been a human being's best friend. Plants have a variety of biological effects on humans in addition to making the environment more pleasant and healthier. Plants are a great way to improve one's mood. They boost a person's productivity and creativity. 
            </p>
            <br/>
            <p  align="justify"  >
            Here at EcoVenture, we offer all kinds of Gardening and Landscaping solutions ranging from Landscaping, Vertical Gardening, Roof top Gardening, and Kitchen Gardening to Organic Farming. We aim to provide our clients high-quality service and 100% satisfaction.

            </p>

            <img  src={rating} />

            <div id="img-conatainer-trust" >
                <img src={img1} />
                <img src={img2} />
                <img src={img3} />
                <img src={img4} />
                <img src={img5} />
                <img src={img6} />
            </div>

        </Container>
    )
}


const Container = styled.div`
min-height: 100vh;
padding: 0px 140px ;
display: flex;
flex-direction: column;
align-items: center;

#img-conatainer-trust{
display: none;
}

h4{
    font-size: 27px;
    font-family: 'Outfit';
    margin-bottom: 20px;
}

p{
    font-family: sans-serif;
    font-size: 15px;
    text-align: center;
    line-height: 20px;
}


img{
    width:100%;
    margin: 20px 0px;
    width: 75%;
}

@media only screen and (max-width: 768px) {
    padding: 20px 20px;
    #first-para{
        display: none;
    }
    p{
        text-align: justify;
    }
    >img{
        display: none;
    }

    #img-conatainer-trust{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    img{
        width: 100%;
    }
    
    }
}
`