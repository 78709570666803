import React, { useState,useEffect } from 'react';
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { loginSuccess, loginFailure } from '../../redux/userSlice';


export default function UserPopup() {

    const navigate = useNavigate();
    const { currentUser } = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [updatationDetails, setUpdatationDetails] = useState({ name: "", phone: "", city: "", address: "", postalCode: "" })

    
    useEffect(() => {
        const verification = async () => {
          try {
            const res = await axios.get(`${process.env.REACT_APP_APIKEY}/user/verify`, {
              withCredentials: true,
            });
    
            if (!res.data) {
              dispatch(loginFailure())
              navigate("/")
            }
    
          }
          catch (err) {
            dispatch(loginFailure())
            navigate("/")
          }
        }
        verification();
      }, []);


    const updateUser = async (e) => {
        e.preventDefault();

        try {
            const res = await axios.put(`${process.env.REACT_APP_APIKEY}/user/${currentUser._id}`, { updatationDetails }, { withCredentials: true, });
            dispatch(loginSuccess(res.data));
            navigate(`/user`);
            console.log(res.data);
        } catch (error) {
            console.log(error);
            dispatch(loginFailure());
        }

    }



    return (
        <Container>
            <Form>
                <p>Fill the  details to continue</p>
                <input onChange={(e) => setUpdatationDetails(prevDetails => ({ ...prevDetails, name: e.target.value }))} type='text' placeholder='Username' required></input>
                <input onChange={(e) => setUpdatationDetails(prevDetails => ({ ...prevDetails, phone: e.target.value }))} type='number' maxLength="10" placeholder='Phone' required></input>
                <input onChange={(e) => setUpdatationDetails(prevDetails => ({ ...prevDetails, city: e.target.value }))} type='text' placeholder='City' required></input>
                <input onChange={(e) => setUpdatationDetails(prevDetails => ({ ...prevDetails, address: e.target.value }))} type='text' placeholder='Address' required></input>
                <input onChange={(e) => setUpdatationDetails(prevDetails => ({ ...prevDetails, postalCode: e.target.value }))} type='number' placeholder='Postal code' required></input>
                <input value="Continue" type='button' onClick={updateUser} />
            </Form>

        </Container>
    )
}

const Container = styled.div`
height: 100vh;
background-color: antiquewhite;
display: flex;
justify-content: center;
align-items: center;
font-family: 'Outfit';
`
const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 420px;
    border: 2px solid ;
    padding: 40px 30px;
    border-radius: 10px;
p{
    text-align: center;
    margin: 10px 0px;
    font-size: 18px;
    margin-bottom: 30px;
}

input{
    padding: 9px 15px;
    border-radius: 5px;
    border: none;
    margin: 13px 0px;
}

input[type="button"]{
    border: none;
    background-color: blueviolet;
    padding: 12px;
    color: white;
    border-radius: 8px;
    margin: 20px 0px;
    margin-top: 20px;
}
`
