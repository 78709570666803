import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import "../../index.css"
import AddCardIcon from '@mui/icons-material/AddCard';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Navbar from "../../components/Navbar"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { loginSuccess, loginFailure } from '../../redux/userSlice';

export default function UpdateUser() {


    const { currentUser } = useSelector(state => state.user)

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [userDetails, setUserDetails] = useState({ name: currentUser?.name, phone: currentUser?.phone, city: currentUser?.city, address: currentUser?.address, postalCode: currentUser?.postalCode })

    useEffect(() => {
        const verification = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_APIKEY}/user/verify`, {
                    withCredentials: true,
                });

                if (!res.data) {
                    dispatch(loginFailure())
                    navigate("/")
                }

            }
            catch (err) {
                dispatch(loginFailure())
                navigate("/")
            }
        }
        verification();
    }, []);



    const updateUser = async (e) => {
        e.preventDefault();
        const updatationDetails = userDetails;


        if (updatationDetails.name === "" || updatationDetails.phone === "" || updatationDetails.city === "" || updatationDetails.address === "" || updatationDetails.postalCode === "") {
            alert("All fields are required")
        } else {
            try {
                const res = await axios.put(`${process.env.REACT_APP_APIKEY}/user/${currentUser._id}`, { updatationDetails }, { withCredentials: true, });
                dispatch(loginSuccess(res.data));
                alert("Profile Updated Successfully")
                navigate(`/user`);
            } catch (error) {
                dispatch(loginFailure());
                navigate("/")
            }
        }
    }

    return (<>
        <Navbar />

        <Container>

            <LeftContainer>
                <Link to="/user" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user" ? "#e4f0f8" : "" }} >
                        <PersonIcon /> <p>  Account</p>
                    </Tab>
                </Link>
                <Link to="/user/profile" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/profile" ? "#e4f0f8" : "" }} >
                        <ManageAccountsIcon />  <p> Update Profile</p>
                    </Tab>
                </Link>
                <Link to="/user/project" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/project" ? "#e4f0f8" : "" }} >
                        <AssignmentIcon /> <p>Project Details</p>
                    </Tab>
                </Link>
                <Link to="/user/payment" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/payment" ? "#e4f0f8" : "" }} >
                        <AddCardIcon />     <p> Payment Options</p>
                    </Tab>
                </Link>
                <Link to="/user/support" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/support" ? "#e4f0f8" : "" }} >
                        <LocalPhoneIcon />   <p>Support </p>
                    </Tab>
                </Link>

            </LeftContainer>
            <RightContainer>
                <h4>Update Profile</h4>

                <Details id="pc-update" >
                    <div>
                        <label>Username</label>
                        <label>Phone Number</label>
                        <label>City</label>
                        <label>Address</label>
                        <label>Postal Code</label>
                    </div>
                    <div>
                        <input value={userDetails.name} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, name: e.target.value }))} type='text' />
                        <input value={userDetails.phone} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, phone: e.target.value }))} type='text' />
                        <input value={userDetails.city} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, city: e.target.value }))} type='text' />
                        <input value={userDetails.address} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, address: e.target.value }))} type='text' />
                        <input value={userDetails.postalCode} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, postalCode: e.target.value }))} type='text' />
                    </div>


                </Details>

                <Details id="mob-update" >
                    <div>
                        <label>Username</label>
                        <input value={userDetails.name} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, name: e.target.value }))} type='text' />
                        <label>Phone Number</label>
                        <input value={userDetails.phone} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, phone: e.target.value }))} type='text' />
                        <label>City</label>
                        <input value={userDetails.city} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, city: e.target.value }))} type='text' />
                        <label>Address</label>
                        <input value={userDetails.address} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, address: e.target.value }))} type='text' />
                        <label>Postal Code</label>
                        <input value={userDetails.postalCode} onChange={(e) => setUserDetails(prevDetails => ({ ...prevDetails, postalCode: e.target.value }))} type='text' />
                    </div>
                </Details>
                <button onClick={updateUser} >Save Changes</button>
            </RightContainer>

        </Container>
    </>
    )
}

const Container = styled.div`
display: flex;
height: 91vh;
font-family: 'Outfit';
@media only screen and (max-width: 768px) {
height: fit-content;
}
`
const LeftContainer = styled.div`
width: 20%;
display: flex;
flex-direction: column;
padding: 30px 40px;
background-color: #d4d1cd;
@media only screen and (max-width: 768px) {
display: none;
}
`
const RightContainer = styled.div`
width: 80%;
display: flex;
flex-direction: column;
padding: 55px 120px;

#mob-update{
    display: none;
}

h4{
    font-size: 26px;
    margin-bottom: 35px;
}

button{
    width: 146px;
    padding: 10px;
    background-color: blueviolet;
    border: none;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    margin: 10px;
}

@media only screen and (max-width: 768px) {
    width: 100%;
    padding: 30px;

    #pc-update{
        display: none;
    }

    h4{
        margin-bottom:0px;
    }

    button{
        width: 100%;
        padding: 15px;
        margin: 10px 0px  ;
    }

    #mob-update{
        display: flex;
        padding: 25px 0px;
        >div{
            width: 100%;
            gap:12px;
        }
        input{
            width: 100%;
        }
        label{
            padding-left: 0px;
        }
    }

}
`



const ImageContainer = styled.div`
height: 200px;
width: 200px;
overflow: hidden;
border-radius: 50%;

image{
    width: 100%;
    height: 100%;

}
`
const Top = styled.div`
width: 100%;
display: flex;
`
const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    row-gap: 10px;
    color: mediumvioletred;
`

const Details = styled.div`
display: flex;
gap: 50px;
padding: 25px 0px;

input{
    padding: 10px;
    width: 400px;
    border-radius: 8px;
    border: 1px solid lightblue;
}

label{
    padding: 10px;
}

div{
    display: flex;
    flex-direction: column;
    gap:30px
}

`

const Tab = styled.div`
padding: 13px;
border: 2px solid grey;
margin: 20px 0px;
display: flex;
align-items: center;
gap:20px;
cursor: pointer;
border-radius: 5px;
`