import { React, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import AdminQuotation from './AdminQuotation';
import { useLocation, useNavigate } from 'react-router-dom'
import OfflineShareIcon from '@mui/icons-material/OfflineShare';
import axios from "axios";
import { useSelector } from 'react-redux';
import { fetchProject, projectFetchFailure } from '../../redux/projectSlice'
import { loginFailure } from '../../redux/adminSlice';
import { useDispatch } from 'react-redux';
import Loader from '../../components/Loader';

export default function AdminShareQuotation() {

    const { currentProject } = useSelector(state => state.project);
    const { currentAdmin } = useSelector(state => state.admin);

    const [servicePartners, setServicePartners] = useState();
    const [active, setActive] = useState(false);
    const [selectedSP, setSelectedSP] = useState([]);

 
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isSelected = (spId) => {
        return selectedSP.some((selected) => selected._id === spId);
    };

    const handleCheckboxChange = (sp) => {
        const spId = sp._id;
    
        if (isSelected(spId)) {
            setSelectedSP(selectedSP.filter((selected) => selected._id !== spId));
        } else {
            setSelectedSP([...selectedSP, sp]);
        }
    };
    

    useEffect(() => {
        const verification = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_APIKEY}/admin/verify`, {
                    withCredentials: true,
                });

                if (!res.data) {
                    dispatch(loginFailure())
                    navigate("/")
                }

            }
            catch (err) {
                dispatch(loginFailure())
                navigate("/")
            }
        }
        verification();
    }, []);



    useEffect(() => {
        const fetchServicePartners = async (e) => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_APIKEY}/admin/project/servicepartners/${currentProject?.service}/${currentAdmin?._id}`, {
                    withCredentials: true,
                });
                setServicePartners(res.data);
                console.log(res.data)
            } catch (err) {
                console.log(err)
                // dispatch(loginFailure());
                // dispatch(projectFetchFailure());
                // navigate("/");
            }
        }
        fetchServicePartners();
    }, []);



    const sendEmails = async () => {
        if (selectedSP.length === 0) {
            alert("Please select the service providers");
            return
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_APIKEY}/admin/project/quotation/share/${currentProject?._id}/${currentAdmin?._id}`, selectedSP, {
                withCredentials: true,
                // headers: {
                //   "content-type": "application/json"
                // }
            });
            alert("Quotation sent Successfully");
            dispatch(fetchProject(res.data));
            navigate("/admin/project");
        } catch (error) {
            // dispatch(loginFailure());
            // dispatch(projectFetchFailure());
            // navigate("/");
            console.log(error)
        }
    }



    return (
        <Container>
            {currentProject?.quotationSent &&
                <Designs>
                    <p>Quotation already sent</p>
                </Designs>}

            {!currentProject?.quotationSent &&
                <>

                    <Tab>
                        <h4>Service Partners</h4>
                        <button onClick={sendEmails} >Send Quotation</button>
                    </Tab>


                    <Designs>
                        <SupplierTable>

                            <thead>
                                <tr>
                                    <th></th>

                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Services</th>
                                </tr>
                            </thead>
                            <tbody>

                                {servicePartners && servicePartners.map((sp) => {

                                    return <tr key={sp._id} >
                                        <td id="check" ><input type="checkbox" id="box" onChange={() => handleCheckboxChange(sp)} /> </td>

                                        <td>{sp.name}</td>
                                        <td>{sp.email}</td>
                                        <td>{sp.phone}</td>
                                        <td>
                                            <div id="services-box" >
                                                {
                                                    sp.servicesOffered.map((service) => {
                                                        {
                                                            return <p key={service} >{service}</p>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                })}



                            </tbody>
                        </SupplierTable>
                    </Designs>
                </>}

        </Container>
    )
}


const EssentialDetails = styled.span`
display: flex;
gap:20% ;
`

const SendDesign = styled.button`
background: #23e3e3;
border-radius: 6px;
display: flex;
gap: 10px;
align-items: center;
border: none;
width: fit-content;
cursor: pointer;
font-family: 'Outfit';
margin: 40px 20px;
padding: 8px 11px;
`

const Designs = styled.div`
font-family: 'Outfit';

padding: 20px;

>p{font-size: 18px;
    margin-bottom: 25px;
}

#image-set{
    display: flex;
    gap:20px;

    img{
    width: 280px;
    height: 180px;
    }
}

`

const SupplierTable = styled.table`

            border-collapse: collapse;
            width: 100%;
            margin-top: 20px;
            font-size:15px;

        th, td {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 12px;
        }

        th {
            background-color: #f2f2f2;
        }

        tbody{
            font-size: 14px;
        }
        input{
            height: 21px;
            width: 21px;
        }
        #check{
            text-align: center;
            margin: auto;
        }
        tr{
            #services-box{
                display: flex;
                flex-direction: column;
                gap:12px;
            }
        }
`

const Menu = styled.div`
background-color: #d4d1cd;
padding: 13px 40px;
display: flex;
gap: 45px;
align-items: center;
border-radius: 5px;
font-weight: bold;

div{
    padding: 7px 14px;
    border: 2px solid grey;
    border-radius: 2px;
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: black;
    cursor: pointer;
}
`


const Container = styled.div`
margin: 20px;
display: flex;
font-family: 'Inter', sans-serif;
flex-direction: column;
padding-top: 0px;
`
const LeftContainer = styled.div`
width: 30%;
height: 100%;
`

const RightContainer = styled.div`
display: flex;
flex-direction: column;
width: 70%;
height: 100%;
background-color: white;
font-family: 'Outfit';
border-radius: 20px;
padding: 20px;

 /* >div{
    padding: 21px;
    font-size: 19px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
button{
    padding: 15px;
    font-size: 16px;
    margin: 20px;
    background-color: forestgreen;
    border: none;
    color: white;
    border-radius: 7px;
} */
`

const ScrollList = styled.section`
overflow-y: scroll;
border-radius: none;
height: 100%;
`

const Details = styled.div`
padding :20px ;
h4{
    margin: 15px 0px;
    font-family: 'Outfit', sans-serif;
    font-size: 18px;
}

div{
    display: flex;
    gap:30px;
}

div div{
    display: flex;
    flex-direction: column;
    gap:25px;
    margin: 13px 0px;

}
`
const Supplier = styled.div`
margin: 16px;
justify-content: space-between;
display: flex;
background-color: aqua;
padding: 20px;
input{
    height: 20px;
    width: 20px;
}
`

const Tab = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-family: 'Outfit';
    font-size: 18px;
    align-items: center;
    
    button{
    background: blueviolet;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 3px;
    font-family: 'Outfit';
    cursor: pointer;
    }
`


