import React, { useState } from 'react'
import styled from 'styled-components'
import PersonIcon from '@mui/icons-material/Person';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import GoogleIcon from '@mui/icons-material/Google';
import "../../index.css"
import axios from "axios"
import Loader from "../../components/Loader"

import { auth, provider } from "../../firebase";
import { signInWithPopup } from 'firebase/auth';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { loginSuccess, loginFailure } from '../../redux/supplierSlice';



export default function SupplierSignIn() {


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [load, setLoad] = useState(false);


    const dispatch = useDispatch();
    const navigate = useNavigate();


    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoad(true);
        try {
            if (email === "" || password === "") {
                alert("All fields are required !")
                setLoad(false)
                return;
            }
            if (!isValidEmail(email)) {
                alert("Invalid Email Address");
                setLoad(false)
                return;
            }
            const res = await axios.post(`${process.env.REACT_APP_APIKEY}/supplier/signin`, { email, password }, { withCredentials: true, });
            dispatch(loginSuccess(res.data));
            if (res.data.profileCompleted) {
                navigate("/supplier/account");
            } else {
                navigate("/supplier/update");
            }
        } catch (error) {
            alert(error.response.data.message)
            setLoad(false)
            dispatch(loginFailure());
        }
    };



    const signInWithGoogle = async (e) => {
        e.preventDefault();
        setLoad(true);
        signInWithPopup(auth, provider).then((result) => {

            axios.post(`${process.env.REACT_APP_APIKEY}/supplier/google`, {
                name: result.user.displayName,
                email: result.user.email,
                img: result.user.photoURL,
            }, { withCredentials: true, }).then((res) => {
                dispatch(loginSuccess(res.data));
                if (res.data.profileCompleted) {
                    navigate("/supplier/account");
                } else {
                    navigate("/supplier/update");
                }
            });
        }
        ).catch(error => {
            alert("User Not found")
            setLoad(false)
            dispatch(loginFailure());
        })
    };


    return (
        <>
            {load && <Loader />}
            <Top>
                <Link to="/login" style={{ textDecoration: "none" }}  >
                    <Admin id="commonUser"  >
                        <PersonIcon style={{ marginRight: "5px" }} /> User
                    </Admin>
                </Link>

                <Link to="/login/supplier" style={{ textDecoration: "none" }} >
                    <Agent>
                        <FiberManualRecordIcon sx={{ fontSize: 10, color: "red" }} style={{ margin: "0px 10px" }} />
                        Supplier
                    </Agent>
                </Link>

                <Link to="/login/service-provider" style={{ textDecoration: "none" }} >
                    <Agent>
                        <FiberManualRecordIcon sx={{ fontSize: 10, color: "blue" }} style={{ margin: "0px 10px" }} />
                        Service Partner
                    </Agent>
                </Link>
            </Top>

            <Container>
                <InnerContainer>
                    <form>
                        <h3>Supplier Sign In</h3>
                        <div>
                            <input placeholder='Email' type="email" id="username" onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div>
                            <input placeholder='Password' type="text" id="password" onChange={e => setPassword(e.target.value)} />
                        </div>
                        <button onClick={handleLogin}  >Submit</button>
                        <p style={{ margin: "0px" }} >or</p>
                        <button onClick={(e) => { e.preventDefault(); navigate("/login/service-provider/phone") }}  >Sign in with Phone</button>
                        <button onClick={signInWithGoogle} > <GoogleIcon sx={{ fontSize: 15 }} style={{ marginRight: "10px" }} /> Sign in with Google</button>
                        <Link to="/signup/supplier" className='sign-up-link' >Sign Up</Link>
                        <Link to="/supplier/resetpass" className='sign-up-link' >Forgot Password</Link>
                    </form>

                </InnerContainer>
            </Container>
        </>

    )
}


const Top = styled.div`
height: 8vh;
background-color: #3B726A;
display: flex;
padding: 10px 20px;
justify-content: flex-end;
align-items: center;

@media only screen and (max-width: 768px) {
    justify-content: space-around;
    height: 9vh;

    
    justify-content: space-between;
    svg{
        display: none;
    }      
}

`

const Container = styled.div`
height: 90vh;
display: flex;
align-items: center;
justify-content: center;
font-family: 'Outfit';

@media only screen and (max-width: 768px) {
   margin-top: -25px;
}
`


const InnerContainer = styled.div`

    width: 330px;
    border-radius: 10px;
    padding: 25px 40px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 15px rgba(0.2, 0, 0, 0.3);

    form{
        height: 100%;
        
    }

    form p{
        text-align: center;
        margin: 20px 0px;
        font-size: 14px;
        margin-bottom: 35px;
    }

    form h3{
        font-size: 20px;
        text-align: center;
    }

    form div{
        margin:20px 0px ;
    }

    input{
        width: 100%;
        padding: 12px;
        border-radius: 9px;
        border: 2px solid #b29f9f;        
    }


    button{
        width: 100%;
        padding: 13px;
        border-radius: 20px;
        border:none;
        cursor: pointer;
        background-image: linear-gradient(to right, #3370ca, #0087bd, #009297, #599679, #8c9674);
        font-weight: bold;
        color: white;
        margin: 15px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sign-up-link{
        margin: 10px 0px;
    }

    @media only screen and (max-width: 768px) {
    width: 320px;
    border-radius: 10px;
    padding: 21px 32px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 15px rgba(0.2, 0, 0, 0.3);

    }

`


const Agent = styled.button`
padding: 9px 12px;
background: #56dada;
border: none;
border-radius: 20px;
margin: 0px 10px;
display: flex;
align-items: center;
cursor: pointer;
@media only screen and (max-width: 768px) {
    margin: 0px;
    justify-content: center;
    width: 110px;
    font-size: 12px;
}
`

const Admin = styled.button`
display: flex;
align-items: center;
padding: 5px 16px;
border: 1px solid white;
border-radius: 19px;
margin: 0px 15px;
color:white;
background-color: transparent;
cursor: pointer;
@media only screen and (max-width: 768px) {
    margin: 0px;
    padding: 8px 16px;
    width: 110px;
    justify-content: center;
}
`

