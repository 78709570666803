import React from 'react'
import styled from 'styled-components'
import logo from "../images/hero-bg.jpg"
import { useNavigate } from 'react-router-dom';


export default function Home() {

    const navigate = useNavigate();

    return (
        <div name="test1" className="element homie" >
            <div id='heroImgContainer'>
                <Image src={logo} />
            </div>
            <Headline>
                <div>
                    <h2>
                        Proper  <br />
                        Greening And <br />
                        Smart Solutions
                    </h2>
                </div>
                <Button onClick={() => navigate("/signup")} >
                    Enquire  Now
                </Button>
            </Headline>
        </div >

    )
}


const Image = styled.img`
    width: 100%;
    filter: brightness(0.6);
    clip-path: inset(0 0 61px 0);


@media only screen and (max-width: 768px) {
    width: 215%;
    height: 100%;
    object-fit: cover;
    object-position: left;
    filter: brightness(0.6);
    clip-path: inset(0 0 61px 0);
}
`

const Headline = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    font-family: 'Outfit', sans-serif;
    height: 80vh;
    padding-right: 250px;
    font-size: 43px;
    justify-content: center;

    @media only screen and (max-width: 768px) {
    /* margin-top: 25px; */
    font-size: 30px;
    padding-right: 0px;
}
    

`

const Button = styled.button`
    font-size: 16px;
    padding: 13px;
    background: #3B726A;
    border: none;
    color: white;
    width: 175px;
    border-radius: 7px;
    box-shadow: 0 4px 8px rgba(0.2, 0,.2 0.2, 0.2);
    align-self: flex-start;
    margin: 25px 0px;
    font-family: 'Outfit',sans-serif;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
}
`