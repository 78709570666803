import { getNativeSelectUtilityClasses } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentServiceProvider: null,
    error: false,
};


export const ServiceProviderSlice = createSlice({
    name: 'serviceProvider',
    initialState,
    reducers: {

        loginSuccess: (state, action) => {
            state.currentServiceProvider = action.payload;
        },

        loginFailure: (state) => {
            state.currentServiceProvider = null;
            state.error = true;
        },

        logout: (state) => {
            state.currentServiceProvider = null;
            state.error = false; 
        }
    }
});


export const { loginFailure, loginSuccess, logout } = ServiceProviderSlice.actions;

export default ServiceProviderSlice.reducer;

