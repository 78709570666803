import React, { useState } from 'react'
import styled from 'styled-components'
import PersonIcon from '@mui/icons-material/Person';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import GoogleIcon from '@mui/icons-material/Google';
import "../../index.css"
import axios from "axios"


import { auth, provider } from "../../firebase";
import { signInWithPopup } from 'firebase/auth';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { loginSuccess , loginFailure} from '../../redux/adminSlice';

export default function AdminLogin() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { currentUser } = useSelector(state => state.user);


    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(`${process.env.REACT_APP_APIKEY}/admin/signin`, { email, password, token }, { withCredentials: true, });
            if (res.data) {
                dispatch(loginSuccess(res.data));
                navigate("/admin")
            } else {
                alert("Invalid credentials");
            }

        } catch (error) {
            console.log(error);
            dispatch(loginFailure());
            alert("Invalid credentials");
        }
    };

    

    return (
        <>

            <Top>
                <Link to="/login/admin" style={{ textDecoration: "none" }}  >
                    <Admin>
                        <PersonIcon style={{ marginRight: "5px" }} /> Admin
                    </Admin>
                </Link>
                {/* <Link to="/login/supplier" style={{ textDecoration: "none" }} >
                    <Agent  >
                        <FiberManualRecordIcon sx={{ fontSize: 10, color: "red" }} style={{ margin: "0px 10px" }} />
                        Supplier
                    </Agent>
                </Link>
                <Link to="/login/serviceprovider" style={{ textDecoration: "none" }} >
                    <Agent>
                        <FiberManualRecordIcon sx={{ fontSize: 10, color: "blue" }} style={{ margin: "0px 10px" }} />
                        Service Provider
                    </Agent>
                </Link> */}
            </Top>



            <Container>
                <InnerContainer>
                    <form>
                        <h3>Admin Login</h3>
                        <p>to cointinue to Ecoventure</p>
                        <div>
                            <input placeholder='Username' type="email" id="username" required onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div>
                            <input placeholder='Password' type="text" id="password" required onChange={e => setPassword(e.target.value)} />
                        </div>
                        <div>
                            <input placeholder='AuthToken' type="text" id="password" required onChange={e => setToken(e.target.value)} />
                        </div>
                        <button onClick={handleLogin}  >Submit</button>

                    </form>

                </InnerContainer>
            </Container>
        </>

    )
}


const Top = styled.div`
height: 8vh;
background-color: #3B726A;
display: flex;
padding: 10px 20px;
justify-content: flex-end;
align-items: center;
`

const Container = styled.div`
height: 90vh;
display: flex;
align-items: center;
justify-content: center;
font-family: 'Outfit';
`


const InnerContainer = styled.div`



    width: 330px;
    border-radius: 10px;
    padding: 45px 40px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 15px rgba(0.2, 0, 0, 0.3);

    form{
        height: 100%;
        
    }

    form p{
        text-align: center;
        margin: 20px 0px;
        font-size: 14px;
        margin-bottom: 35px;
    }

    form h3{
        font-size: 21px;
        text-align: center;
    }

    form div{
        margin:20px 0px ;
    }

    input{
        width: 100%;
        padding: 12px;
        border-radius: 9px;
        border: 2px solid #b29f9f;        
    }


    button{
        width: 100%;
        padding: 13px;
        border-radius: 20px;
        border:none;
        cursor: pointer;
        background-image: linear-gradient(to right, #3370ca, #0087bd, #009297, #599679, #8c9674);
        font-weight: bold;
        color: white;
        margin: 15px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }

    @media only screen and (max-width: 768px) {
    width: 295px;
    border-radius: 10px;
    padding: 22px 28px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 15px rgba(0.2, 0, 0, 0.3);
    }

`


const Agent = styled.button`
padding: 9px 12px;
background: #56dada;
border: none;
border-radius: 20px;
margin: 0px 10px;
display: flex;
align-items: center;
cursor: pointer;
`

const Admin = styled.button`
display: flex;
align-items: center;
padding: 5px 16px;
border: 1px solid white;
border-radius: 19px;
margin: 0px 15px;
color:white;
background-color: transparent;
cursor: pointer;
`

