import React from 'react'
import styled from 'styled-components'
import "../index.css"
import q1 from "../images/q1.webp"
import q2 from "../images/q2.webp"
import q4 from "../images/q4.webp"
import q3 from "../images/q3.webp"
import q5 from "../images/q5.webp"
import q9 from "../images/q9.webp"

export default function Gallary() {
    return (
        <Container name="gallary" className="element" >
            <h3 className='gallary-head' >Project Gallary</h3>
            <InnerContainer>
                <div className='left-container' >
                    <div >
                        <GallaryImage className='S1' >
                            <img src={q1} />
                        </GallaryImage>
                        <GallaryImage className='S2' >
                            <img src={q2} />
                        </GallaryImage>
                    </div>
                    <div>
                        <GallaryImage className='S3' >
                            <img src={q9} />
                        </GallaryImage>
                    </div>
                </div>

                <div className='right-container'  >
                    <GallaryImage className='S4' >
                        <img src={q3} />
                    </GallaryImage>
                    <GallaryImage className='S5' >
                        <img src={q5} />
                    </GallaryImage>
                </div>


            </InnerContainer>

        </Container>
    )
}


const Container = styled.div`
height: 100vh;
width: 75%;
display: flex;
flex-direction: column;
text-align: center;
margin: auto;
padding: 20px;

@media only screen and (max-width: 768px) {
  margin:0px;
  width:100%;
  padding: 27px;
  height: fit-content;

  .gallary-head{
    padding: 10px 18px
  }

  h3{
    text-align: center;
    font-size:30px;
  }
}
`

const InnerContainer = styled.div`
height: 82%;
display: flex;

.left-container{
    >div:nth-child(1){
         display: flex;
          height: 65%;
    }
    >div:nth-child(2){
         display: flex;
          height: 35%;
    }
}
@media only screen and (max-width: 768px) {
    flex-direction: column;
    div{
        width: 100%;
        height: 100%;
        margin:  0px;
        padding: 0px;
    }
    img{
        margin:12px 0px;

    }
   
   .left-container >div{
    flex-direction: column;
   }
   .left-container  >div:nth-child(2){
    display: none;
   }
}
`



const GallaryImage = styled.div`
margin: 20px;
border-radius: 6px;

img{
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 0.5px solid #c0d3f6;
}

@media only screen and (max-width: 768px) {
  img{
    height: 200px;
  }
}
`
