import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentUser: null,
  error:false
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {

      loginSuccess: (state,action)=>{
        state.currentUser=action.payload;
      },

      loginFailure: (state)=>{
        state.currentUser = null ;
        state.error= true;
      },
  
      logout:(state)=>{
        state.currentUser = null;
        state.error = false;
      },
    },
  });


export const {loginFailure,loginSuccess,logout}=userSlice.actions;

export default userSlice.reducer;

