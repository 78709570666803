import React from 'react'
import HashLoader from "react-spinners/HashLoader";
import styled from 'styled-components';

export default function Loader() {
  return (
    <Container>
      <HashLoader color="#010504" size={50} />
    </Container>
  )
}

const Container = styled.div`
display: flex;
justify-content: center;
align-items: center;
z-index: 5;
position: absolute;
top:0;
left: 0;
height: 100vh;
width: 100vw;
display: flex;
background-color: rgba(255, 255, 255, 0.2); /* Adjust the background color and opacity as needed */
backdrop-filter: blur(10px); /* Adjust the blur amount as needed */
overflow: hidden;
overflow-y: hidden;
overflow-x: hidden;
`