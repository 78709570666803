import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentProject: null,
  allProjects : null ,
  allocatedQuotation : [] ,
  error:false
};


export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {

      fetchProject: (state,action)=>{
        state.currentProject=action.payload;
      },

      fetchAllProjects: (state,action)=>{
        state.allProjects=action.payload;
      },

      fetchAllocatedQuotation: (state,action)=>{
        state.allocatedQuotation=action.payload;
      },
      

      projectFetchFailure: (state)=>{
        state.error= true;
        state.currentProject = null ;
        state.allProjects = null ;
        state.allocatedQuotation= []
      },
  
    },
  });


export const {fetchAllProjects,fetchProject,projectFetchFailure,fetchAllocatedQuotation}=projectSlice.actions;

export default projectSlice.reducer;

