import { React, useState, useEffect } from 'react'
import styled from 'styled-components'
import AdminQuotation from './AdminQuotation'
import AdminProjectInfo from './AdminProjectInfo'
import { useLocation } from 'react-router-dom'
import { Outlet, Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import SendIcon from '@mui/icons-material/Send';
import { fetchProject, projectFetchFailure } from '../../redux/projectSlice'
import axios from "axios"
import { loginFailure } from '../../redux/userSlice'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import SearchIcon from '@mui/icons-material/Search';
import PriceSearcher from '../../components/PriceSearcher'


export default function AdminProject() {


    const { currentAdmin } = useSelector(state => state.admin);
    const { allocatedQuotation, currentProject } = useSelector(state => state.project);


    const [open, setOpen] = useState(false);



    const changeStatus = () => {
        setOpen(true)
    }



    function allObjectsFinalized(allocatedQuotation) {
        if (allocatedQuotation.length === 0) return false;
        for (const obj of allocatedQuotation) {
            if (!obj?.finalized) {
                return false;
            }
        }
        return true;
    }


    const sendToClient = async (e) => {
        try {
            const res = await axios.put(`${process.env.REACT_APP_APIKEY}/admin/project/client/${currentProject._id}/${currentAdmin._id}`, {}, { withCredentials: true, });
            console.log(res.data)
            alert("Quotations sent to client Successfully")
            navigate(`/admin/project`);
        } catch (error) {
            dispatch(loginFailure());
            navigate("/")
            console.log(error)
        }
    }


    const updateStatus = async (e) => {
        try {
            const res = await axios.put(`${process.env.REACT_APP_APIKEY}/admin/project/completed/${currentProject._id}/${currentAdmin._id}`, {}, { withCredentials: true, });
            console.log(res.data)
            alert("your project is completed")
            navigate(`/admin`);
        } catch (error) {
            dispatch(loginFailure());
            navigate("/")
            console.log(error)
        }
    }


    useEffect(() => {
        const verification = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_APIKEY}/admin/verify`, {
                    withCredentials: true,
                });

                if (!res.data) {
                    dispatch(loginFailure());
                    navigate("/")
                }
            }
            catch (err) {
                dispatch(loginFailure())
                navigate("/")
            }
        }
        verification();
    }, []);



    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();



    return (
        <Container>
            <Menu>
                <Link to="/admin/project" style={{ textDecoration: "none" }} >
                    <div style={{ backgroundColor: location.pathname === "/admin/project" ? "#eddeff" : "" }} >Project Info</div>

                </Link>

                <Link to="/admin/project/quotation" style={{ textDecoration: "none" }}  >
                    <div style={{ backgroundColor: location.pathname === "/admin/project/quotation" ? "#eddeff" : "" }} >  Quotation</div>
                </Link>

                <Link to="/admin/project/share" style={{ textDecoration: "none" }}  >
                    <div style={{ backgroundColor: location.pathname === "/admin/project/share" ? "#eddeff" : "" }} >Send Quotation</div>
                </Link>

                {(currentProject?.quotationSent && currentProject?.allocatedQuotations?.length === 3) && <>

                    <Link to="/admin/project/quotation/first" style={{ textDecoration: "none" }}  >
                        <div style={{ backgroundColor: location.pathname === "/admin/project/quotation/first" ? "#eddeff" : "" }} >Quotation 1</div>
                    </Link>

                    <Link to="/admin/project/quotation/second" style={{ textDecoration: "none" }}  >
                        <div style={{ backgroundColor: location.pathname === "/admin/project/quotation/second" ? "#eddeff" : "" }} >Quotation 2</div>
                    </Link>

                    <Link to="/admin/project/quotation/third" style={{ textDecoration: "none" }}  >
                        <div style={{ backgroundColor: location.pathname === "/admin/project/quotation/third" ? "#eddeff" : "" }} >Quotation 3</div>
                    </Link>



                </>}



                {(allObjectsFinalized(allocatedQuotation) && !currentProject?.visibleToclient) &&
                    <SearchBar id="search" >
                        <SearchIcon onClick={changeStatus} sx={{ fontSize: 25 }} />
                    </SearchBar>
                }


                {(allObjectsFinalized(allocatedQuotation) && !currentProject?.visibleToclient) && <button onClick={sendToClient}  ><SendIcon id="sendIcon" /><p>Proceed</p></button>}

                {(currentProject?.visibleToclient && currentProject?.status !== "Completed") && <button onClick={updateStatus} id="markAsComplete"  ><TaskAltIcon /><p>Mark as complete</p></button>}




            </Menu>
            <Outlet />
            <PriceSearcher setOpen={setOpen} open={open} />
        </Container>
    )
}




const SearchBar = styled.span`
display: flex;
align-items: center;
background-color: #383850;
padding: 3px;
border-radius: 6px;
cursor: pointer;
color:white;

svg{
    padding:3px;
}

`


const Menu = styled.div`
background-color: #d4d1cd;
padding: 13px 30px;
display: flex;
gap: 40px;
align-items: center;
border-radius: 5px;
font-weight: bold;

div{
    padding: 7px 14px;
    border: 2px solid grey;
    border-radius: 2px;
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: black;
    cursor: pointer;
}

button{
    display: flex;
    padding: 7px 15px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background: blueviolet;
    color: white;
    border: none;
    border-radius: 2px;
    cursor: pointer;
}
#sendIcon{
    font-size: 18px;
}

#markAsComplete{
    background-color: green;
    margin: 0px;
    font-size: 12px;
    padding: 7px 6px;
    gap:7px;
    border-radius: 4px;
}


`


const Container = styled.div`
padding: 20px;
margin: 20px;
display: flex;
font-family: 'Inter', sans-serif;
flex-direction: column;
padding-top: 0px;
`
const LeftContainer = styled.div`
width: 30%;
height: 100%;
`

const RightContainer = styled.div`
display: flex;
flex-direction: column;
width: 70%;
height: 100%;
background-color: white;
font-family: 'Outfit';
border-radius: 20px;
padding: 20px;

 /* >div{
    padding: 21px;
    font-size: 19px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
button{
    padding: 15px;
    font-size: 16px;
    margin: 20px;
    background-color: forestgreen;
    border: none;
    color: white;
    border-radius: 7px;
} */
`

const ScrollList = styled.section`
overflow-y: scroll;
border-radius: none;
height: 100%;
`

const Details = styled.div`
padding :20px ;
h4{
    margin: 20px 0px;
    font-family: 'Outfit', sans-serif;
    font-size: 18px;
}

div{
    display: flex;
    gap:40px;
}

div div{
    display: flex;
    flex-direction: column;
    gap:25px;
    margin: 13px 0px;

}
`
const Supplier = styled.div`
margin: 16px;
justify-content: space-between;
display: flex;
background-color: aqua;
padding: 20px;
input{
    height: 20px;
    width: 20px;
}
`

const ActionTab = styled.div`
    height: 65px;
    background-color: rebeccapurple;
    width: 98%;
    border-radius: 10px;
`

