import React, { useState ,useEffect} from 'react';
import styled from "styled-components";
import "../../index.css"
import AddCardIcon from '@mui/icons-material/AddCard';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Navbar from "../../components/Navbar"
import { Link,useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios" ;
import { loginSuccess,loginFailure } from '../../redux/userSlice';


export default function UserPayment() {


    const { currentUser } = useSelector( state => state.user)

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    
    useEffect(() => {
        const verification = async () => {
          try {
            const res = await axios.get(`${process.env.REACT_APP_APIKEY}/user/verify`, {
              withCredentials: true,
            });
    
            if (!res.data) {
              dispatch(loginFailure())
              navigate("/")
            }
    
          }
          catch (err) {
            dispatch(loginFailure())
            navigate("/")
          }
        }
        verification();
      }, []);


   

    return (<>
        <Navbar />

        <Container>

            <LeftContainer>
            <Link to="/user" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user" ? "#e4f0f8" : "" }} >
                        <PersonIcon /> <p>  Account</p>
                    </Tab>
                </Link>
                <Link to="/user/profile" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/profile" ? "#e4f0f8" : "" }} >
                        <ManageAccountsIcon />  <p> Update Profile</p>
                    </Tab>
                </Link>
                <Link to="/user/project" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/project" ? "#e4f0f8" : "" }} >
                        <AssignmentIcon /> <p>Project Details</p>
                    </Tab>
                </Link>
                <Link to="/user/payment" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/payment" ? "#e4f0f8" : "" }} >
                        <AddCardIcon />     <p> Payment Options</p>
                    </Tab>
                </Link>
                <Link to="/user/support" style={{ textDecoration: "none", color: "inherit" }}  >
                    <Tab style={{ backgroundColor: location.pathname === "/user/support" ? "#e4f0f8" : "" }} >
                        <LocalPhoneIcon />   <p>Support </p>
                    </Tab>
                </Link>

            </LeftContainer>
            <RightContainer>
                <h4>Payments</h4>
                <p>Currently we are only accepting payments in offline mode .</p>
              
            </RightContainer>

        </Container>
    </>
    )
}

const Container = styled.div`
display: flex;
height: 91vh;
font-family: 'Outfit';
@media only screen and (max-width: 768px) {
height: fit-content;
}
`
const LeftContainer = styled.div`
width: 20%;
display: flex;
flex-direction: column;
padding: 30px 40px;
background-color: #d4d1cd;
@media only screen and (max-width: 768px) {
display: none;
}
`
const RightContainer = styled.div`
width: 80%;
display: flex;
flex-direction: column;
padding: 55px 120px;

h4{
    font-size: 26px;
    margin-bottom: 35px;
}

button{
    width: 146px;
    padding: 10px;
    background-color: blueviolet;
    border: none;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    margin: 10px;
}

@media only screen and (max-width: 768px) {
    width: 100%;
    padding: 25px;
}
`
const ImageContainer = styled.div`
height: 200px;
width: 200px;
overflow: hidden;
border-radius: 50%;

image{
    width: 100%;
    height: 100%;

}
`
const Top = styled.div`
width: 100%;
display: flex;
`
const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    row-gap: 10px;
    color: mediumvioletred;
`

const Details = styled.div`
display: flex;
gap: 50px;
padding: 25px 0px;

input{
    padding: 10px;
    width: 400px;
    border-radius: 8px;
    border: 1px solid lightblue;
}

label{
    padding: 10px;
}

div{
    display: flex;
    flex-direction: column;
    gap:30px
}

`

const Tab = styled.div`
padding: 13px;
border: 2px solid grey;
margin: 20px 0px;
display: flex;
align-items: center;
gap:20px;
cursor: pointer;
border-radius: 5px;
`