import { React, useState, useEffect } from 'react'
import styled from "styled-components"
import axios from "axios"
import { useLocation } from 'react-router-dom';
import Loader from '../../components/Loader';


export default function ServiceProviderQuotation() {

  const [quotation, setQuotation] = useState([]);
  const location = useLocation();

  const [validationError, setValidationError] = useState('');
  const [active, setActive] = useState(true);
  const [msg, setMsg] = useState(null);


  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    info: [], // This will hold information for each row in the table
  });

  console.log(formData);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleTableInputChange = (field, value, index, row) => {
    setFormData((prevData) => {
      const updatedInfo = [...prevData.info];
      const currentRow = updatedInfo[index] || {};
      updatedInfo[index] = {
        ...currentRow,
        [field]: value,
        // Store values from the row object as well
        quantity: row.quantity,
        lowerRange: row.lowerRange,
        upperRange: row.upperRange,
        serviceDesc: row.serviceDesc,
        amount: Number(value) * row.quantity,
      };
      return {
        ...prevData,
        info: updatedInfo,
      };
    });
  };


  const handleReset = () => {
    setFormData({
      name: '',
      phone: '',
      email: '',
      info: [],
    }); setValidationError("")

  };


  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function isValueInRange(lowerRange, upperRange, value) {
    return Number(value) >= Number(lowerRange) && Number(value) <= Number(upperRange);
  }

  function isQuotationFieldsComplete(formData) {
    if (formData.info.length === 0) return false;
    for (const row of formData.info) {
      if (row.spCosting === null || row.spCosting === undefined || row.spCosting === '') {
        return false;
      }
    }
    return true;
  }



  const handleSubmit = async () => {

    if (formData.phone === "" || formData.name === "") {
      alert("Please fill the required fields !")
      return
    }
    else if (formData.phone.length !== 10) {
      alert("Invalid Phone number")
      return
    } else if (formData.email && !isValidEmail(formData.email)) {
      alert("Invalid Email Address")
      return
    }

    if (!isQuotationFieldsComplete(formData)) {
      alert("Please fill all the fields !")
      return
    }

    const ans = formData.info.every((item) => {
      return isValueInRange(item.lowerRange, item.upperRange, item.spCosting);
    });

    if (ans === false) {
      alert("The entered values are not in range !")
      return
    }

    try {
      document.getElementById("info-table").style.display = "none";
      setActive(true);
      const res = await axios.post(`${process.env.REACT_APP_APIKEY}/service-provider/quotation/${location.pathname.split("/")[3]}`, formData, {
        withCredentials: true,
        headers: {
          "content-type": "application/json"
        }
      });
      setMsg(res.data);
    } catch (error) {
      alert(error.response.data.message);
    }
  }




  useEffect(() => {
    const verify = async () => {
      const res = await axios.get(`${process.env.REACT_APP_APIKEY}/service-provider/quotation/verify/${location.pathname.split("/")[3]}`);
      setMsg(res.data)
    }
    verify();
  }, []);



  useEffect(() => {
    const fetchQuotation = async () => {
      const res = await axios.get(`${process.env.REACT_APP_APIKEY}/service-provider/quotation/${location.pathname.split("/")[3]}`);
      setQuotation(res.data);
      setActive(false);
    }
    if (!msg) {
      fetchQuotation();
    }
  }, []);





  return (
    <>

      <Container>
        {active && <Loader />}

        {msg && <Popup> <div><p>{msg}</p></div></Popup>}
        {msg && <Loader />}

        {validationError && <p style={{ color: 'red' }}>{validationError}</p>}


        {quotation && <> <h4>Quotation Form</h4>
          <hr />
          <div id="main" >



            <SPDetails>
              <div>
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
                {!formData.name && <span class="required">*</span>}

                <label htmlFor="phone">Phone:</label>
                <input
                  type='number'
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                  pattern={"[0-9]{10}"}
                  maxLength="10"
                />
                {!formData.phone && <span class="required">*</span>}

                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />

              </div>
            </SPDetails>
            <div id="inital-quotation"  >
              <div>
                <table id="info-table" >
                  <thead>
                    <tr>
                      <th>Description of Service</th>
                      <th>Quantity</th>
                      <th>Price Range</th>
                      <th>S.P Costing</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody id="table-body">
                    {quotation &&
                      quotation[0]?.info.map((row, index) => (
                        <tr key={row._id}>
                          <td>
                            <textarea
                              disabled
                              className='service-desc'
                            >
                              {row.serviceDesc}
                            </textarea>
                          </td>
                          <td>
                            <input
                              disabled
                              className='service-input'
                              type='number'
                              value={row.quantity}
                            />
                          </td>
                          <td>
                            <div className='price-range-input-container'>
                              <input
                                disabled
                                className='service-input'
                                type='number'
                                value={row.lowerRange}
                              />
                              <p>-</p>
                              <input
                                disabled
                                className='service-input'
                                type='number'
                                value={row.upperRange}
                              />
                            </div>
                          </td>
                          <td>
                            <input
                              className='service-input'
                              type='number'
                              value={formData.info[index]?.spCosting || ''}
                              onChange={(e) =>
                                handleTableInputChange('spCosting', e.target.value, index, row)
                              }
                              style={{ border: formData.info[index]?.spCosting && !isValueInRange(row.lowerRange, row.upperRange, formData.info[index]?.spCosting) ? "2px solid red" : "" }}
                            />
                          </td>
                          <td>
                            <input
                              className='service-input'
                              type='number'
                              value={formData.info[index]?.amount || ''}
                              onChange={(e) =>
                                handleTableInputChange('amount', e.target.value, index, row)
                              }
                              disabled
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <BtnContainer>
            <button onClick={handleReset}>Reset</button>
            <button onClick={handleSubmit}>Submit</button>
          </BtnContainer>
        </>
        }

      </Container>
    </>
  )
}


const Container = styled.div`
display: flex;
flex-direction: column;
padding: 2% 15%;
position: relative;
overflow-x: hidden;
min-height: 100vh;

hr{
    margin-bottom: 50px;
}

h4{
    margin-bottom: 30px;
    font-size: 30px;
    text-align: center;
    font-family: 'Outfit';
    font-weight: bold;
    background: #c5d0da;
    padding: 12px;
    border-radius: 5px;
}

table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
    }

    th, td {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    th {
      background-color: #f2f2f2;
    }

    thead > tr>th:nth-child(1) {
        width: 40%;
    }
    thead{
      font-size: 15px;
    }
    thead tr th{
      padding: 14px;
    }

#submit-quote{
    width: 178px;
    padding: 10px;
    margin: 40px 0px;
    background: blueviolet;
    border: none;
    color: white;
    font-size: 15px;
    border-radius: 5px;
    font-family: 'Outfit';
    align-self: center;
    cursor: pointer;
    align-self: flex-end;
} 

@media only screen and (max-width: 768px) {
  padding: 0px;

  hr{
    display: none;
  }
  h4{
    font-size: 23px;
    margin-bottom: 12px;
  }
  
  #main{
    width: 100%;
    padding: 5%;
  }

  #inital-quotation{
        width: 100%;
        overflow-x: scroll;

    table {
        width: 700px;
    }

    thead > tr>th:nth-child(1) {
        width: 200px;
    }

    }


}
`

const SPDetails = styled.form`
display: flex;
flex-direction: column;

span{
    margin-left: -45px;
    margin-bottom: 24px;
    color: red;
    font-size: 21px;
}

>div{
    display: flex;
    gap: 30px;
    align-items: center;
}
   
label {
display: block;
margin-bottom: 8px;
}

input {
    width: 100%;
    padding: 8px;
  
    margin-bottom: 16px;
    box-sizing: border-box;
}
input[type="submit"] {
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    >div{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    }

    span{
    align-self: flex-end;
    justify-content: flex-end;
    display: flex;
    margin-top: -54px;
    margin-right: 9px;
    }

    
}



`

const BtnContainer = styled.div`
display: flex;
justify-content: space-evenly;

button{
    padding: 11px;
    width: 164px;
    background: blueviolet;
    border: none;
    color: white;
    border-radius: 3px;
    margin: 30px 0px;
    cursor: pointer;
}

button:hover{
    transform: scale(0.95);
}

@media only screen and (max-width: 768px) {
  margin-bottom: 25px;
  button{width: 135px;}
}

`

const Popup = styled.div`
position: absolute;
top: 0;
left: 0;
display: flex;
align-items: center;
justify-content: center;
z-index: 10;
height: 100vh;
width: 100vw;
p{
padding: 24px;
}
div{
background-color:#5e5858;
color: white;
font-family: 'Outfit';
border-radius:4px;
}

`

