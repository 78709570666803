import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import "../../index.css"
import AddCardIcon from '@mui/icons-material/AddCard';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Navbar from "../../components/Navbar"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { loginFailure } from '../../redux/serviceProviderSlice';
import { projectFetchFailure } from '../../redux/projectSlice';

import Carousel from "../../components/Carousel"

export default function ServiceProviderPayments() {


    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();



    const { currentUser } = useSelector(state => state.user);

    useEffect(() => {
        const verification = async () => {
          try {
            const res = await axios.get(`${process.env.REACT_APP_APIKEY}/service-provider/verify`, {
              withCredentials: true,
            });
    
            if (!res.data) {
              dispatch(loginFailure());
              navigate("/")
            }
          }
          catch (err) {
            dispatch(loginFailure())
            navigate("/")
          }
        }
        verification();
      }, []);





    return (<>

        <Container>
            <RightContainer>
                <Details>
                   <p>Currently payments are provided only in offline mode</p>
                </Details>
            </RightContainer>
        </Container>
    </>
    )
}

const Container = styled.div`
display: flex;
height: 91vh;
font-family: 'Outfit';
`
const QuotationDiv = styled.div`
background-color: #e8e9ec;
padding: 28px;
margin: 40px 0px;
font-size: 15px;
border-radius: 2px;

>div{
    display: flex;
    gap: 40px;
}

.innerdiv{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

td{
    font-size: 14px;
    font-family: sans-serif;
}
`

const Notifier = styled.div`
min-width:  465ps;
margin: 35px 0px;
background: #c9bed7;
display: flex;
padding: 3px 21px;
border-radius: 4px;

p{
    padding: 15px;
}
input{
    margin: 10px 50px;
    text-align: center;
    width: 50px;
}

button{
    align-self: flex-end;
    width: 129px;
    padding: 8px;
    background-color: blueviolet;
    border: none;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    margin: 10px;
}
`



const RightContainer = styled.div`
width: 100%;
display: flex;
gap: 70px;
padding: 0px 25px;
align-items: flex-start;
margin: 30px 0px;

h4{
    font-size: 26px;
    margin-bottom: 10px;
}

table {
      border-collapse: collapse;
      width: 100%;
      margin: auto;
      margin-top: 20px;
    }

    th, td {
      border: 1px solid #474654;
      padding: 12px;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
    }

@media only screen and (max-width: 768px) {
    padding: 0px 20px;
    }

`
const ImageContainer = styled.div`
position: relative;
span{
    display: flex;
    position: absolute;
    top: -8px;
    left: -8px;
    background: black;
    color: white;
    padding: 6px;
    height: 23px;
    width: 24px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}
image{
    width: 100%;
    height: 100%;

}
`

const Top = styled.div`
width: 100%;
display: flex;
`


const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    row-gap: 10px;
    color: mediumvioletred;
`

const Details = styled.div`
padding :25px 20px ;
padding-bottom: 0px;
h4{
    margin: 15px 0px;
    font-family: 'Outfit', sans-serif;
    font-size: 18px;
}
div{
    display: flex;
    gap:30px;
}
div div{
    display: flex;
    flex-direction: column;
    gap:45px;
    margin: 13px 0px;
}
div div:nth-child(2){
    font-family: none;
}

@media only screen and (max-width: 768px) {
    padding: 0px;
}
`


const Tab = styled.div`
padding: 13px;
border: 2px solid grey;
margin: 20px 0px;
display: flex;
align-items: center;
gap:20px;
cursor: pointer;
border-radius: 5px;
`



const Designs = styled.div`
font-family: 'Outfit';
padding: 20px 0px;

>p{font-size: 18px;
    margin-bottom: 25px;
}
p:nth-child(2){
    font-size: 15px;
    font-family: 'Inter';
}
#image-set{
    display: flex;
    gap:20px;

    img{
    width: 280px;
    height: 180px;
    }
}
`