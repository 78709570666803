import { React, useState, useEffect, useRef } from 'react'
import styled from "styled-components"
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';

import axios from "axios";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { loginFailure, loginSuccess } from '../redux/serviceProviderSlice.js';
import { useDispatch } from 'react-redux';
import Loader from './Loader';


import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import app from '../firebase.js';



export default function ServiceProviderPublicProfileUpdate({ setOpen }) {

    const { currentServiceProvider } = useSelector(state => state.serviceProvider);

    console.log(currentServiceProvider)

    const [upload, setUpload] = useState(currentServiceProvider?.publicImages);

    const [active, setActive] = useState(false);

    const fileInputRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleIconClick = () => {
        // Trigger file input click
        fileInputRef.current.click();
    };


    const uploadFile = async (file) => {
        const storage = getStorage(app);
        const storageRef = ref(storage, `Service Provider/${currentServiceProvider?._id}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        await new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                (error) => {
                    console.error(error);
                    reject(error);
                },
                () => {
                    resolve();
                }
            );
        });
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        // console.log(downloadURL);
        return downloadURL;
    }


    const deleteFile = async (file, indexToRemove) => {

        setActive(true);

        const parts = file.split("%");
        const storage = getStorage(app);
        const filename = parts[parts.length - 1].split("?")[0];

        console.log(upload[indexToRemove])

        const desertRef = ref(storage, `Service Provider/${currentServiceProvider?._id}/${filename}`);

        await deleteObject(desertRef).then(() => {
            console.log("file deleted successfully")
        }).catch((error) => {
            console.log(error.response)
        });

        setUpload((prevItems) => {
            return prevItems.filter((item, index) => index !== indexToRemove);
        });

        const updatationDetails = { publicImages: upload.filter((item, index) => index !== indexToRemove) }

        try {
            const res = await axios.put(
                `${process.env.REACT_APP_APIKEY}/service-provider/${currentServiceProvider._id}`,
                { updatationDetails },
                {
                    withCredentials: true,
                    headers: {
                        "content-type": "application/json",
                    },
                }
            );

            dispatch(loginSuccess(res.data));
            setActive(false);
            alert("Images removed successfully");
            navigate(`/service-provider/publicprofile/${currentServiceProvider._id}`);
        } catch (error) {
            dispatch(loginFailure());
        }

    }



    const addImages = async (e) => {

        e.preventDefault();
        setActive(true);

        const selectedPhotos = Array.from(e.target.files);

        const images = await Promise.all(selectedPhotos.map(async (photo) => {
            return await uploadFile(photo);
        }));

        const updatationDetails = { publicImages: upload.concat(images) }

        try {
            const res = await axios.put(`${process.env.REACT_APP_APIKEY}/service-provider/${currentServiceProvider._id}`, { updatationDetails }, {
                withCredentials: true,
                headers: {
                    "content-type": "application/json"
                }
            });

            console.log(res.data, "..........................")

            dispatch(loginSuccess(res.data));
            setActive(false);
            alert("Images updated successfully");
            navigate(`/service-provider/publicprofile/${currentServiceProvider._id}`)
        } catch (error) {
            dispatch(loginFailure());
        }
    };



    return (
        <Container>
            {active && <Loader />}
            <TopContainer>
                <h4>Manage your gallery</h4>
                <UploadButton onClick={handleIconClick} >
                    <UploadIcon sx={{ fontSize: 20 }} />
                    <span>Add Image</span>
                    <input
                        type="file"
                        id="upload"
                        name="image"
                        accept="image/*"
                        multiple
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={addImages}
                    />
                </UploadButton>
                <Close id="close" onClick={() => setOpen(false)} ><CancelIcon sx={{ fontSize: 25 }} /></Close>
            </TopContainer>
            <InnerConatainer>

                {
                    currentServiceProvider && currentServiceProvider?.publicImages?.map((item, index) => (
                        <Image key={index} >
                            <img src={item} />
                            <span onClick={(e) => deleteFile(item, index)} ><DeleteIcon sx={{ fontSize: 19 }} /></span>
                        </Image>
                    ))
                }




            </InnerConatainer>
        </Container>
    )
}


const Container = styled.div`
z-index:20;
height: 100vh;
width: 100vw;
position: absolute;
top:0;
left:0;
overflow: hidden;
background-color: rgba(255, 255, 255, 0.2); /* Adjust the background color and opacity as needed */
backdrop-filter: blur(10px); /* Adjust the blur amount as needed */
display: flex;
padding: 5%;
flex-direction: column;

`

const InnerConatainer = styled.div`
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    background-color: white;
    padding: 35px;
    min-height: 70vh;
    overflow-y: scroll;

    @media only screen and (max-width: 768px) {
        padding: 20px;
    }
`

const Image = styled.div`
    background: pink;
    height: 200px;
    width: 22%;
    border-radius: 4px;
    position: relative;

    img{
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    span{
        position: absolute;
        top: -10px;
        right: -10px;
        cursor:pointer;
        background: red;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: black;
        }

        @media only screen and (max-width: 768px) {
            width: 100%;
        }
`

const TopContainer = styled.div`
background-color: aquamarine;
border-top-right-radius: 5px;
border-top-left-radius: 5px;
position: relative;
h4{
    font-size: 23px;
    font-family: 'Outfit';
    text-align: center;
    padding: 20px;
}

#close{
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
}

    @media only screen and (max-width: 768px) {
        h4{
            font-size: 18px;
            text-align: unset;
            padding: 15px;
        }

        #close{
            top: 15px;
            right: 10px;
        }
    }



`


const UploadButton = styled.button`
    position: absolute;
    right: 5%;
    top: 27%;
    padding: 7px 9px;
    background: blueviolet;
    color: white;
    border: none;
    font-family: 'Outfit';
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
        right: 15%;
        top: 19%;
        padding: 7px;

        span{
            display:none;
        }

    }
`

const Close = styled.span`
`