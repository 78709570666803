import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';
import axios from "axios"
import CircularProgress from '@mui/material/CircularProgress';

const DrawerContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${({ open }) => (open ? '0' : '-350px')}; 
  width: 20%;
  height: 100vh;
  background-color: #161f2d;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease;
  z-index: 1000;


  ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: darkgrey;
}

::-webkit-scrollbar-thumb {
  background-color: #4D949E;
}

`;

const DrawerContent = styled.div`
  padding: 20px;
  height: calc(100vh - 65px );
  overflow-y:scroll;

#searchbox{
     display: flex;
    align-items: center;
    background: white;
    border-radius: 4px;

    svg{
      margin: 6px;
      cursor: pointer;
    }
}

#loader{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.css-s6v1ve-MuiPaper-root-MuiSnackbarContent-root{
      min-width: 255px;
    }
`;

const DrawerHeader = styled.div`
display: flex;
padding: 20px;
justify-content: space-between;
align-items: center;
color:white;
background-color: #2b3636;
text-align: center;

h5{
  width: 100%;
}
`;

const DrawerClose = styled.span`
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
`;


const Input = styled.input`
    width: 100%;
    padding: 9px;
    font-size: 15px;
    border: none;
    border-radius: 3px;
    outline: none;

`

const PriceContainer = styled.div`
    background: #346970;
    color: white;
    font-size: 14px;
    margin: 15px 0px;
    display: flex;
    padding: 13px 16px;
    border-radius: 3px;
    flex-direction: column;
    gap: 12px;

    div{
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      background: #423d43;
      padding: 7px 10px;
      border-radius: 3px;

      span{
        text-align: center;
        width: 100%;
      }
      }
      
    p{
      cursor: pointer;
      color:black;
      font-weight: bold;
      background: #f1e9ec;
      padding: 5px 19px;
      border-radius: 6px;
      width: fit-content;
    }
    

  

`






const PriceSearcher = ({ setOpen, open }) => {

  const drawerRef = useRef(null);

  const close = () => setOpen(false);

  const [snack, setSnack] = React.useState(false);
  const [copiedText, setCopiedText] = useState('');

  const [data, setData] = useState(null);
  const [item, setItem] = useState(null);
  const [loading, setloading] = useState(false);

  const { currentAdmin } = useSelector(state => state.admin);


  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopiedText(text);
      })
      .catch((error) => {
        console.error('Unable to copy text:', error);
      });
  }



  const handleClick = (e) => {
    setSnack(true);
    copyTextToClipboard(e.target.innerText)
    setTimeout(() => {
      setSnack(false);
    }, 2000)
  };



  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
      >
      </IconButton>
    </React.Fragment>
  );


  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (drawerRef.current && !drawerRef.current.contains(event.target)) {
  //       close(false);
  //     }
  //   };

  //   if (setOpen) {
  //     document.addEventListener('mousedown', handleClickOutside);
  //     return () => {
  //       document.removeEventListener('mousedown', handleClickOutside);
  //     };

  //   }


  // }, [close, setOpen]);



  const retrivePrices = async (e) => {
    try {
      setData(null);
      setloading(true);
      const res = await axios.get(`${process.env.REACT_APP_APIKEY}/admin/getprices/${currentAdmin?._id}?item=${item}`, {
        withCredentials: true,
      });
      setTimeout(() => {
        setloading(false);
        setData(res.data);
      }, 2000)

    } catch (err) {
      console.log(err)
    }
  }


  return (
    <DrawerContainer open={open} ref={drawerRef}>
      <DrawerHeader>
        <h5>Suppliers and Pricing</h5>
        <DrawerClose onClick={close} >
          <CloseIcon />
        </DrawerClose>
      </DrawerHeader>
      <DrawerContent>
        <div id="searchbox" >
          <Input onChange={(e) => setItem(e.target.value)} />
          <SearchIcon onClick={retrivePrices} />
        </div>



        {loading && <div id="loader" ><CircularProgress /></div>}

        {
          data && data.map((item, index) => (

            <PriceContainer key={index} >
              <p value={item.price} onClick={handleClick}>{item.price}</p>
              <div onClick={handleClick} >
                <span>{item.name} &nbsp; |  &nbsp;  {item.phone}</span>
              </div>
            </PriceContainer>
          ))
        }

       <Snackbar
          open={snack}
          autoHideDuration={3000}
          message="Text Copied"
          ContentProps={{
            sx: {
              display: 'block',
              textAlign: "center",
            }
          }}
        />

      </DrawerContent>
    </DrawerContainer>
  );
};



export default PriceSearcher;
