import React from 'react'
import styled from 'styled-components'
import PersonIcon from '@mui/icons-material/Person';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from "axios"
import { useDispatch } from 'react-redux';
import { logout } from '../redux/userSlice';
import { Link as ScrollLink, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import Hamburger from './Hamburger';
export default function Navbar() {

    const { currentUser } = useSelector(state => state.user);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logOut = async () => {
        const res = await axios.post(`${process.env.REACT_APP_APIKEY}/auth/logout`, {}, { withCredentials: true });
        dispatch(logout());
        navigate("/");
    }

    return (
        <Container>
            <Link to="/" style={{ textDecoration: "none" }}  >
                <Logo>
                    <h3>EcoVenture</h3>
                </Logo>

            </Link>


           { location.pathname.split("/")[1] !== "user" &&
           <LinkContainer >
                <ScrollLink style={{ textDecoration: "none", cursor: "pointer" }} activeClass="active" to="test1" spy={true} smooth={true} offset={-70} duration={500} >
                    <li>Home</li>
                </ScrollLink>


                <ScrollLink style={{ textDecoration: "none", cursor: "pointer" }} activeClass="active" to="about" spy={true} smooth={true} offset={-90} duration={500} >
                    <li>About</li>
                </ScrollLink>


                <ScrollLink style={{ textDecoration: "none", cursor: "pointer" }} activeClass="active" to="services" spy={true} smooth={true} offset={-30} duration={500} >
                    <li>Services</li>
                </ScrollLink>

                <ScrollLink style={{ textDecoration: "none", cursor: "pointer" }} activeClass="active" to="gallary" spy={true} smooth={true} offset={-30} duration={500} >
                    <li>Gallary</li>
                </ScrollLink>

                <ScrollLink style={{ textDecoration: "none", cursor: "pointer" }} activeClass="active" to="contact" spy={true} smooth={true} offset={-30} duration={500} >
                <li>Contact Us</li>
                </ScrollLink>


            </LinkContainer >
            }

            <UserInfo>
                {
                    (location.pathname.startsWith("/user") && currentUser) ?
                        <User onClick={logOut} >
                            Logout
                        </User>
                        :
                        (currentUser && <Link to="/user" style={{ textDecoration: "none" }} >
                            <Admin>
                                <PersonIcon style={{ marginRight: "3px" }} />
                                <p>Account</p>
                            </Admin>
                        </Link>)


                }

                {!currentUser && <Link to="/login" >
                    <User>
                        Sign In
                    </User>
                </Link>}

                

            </UserInfo>

            <Hamburger/>


        </Container >
    )
}


const Container = styled.div`
background-color:#3B726A ;
width:100%;
display: flex;
justify-content: space-between;
align-items: center;
padding: 0px 80px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
position: sticky;
top: 0px;
height: 9vh;
z-index: 15;


@media only screen and (max-width: 768px) {
    padding: 20px;
}

`
const Logo = styled.div`
    text-decoration: none;
h3{
    color: white;
    font-size: 25px; 
    font-family: 'Poppins', sans-serif;
    cursor: pointer;

}
`

const UserInfo = styled.div`
display: flex;
@media only screen and (max-width: 768px) {
   display: none;
}
`

const LinkContainer = styled.ul`
display: flex;
flex-direction: row;
padding-right: 240px;

li{
    list-style: none;
    margin: 22px 44px;
    color: white;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 768px) {
   display: none;
}

`

const Admin = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 11px;
    border: 1px solid white;
    border-radius: 19px;
    margin: 0px 15px;
    color: white;
    cursor: pointer;
`
const User = styled.button`
    padding: 8px 10px;
    width: 81px;
    border-radius: 5px;
    border: none;
    background: aliceblue;
    margin: 0px 10px;
    font-size: 14px;
    font-family: 'Inter',sans-serif;
    font-weight: bold;
    cursor: pointer;

`

