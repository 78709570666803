import { useEffect } from 'react'
import "../../index.css"
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person';

import axios from "axios"
import { loginFailure } from '../../redux/adminSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../redux/adminSlice';
import { Link } from 'react-router-dom';

import PriceSearcher from '../../components/PriceSearcher';

export default function Admin() {

  const { currentAdmin } = useSelector(state => state.admin);

  const dispatch = useDispatch();
  const navigate = useNavigate();



  useEffect(() => {
    const verification = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_APIKEY}/admin/verify`, {
          withCredentials: true,
        });

        if (!res.data) {
          dispatch(loginFailure());
          navigate("/")
        }
      }
      catch (err) {
        dispatch(loginFailure())
        navigate("/")
      }
    }
    verification();
  }, []);



  const logOut = async () => {
    const res = await axios.post(`${process.env.REACT_APP_APIKEY}/auth/logout`, {}, { withCredentials: true });
    dispatch(logout());
    navigate("/");
  }

  return (
    <>
      <TopMenu>

        <div>
          <Link to="/admin" style={{ textDecoration: "none", color: "white" }} >
            EcoVenture
          </Link>
        </div>



        {currentAdmin && <button onClick={logOut} >LogOut</button>}

      </TopMenu>

      <Container>

        <MessageContainer>
          <Message>
            <PersonIcon />
            <span>3</span>
            <p>Service Providers</p>
          </Message>
          <Message>
            <PersonIcon />
            <p>Suppliers</p>
          </Message>
          <Message>
            <PersonIcon />
            <p>Customers</p>
          </Message>

        </MessageContainer>

        <PriceSearcher/>

        <InnerContainer>

          <Outlet />
        </InnerContainer>

      </Container>
    </>
  )
}

const Container = styled.div`
height: calc(100vh - 65px);
display: flex;
`



const MessageContainer = styled.div`
height: 100%;
width : 20%;
background-color: #d4d1cd;
font-family: 'Outfit', sans-serif;
padding: 30px 40px;

h6{
  font-size: 24px;
  margin-bottom: 35px;
  text-align: center;
}
`

const InnerContainer = styled.div`
display: flex;
flex-direction: column;
height: 100% ;
width: 80%;
overflow-y: scroll;
`
const TopMenu = styled.div`
background-color: #4d949e;
height: 65px;
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
padding: 0px 75px;

>div{
  color: white;
    font-weight: bold;
    font-size: 27px;
    font-family: 'Outfit';
}

button{
  padding: 7px 14px;
    background: azure;
    border: none;
    border-radius: 3px;
    font-weight: bold;
    cursor: pointer;
}
`

const Message = styled.div`
    gap: 20px;
    display: flex;
    position: relative;
    margin: 30px 0px;
    padding: 15px;
    border-radius: 4px;
    align-items: center;
    border: 2px solid grey;
    cursor:pointer;

span{
  color: white;
    position: absolute;
    right: -10px;
    background: black;
    top: -10px;
    padding: 7px;
    height: 20px;
    border-radius: 50%;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
`